import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as SearchIcon } from '../../../Assets/magnifying-glass-solid-black.svg';
import { ReactComponent as LightningIcon } from '../../../Assets/bolt-lightning-solid.svg';
import { ReactComponent as ShoppingCartIcon } from '../../../Assets/shopping-cart-solid.svg';
import mainAddImage from '../../../Assets/main-add-image.png';
import '../CSS/InvitationLandingPage.css';
import ConfettiExplosion from 'react-confetti-explosion';

const InvitationLandingPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const fullName = (queryParams.get('friend') || 'Amigo').replace(/-/g, ' ');
    const inviteCode = queryParams.get('invite_code');
    const firendUID = queryParams.get('friend_id');
    const navigate = useNavigate();

    return (
        <div className="invitation-landing-page">
            <div style={{width: '100%', zIndex: 1, display: 'flex', justifyContent: 'space-between'}}>
                <ConfettiExplosion style={{alignSelf: 'flex-start'}} />
                <ConfettiExplosion style={{alignSelf: 'center'}} />
                <ConfettiExplosion style={{alignSelf: 'flex-end'}} />
            </div>
            <div className="background-video">
                <video autoPlay loop muted playsInline preload="true" poster={require("../../../Assets/main-home-video.png")} style={{ width: '100%', height: '100%', objectFit: 'cover', zIndex: -1 }}>
                <source src={require("../../../Assets/main-home-video.mp4")} type="video/mp4" />
                </video>
                <div className="overlay-invitation"></div>
            </div>
            <div className="content-landing-page">
                <h1>{fullName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} te invitó a Wearly!</h1>
                <p>la plataforma de la moda <span role="img" aria-label="flag">🇦🇷</span></p>
                <div className="steps">
                    <div className="step">
                        <div class="step-header">
                            <div className="icon-div">
                                <SearchIcon className="icon icon-search" style={{ fill: 'black' }} />
                            </div>
                            <h1>Buscar ropa en todas las marcas</h1>
                        </div>
                        <p>Busca como ropa como quieras, por ocasión o por estilos.</p>
                        <p>“Tengo una fiesta a la noche”</p>
                        <p>“Remera rallada de Tranca”</p>
                        <p>“Campera puffer de 200mil pesos”</p>
                    </div>
                    <div className="step">
                        <div class="step-header">
                            <div className="icon-div">
                                <LightningIcon className="icon icon-lightning" />
                            </div>
                            <h1>Probate la ropa</h1>
                        </div>
                        <p>Sacate una foto y proba como te queda.</p>
                    </div>
                    <div className="step">
                        <div class="step-header">
                            <div className="icon-div">
                                <ShoppingCartIcon className="icon icon-shopping-cart" />
                            </div>
                            <h1>Compra lo que te guste</h1>
                        </div>
                        <p>Un carrito, una compra, multiples marcas de ropa.</p>
                    </div>
                </div>
                <div style={{width: '100%', zIndex: 1, display: 'flex', justifyContent: 'space-between'}}>
                    <ConfettiExplosion style={{alignSelf: 'flex-start'}} />
                    <ConfettiExplosion style={{alignSelf: 'center'}} />
                    <ConfettiExplosion style={{alignSelf: 'flex-end'}} />
                </div>
                <div className="bottom-content">
                    <button onClick={() => navigate('/join?invite=' + inviteCode)} className="create-account-button-invitation">Crear Cuenta</button>
                    {inviteCode && <p style={{fontSize: '12px', color: '#fff', textAlign: 'center'}}>invite code: {inviteCode}</p>}
                    <div className="logo-invitation-image">
                        <img src={require('../../../Assets/wai-logo-white.png')} alt="Wearly Logo" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvitationLandingPage;