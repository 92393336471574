import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfettiExplosion from 'react-confetti-explosion';
import { Container, Alert, Button } from 'react-bootstrap';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const PaymentStatusPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('q');
  const navigate = useNavigate();

  const renderContent = () => {
    switch (query) {
      case 'success':
        return (
          <>
            <div className="d-flex justify-content-center">
              <FaCheckCircle size={50} color="green" className="my-3" />
            </div>
            <Alert variant="success" className="text-center" style={{ marginTop: '20px' }}>
                <Alert.Heading>¡Pago Realizado con Éxito!</Alert.Heading>
                <p>
                Muchas gracias por tu compra. Hemos enviado un correo electrónico con los detalles de tu pedido y pronto la o las marcas te enviaran mas informacion.
                </p>
                <hr />
                <Button style={{ backgroundColor: 'black', color: 'white', borderRadius: '5px', width: '100%', padding: '10px', border: 'none', fontWeight: '700', fontFamily: 'Raleway', cursor: 'pointer' }} onClick={() => navigate('/')}>Volver al Inicio</Button>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ConfettiExplosion />
                </div>
            </Alert>
          </>
        );
      case 'failed':
        return (
          <>
            <div className="d-flex justify-content-center">
              <FaTimesCircle size={50} color="red" className="my-3" />
            </div>
            <Alert variant="danger" className="text-center" style={{ marginTop: '20px' }}>
              <Alert.Heading>Pago Fallido</Alert.Heading>
              <p>
                Lo sentimos, el proceso de pago no se ha completado correctamente. Por favor, intenta de nuevo.
              </p>
              <hr />
              <Button style={{ backgroundColor: 'black', color: 'white', borderRadius: '5px', width: '100%', padding: '10px', border: 'none', fontWeight: '700', fontFamily: 'Raleway', cursor: 'pointer' }} onClick={() => navigate('/')}>Volver al Inicio</Button>
            </Alert>
          </>
        );
      default:
        return (
          <h2>Estado de pago desconocido. Por favor, verifica el estado de tu pago.</h2>
        );
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
      <Container className="shadow" style={{ maxWidth: '900px', borderRadius: '15px', padding: '20px' }}>
        {renderContent()}
      </Container>
    </div>
  );
};

export default PaymentStatusPage;