import { useEffect, useState } from 'react';
import { auth, db } from './firebase'; // Adjust imports based on your setup
import { doc, onSnapshot } from 'firebase/firestore';

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [authChecking, setAuthChecking] = useState(true);
  const [onboardingStatus, setOnboardingStatus] = useState(null);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(userAuth => {
      if (userAuth) {
        // User is signed in.
        const userRef = doc(db, "users", userAuth.uid);
        const unsubscribeDoc = onSnapshot(userRef, (doc) => {
          const userData = doc.data();
          if (userData) {
            if (userData.onboarding_status === undefined) {
              setOnboardingStatus(false);
            } else {
              setOnboardingStatus(userData.onboarding_status);
            }
          } else {
            // Handle case where userData might not exist
            setOnboardingStatus(false);
          }
        });
        setUser(userAuth);
      } else {
        // User is signed out.
        setUser(null);
        setOnboardingStatus(false); // Set onboardingStatus to false to allow redirection to Landing page.
      }
      setAuthChecking(false);
    });

    return () => {
      unsubscribeAuth();
      // Ensure to properly manage unsubscribeDoc to avoid memory leaks or errors.
    };
  }, []);

  return { user, authChecking, onboardingStatus };
};

export { useAuth };