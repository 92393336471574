import React, { useState, useEffect } from 'react';
import { collection, getDocs, getDoc, query, where, updateDoc, doc, arrayUnion, arrayRemove, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../../../firebase';
import { useNavigate } from 'react-router-dom';
import '../../DetailItemPages/CSS/ItemDetails.css';
import '../CSS/PublicBrandProfile.css';
import '../CSS/UserWishlistPage.css';
import { ReactComponent as ArrowBackIcon } from '../../../Assets/arrow-left-solid.svg';
import { ReactComponent as ProfileIcon } from '../../../Assets/user-solid.svg';
import { ReactComponent as CartIcon } from '../../../Assets/bag-shopping-solid.svg';
import { ReactComponent as HeartHallowIcon } from '../../../Assets/heart-regular.svg';
import { ReactComponent as HeartFilledIcon } from '../../../Assets/heart-solid.svg';
import Logo from '../../../Assets/wai-logo.png';
import { ReactComponent as MoreIcon } from '../../../Assets/more-menu-icon.svg';

const UserWishlistPage = () => {
    const [wishlist, setWishlist] = useState([]);
    const [userLikedItems, setUserLikedItems] = useState([]);
    const user = auth.currentUser;
    const userId = user?.uid;
    const navigate = useNavigate();
    const [showMoreMenu, setShowMoreMenu] = useState(false);
    const [activeItem, setActiveItem] = useState(null);

    useEffect(() => {
        if (!userId) return;

        const unsubscribe = onSnapshot(doc(db, 'users', userId), (docSnapshot) => {
            const wishlistItemIds = docSnapshot.data()?.wishlist || [];
            Promise.all(wishlistItemIds.map(itemId => 
                getDoc(doc(db, 'products', itemId))
            )).then(docs => {
                const items = docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setWishlist(items);
                setUserLikedItems(docSnapshot.data().wishlist);
            }).catch(error => {
                console.error("Error fetching wishlist items: ", error);
                setWishlist([]);
                setUserLikedItems([]);
            });
        });
        return () => unsubscribe();
    }, [userId]);

    const goBack = () => {
        navigate(-1);
    }

    const handleItemClick = (id) => {
        navigate(`/product/${id}`);
    }

    const handleShowMoreMenu = () => {
        setShowMoreMenu(!showMoreMenu);
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 750) {
                setShowMoreMenu(true);
            } else {
                setShowMoreMenu(false);
            }
        };

        window.addEventListener('resize', handleResize);
        // Set initial state based on window size
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleLikeClick = async (item) => {
        const likedItems = userLikedItems || [];
        if (likedItems.includes(item.wearly_item_id)) {
            const updatedLikedItems = likedItems.filter(likedItem => likedItem !== item.wearly_item_id);
            setUserLikedItems(updatedLikedItems);
            await updateDoc(doc(db, "users", userId), {
                wishlist: arrayRemove(item.wearly_item_id)
            });
        } else {
            const updatedLikedItems = [...likedItems, item.wearly_item_id];
            setUserLikedItems(updatedLikedItems);
            await updateDoc(doc(db, "users", userId), {
                wishlist: arrayUnion(item.wearly_item_id)
            });
        }
    }

    return (
        <div className="content">
            {showMoreMenu &&
                <footer className="footer" style={{margin: '0px', padding: '0px', display: 'flex', justifyContent: 'space-between'}}>
                    <ul className="footer-links" style={{textAlign: 'left', alignItems: 'left', marginLeft: '0px'}}>
                        <li style={{marginLeft: '0px', cursor: 'pointer'}}><a href="/">Home</a></li>
                        {/* <li style={{ marginLeft: '0px', cursor: 'pointer' }} onMouseEnter={() => setActiveItem('brands')} onMouseLeave={() => setActiveItem(null)}>
                            <a>Marcas Destacadas</a>
                            {activeItem === 'brands' && (
                                <div>
                                    <ul className="hover-options">
                                        <li className="hover-button"><a>NXBO</a></li>
                                        <li className="hover-button"><a>Tranca</a></li>
                                        <li className="hover-button"><a>El del Barrio Chino</a></li>
                                    </ul>
                                </div>
                            )}
                        </li>
                        <li style={{ marginLeft: '0px', cursor: 'pointer' }} onMouseEnter={() => setActiveItem('categories')} onMouseLeave={() => setActiveItem(null)}>
                            <a>Categorias</a>
                            {activeItem === 'categories' && (
                                <div>
                                    <ul className="hover-options">
                                        <li className="hover-button"><a>Mas Vendidos</a></li>
                                        <li className="hover-button"><a>Nuevos</a></li>
                                    </ul>
                                    <ul className="hover-options">
                                        <li className="hover-button"><a>Remeras</a></li>
                                        <li className="hover-button"><a>Camperas</a></li>
                                        <li className="hover-button"><a>Pantalones</a></li>
                                    </ul>
                                </div>
                            )}
                        </li>
                        <li style={{ marginLeft: '0px', cursor: 'pointer' }} onMouseEnter={() => setActiveItem('collections')} onMouseLeave={() => setActiveItem(null)}>
                            <a>Colecciones</a>
                            {activeItem === 'collections' && (
                                <ul className="hover-options">
                                    <li className="hover-button"><a>Streetwear</a></li>
                                    <li className="hover-button"><a>Vintage</a></li>
                                    <li className="hover-button"><a>Sneakers</a></li>
                                </ul>
                            )}
                        </li> */}
                    </ul>
                    {window.innerWidth > 750 &&
                        <ul className="footer-links" style={{textAlign: 'right', alignItems: 'right', marginRight: '0px'}}>
                            <li style={{marginRight: '0px', fontStyle: 'italic', textDecoration: 'underline'}}><a href="/wishlist">Lista de Deseos</a></li>
                            <li style={{marginRight: '0px', fontStyle: 'italic', textDecoration: 'underline'}}><a href="/profile-settings">Perfil</a></li>
                        </ul>
                    }
                </footer>
            }

            <div className='mobile-navbar'>
                <div className='mobile-navbar-cart'>
                    <button className='navbar-more-button' onClick={() => handleShowMoreMenu()}>
                        <MoreIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
                    </button>
                </div>
                <div className='mobile-navbar-logo'>
                    <div className='logo' style={{width:'140px', height:'60px'}}>
                        <img src={Logo} onClick={() => navigate('/')} alt="Logo" className='logo-img' style={{ width: '100%', height: '100%', objectFit: 'contain', padding:'10%' }} />
                    </div>
                </div>
                <div className='navbar-profile'>
                    <button className='navbar-profile-button' onClick={() => navigate('/profile-settings')}>
                        <ProfileIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
                    </button>
                </div>
                <div className='navbar-heart'>
                    <button className='navbar-heart-button'>
                        <HeartHallowIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
                    </button>
                </div>
                <div className='mobile-navbar-cart'>
                    <button className='navbar-cart-button' onClick={() => navigate('/cart')}>
                        <CartIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
                    </button>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <button className="back-button-client" style={{ marginBottom: '0px' }} onClick={goBack}>
                    <ArrowBackIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
                </button>
                <h1 className='cart-title' style={{ marginLeft: '20px', fontWeight: '900' }}>Tu Lista de Deseos</h1>
            </div>
            <div className="wishlist-page">
                <div className="wishlist-grid">
                    {wishlist.length > 0 ? (
                        wishlist.map((item) => (
                            <div style={{cursor: 'pointer'}} key={item.wearly_item_id} className="wishlist-card" onClick={() => handleItemClick(item.wearly_item_id)}>
                                <img src={item.item_image} alt={item.item_name} />
                                <button className="like-button" style={{marginRight: '5px', marginTop: '5px'}} onClick={(e) => {
                                    e.stopPropagation(); // Prevents the click from triggering other click events
                                    handleLikeClick(item);
                                }}>
                                    {userLikedItems && userLikedItems.includes(item.wearly_item_id) ? 
                                        <HeartFilledIcon style={{ width: '20px', height: '20px', fill: 'red' }} /> :
                                        <HeartHallowIcon style={{ width: '20px', height: '20px' }} />
                                    }
                                </button>
                            </div>
                        ))
                    ) : (
                        <p>No items in wishlist</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UserWishlistPage;
