import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../../firebase';
import { ReactComponent as SearchIcon } from '../../../Assets/magnifying-glass-solid.svg';
import { ReactComponent as HeartHallowIcon } from '../../../Assets/heart-regular.svg';
import { ReactComponent as HeartFilledIcon } from '../../../Assets/heart-solid.svg';
import { ReactComponent as ProfileIcon } from '../../../Assets/user-solid.svg';
import Logo from '../../../Assets/wai-logo.png';
import { ReactComponent as CartIcon } from '../../../Assets/bag-shopping-solid.svg';
import { ReactComponent as MoreIcon } from '../../../Assets/more-menu-icon.svg';
import { doc, getDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import Modal from 'react-modal';
import '../CSS/UserProfileSettings.css';
import ArrowBackIcon from '../../../Assets/arrow-left-solid.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as SettingsIcon } from '../../../Assets/search-settings-icon.svg';

Modal.setAppElement('#root');

function ProfileSettings() {
    const [userData, setUserData] = useState({
        preferences: {},
        user_address: {}
    });
    const [userPurchases, setUserPurchases] = useState([]);
    const [activeSection, setActiveSection] = useState('ajustes');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const [showMoreMenu, setShowMoreMenu] = useState(false);
    const [showLeftSidebar, setShowLeftSidebar] = useState(false);
    const [placeholder, setPlaceholder] = useState('');
    const [textSearch, setTextSearch] = useState('');
    const [showSearchSettingsModal, setShowSearchSettingsModal] = useState(false);
    const [recomendedSearches, setRecomendedSearches] = useState(null);
    const [placeholderIndex, setPlaceholderIndex] = useState(0);
    const [typingIndex, setTypingIndex] = useState(0);
    const [activeItem, setActiveItem] = useState(null);
    const [savingSettings, setSavingSettings] = useState(false);
    const occasions_examples_list = [
        "Tengo una joda en la playa.",
        "Vestime como Taylor Swift.",
        "Reunion en la oficina.",
        "https://pinterest.com/pin/...",
        "Yendo a un casamiento.",
        "Quiero verme como la Rosalia",
        "Un día de museo.",
        "Dame el estilo de CRO.",
        'https://instagram.com/p/...',
        "https://pinterest.com/pin/...",
        "Vamos a un boliche.",
        "Noche de cine.",
        "Picnic de verano en la plaza.",
        "https://pinterest.com/pin/...",
        "Volley en la playa.",
        "Mañana de GYM.",
        'https://instagram.com/p/...',
        "Festejo de cumpleaños.",
        "https://pinterest.com/pin/...",
        "Brunch de domingo con la flia.",
        "Me voy a hacer ski a la montaña.",
        "Me voy al fashionweek.",
    ];

    const provinces = ["Buenos Aires", "Ciudad Autónoma de Buenos Aires", "Catamarca", "Chaco", "Chubut", "Córdoba", "Corrientes", "Entre Ríos", "Formosa", "Jujuy", "La Pampa", "La Rioja", "Mendoza", "Misiones", "Neuquén", "Río Negro", "Salta", "San Juan", "San Luis", "Santa Cruz", "Santa Fe", "Santiago del Estero", "Tierra del Fuego", "Tucumán"];

    useEffect(() => {
        let typewriterIntervalId;
        if (placeholderIndex !== null) {
            typewriterIntervalId = setInterval(() => {
                setTypingIndex((prevTypingIndex) => {
                    const phrase = occasions_examples_list[placeholderIndex];
                    if (prevTypingIndex < phrase.length) {
                        setPlaceholder(phrase.slice(0, prevTypingIndex + 1));
                        return prevTypingIndex + 1;
                    } else {
                        clearInterval(typewriterIntervalId);
                        return prevTypingIndex;
                    }
                });
            }, 150);
        }
    
        return () => clearInterval(typewriterIntervalId);
    }, [placeholderIndex]);

    useEffect(() => {
        const fetchUserData = async () => {
            const user = auth.currentUser;
            if (user) {
                const userId = user.uid;
                const userDocRef = doc(db, 'users', userId);
                try {
                    const docSnap = await getDoc(userDocRef);
                    if (docSnap.exists()) {
                        setUserData(docSnap.data());
                    } else {
                        console.log("No such document!");
                    }
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            }
        };

        const fetchUserPurchases = async () => {
            const user = auth.currentUser;
            if (user) {
                const userId = user.uid;
                const purchasesRef = collection(db, 'user_purchases');
                const q = query(purchasesRef, where('user_id', '==', userId), where('payment_status', '==', 'APPROVED'));
                try {
                    const querySnapshot = await getDocs(q);
                    const purchases = await Promise.all(querySnapshot.docs.map(async (docSnapshot) => {
                        const purchase = docSnapshot.data();
                        const cartItems = await Promise.all(purchase.cart_items.map(async (item) => {
                            const itemDocRef = doc(db, 'products', item.wearly_item_id);
                            const itemDocSnap = await getDoc(itemDocRef);
                            if (itemDocSnap.exists()) {
                                return {
                                    ...item,
                                    itemData: itemDocSnap.data()
                                };
                            } else {
                                console.log(`No item found for ID ${item.wearly_item_id}`);
                                return item;
                            }
                        }));
                        return {
                            ...purchase,
                            cart_items: cartItems,
                            purchase_id: docSnapshot.id
                        };
                    }));
                    setUserPurchases(purchases);
                    console.log("User Purchases: ", purchases);
                } catch (error) {
                    console.error("Error fetching user purchases:", error);
                }
            }
        };

        fetchUserData();
        fetchUserPurchases();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevState) => {
            if (name in prevState.user_address) {
                return {
                    ...prevState,
                    user_address: {
                        ...prevState.user_address,
                        [name]: value
                    }
                };
            }
            return {
                ...prevState,
                [name]: value
            };
        });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setUserData((prevState) => ({
            ...prevState,
            preferences: {
                ...prevState.preferences,
                [name]: checked
            }
        }));
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        let searchText = '';
        // Check if the event is triggered from the form submission
        if (e.target.tagName === 'FORM') {
            searchText = e.target.elements[0].value; // Assuming the first element is the input
        } else if (e.target.tagName === 'INPUT') {
            // If the event is triggered by onKeyDown on the input
            searchText = e.target.value;
        }
    
        if (searchText) {
            if (searchText.startsWith('https://')) {
                // no url search yet
                console.log("no url search yet");

            } else {
                // Handle text search
                // send to home with search text
                navigate('/', { state: { searchText } });
            }
        }
    }

    const handleSaveChanges = async () => {
        setSavingSettings(true);
        const user = auth.currentUser;
        if (!user) {
            console.error("User not authenticated");
            setSavingSettings(false);
            return;
        }

        try {
            const userDocRef = doc(db, 'users', user.uid);
            await updateDoc(userDocRef, {
                ...userData,
                user_address: {
                    ...userData.user_address
                }
            });

            toast.success("Data saved successfully!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setSavingSettings(false);
        } catch (error) {
            setSavingSettings(false);
            console.error("Error saving data: ", error);
            toast.error("Error saving data.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const navigateHome = () => {
        navigate('/');
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSignOut = async () => {
        closeModal();
        try {
            await auth.signOut();
            navigate('/');
        } catch (error) {
            console.error("Error signing out:", error);
        }
    };

    const handleShowMoreMenu = () => {
        setShowMoreMenu(!showMoreMenu);
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 750) {
                setShowMoreMenu(true);
                setShowLeftSidebar(false);
            } else {
                setShowMoreMenu(false);
                setShowLeftSidebar(true);
            }
        };

        window.addEventListener('resize', handleResize);
        // Set initial state based on window size
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleWearLookUpload = () => {
        // handle upload image from user input (file input) also for mobile
        // compress image
        // upload image to storage
        // get download url
        // update user data
        
    }

    const firstName = userData.user_address.first_name;
    const lastName = userData.user_address.last_name;
    const id = userData.user_address.id_number;
    const phone_number = userData.user_address.phone_number;
    const {user_address, street_number, apt_number, floor, neighborhood, city, province, postal_code, notes, preferences } = userData.user_address || {};
    const email = userData.email || '';

    return (
        <div className="content">
            {showMoreMenu &&
                <footer className="footer" style={{margin: '0px', padding: '0px', display: 'flex', justifyContent: 'space-between'}}>
                    <ul className="footer-links" style={{textAlign: 'left', alignItems: 'left', marginLeft: '0px'}}>
                        <li style={{marginLeft: '0px', cursor: 'pointer'}}><a href="/">Home</a></li>
                        {/* <li style={{ marginLeft: '0px', cursor: 'pointer' }} onMouseEnter={() => setActiveItem('brands')} onMouseLeave={() => setActiveItem(null)}>
                            <a>Marcas Destacadas</a>
                            {activeItem === 'brands' && (
                                <div>
                                    <ul className="hover-options">
                                        <li className="hover-button"><a>NXBO</a></li>
                                        <li className="hover-button"><a>Tranca</a></li>
                                        <li className="hover-button"><a>El del Barrio Chino</a></li>
                                    </ul>
                                </div>
                            )}
                        </li>
                        <li style={{ marginLeft: '0px', cursor: 'pointer' }} onMouseEnter={() => setActiveItem('categories')} onMouseLeave={() => setActiveItem(null)}>
                            <a>Categorias</a>
                            {activeItem === 'categories' && (
                                <div>
                                    <ul className="hover-options">
                                        <li className="hover-button"><a>Mas Vendidos</a></li>
                                        <li className="hover-button"><a>Nuevos</a></li>
                                    </ul>
                                    <ul className="hover-options">
                                        <li className="hover-button"><a>Remeras</a></li>
                                        <li className="hover-button"><a>Camperas</a></li>
                                        <li className="hover-button"><a>Pantalones</a></li>
                                    </ul>
                                </div>
                            )}
                        </li>
                        <li style={{ marginLeft: '0px', cursor: 'pointer' }} onMouseEnter={() => setActiveItem('collections')} onMouseLeave={() => setActiveItem(null)}>
                            <a>Colecciones</a>
                            {activeItem === 'collections' && (
                                <ul className="hover-options">
                                    <li className="hover-button"><a>Streetwear</a></li>
                                    <li className="hover-button"><a>Vintage</a></li>
                                    <li className="hover-button"><a>Sneakers</a></li>
                                </ul>
                            )}
                        </li> */}
                    </ul>
                    {window.innerWidth > 750 &&
                        <ul className="footer-links" style={{textAlign: 'right', alignItems: 'right', marginRight: '0px'}}>
                            <li style={{marginRight: '0px', fontStyle: 'italic', textDecoration: 'underline'}}><a href="/wishlist">Lista de Deseos</a></li>
                            <li style={{marginRight: '0px', fontStyle: 'italic', textDecoration: 'underline'}}><a href="/profile-settings">Perfil</a></li>
                        </ul>
                    }
                </footer>
            }

            <div className='mobile-navbar'>
                <div className='mobile-navbar-cart'>
                    <button className='navbar-more-button' onClick={() => handleShowMoreMenu()}>
                        <MoreIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
                    </button>
                </div>
                <div className='mobile-navbar-logo'>
                    <div className='logo' style={{width:'140px', height:'60px'}}>
                        <img src={Logo} onClick={() => navigate('/')} alt="Logo" className='logo-img' style={{ width: '100%', height: '100%', objectFit: 'contain', padding:'10%' }} />
                    </div>
                </div>
                <div className='navbar-profile'>
                    <button className='navbar-profile-button' onClick={() => navigate('/profile-settings')}>
                        <ProfileIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
                    </button>
                </div>
                <div className='navbar-heart'>
                    <button className='navbar-heart-button' onClick={() => navigate('/wishlist')}>
                        <HeartHallowIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
                    </button>
                </div>
                <div className='mobile-navbar-cart'>
                    <button className='navbar-cart-button' onClick={() => navigate('/cart')}>
                        <CartIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
                    </button>
                </div>
            </div>
            <div className="navbar">
                <div className="navbar-logo">
                    <div className="logo" style={{width:'110px', height:'70px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <img src={Logo} onClick={() => navigate('/')} alt="Logo" className='logo-img' style={{ width: '80%', height: '80%', objectFit: 'contain', padding:'10%' }} />
                    </div>
                </div>
                <div className="navbar-search">
                    <form onSubmit={handleSearch} style={{ display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                        <div className='input-wrapper' style={{ width: '100%', height: '50px', position: 'relative'}}>
                            <input
                                type="text"
                                placeholder={placeholder}
                                value={textSearch}
                                className='search-bar'
                                required
                                onChange={e => setTextSearch(e.target.value)}
                                onKeyDown={(e) => { if (e.key === 'Enter') { handleSearch(e); } }} // Use Enter key to submit
                                style={{ 
                                    fontFamily: 'Raleway', 
                                    fontWeight:'700',
                                    height: '100%', 
                                    padding: '10px',
                                    borderRadius: '15px', 
                                    paddingLeft: '50px', // Adjusted paddingLeft to make space for the icon
                                    paddingRight: '20px', // Adjusted paddingRight
                                    boxSizing: 'border-box',
                                    animation: 'fadeEffect 1.5s' // CSS animation for fade effect
                                }}
                            />
                            <div className="search-icon" style={{ position: 'absolute', left: '20px', top: '50%', transform: 'translateY(-50%)'}}>
                                <SearchIcon style={{ width: '20px', height: '20px', color: '#C4C4C4' }} />
                            </div>
                            {/* <div clasxsName="settings-icon-landing" style={{
                                position: 'absolute',
                                right: '20px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '50%',
                                width: '40px',
                                height: '40px',
                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    cursor: 'pointer'
                                }} onClick={() => setShowSearchSettingsModal(true)}>
                                    <SettingsIcon style={{ width: '15px', height: '15px' }} />
                                </div>
                            </div> */}
                        </div>
                        {/* Removed the button to make the form submit with Enter key press */}
                    </form>
                </div>
                <div className="navbar-cart">
                    <button className="navbar-cart-button" onClick={() => navigate('/cart')}>
                        CART
                    </button>
                </div>
            </div>
            {showLeftSidebar && <div className="user-profile-sidebar">
                <ul className="user-profile-sidebar-links">
                    <li className={activeSection === 'ajustes' ? 'active' : ''} onClick={() => setActiveSection('ajustes')}>Ajustes</li>
                    <li className={activeSection === 'notificaciones' ? 'active' : ''} onClick={() => setActiveSection('notificaciones')}>Notificaciones</li>
                    <li className={activeSection === 'perfilPublico' ? 'active' : ''} onClick={() => setActiveSection('perfilPublico')}>Perfil Publico</li>
                    <li className={activeSection === 'guardaropa' ? 'active' : ''} onClick={() => setActiveSection('guardaropa')}>Tu Guardaropa</li>
                    <li className={activeSection === 'wearLook' ? 'active' : ''} onClick={() => setActiveSection('wearLook')}>WearLook (Probador Virtual)</li>
                    <li onClick={openModal}>Cerrar Sesion</li>
                </ul>
            </div>}
            <div className="user-profile-container">
                {!showLeftSidebar && <div className="user-profile-sidebar">
                    <h2 className="user-profile-sidebar-title">Tu Perfil</h2>
                    <ul className="user-profile-sidebar-links">
                        <li className={activeSection === 'ajustes' ? 'active' : ''} onClick={() => setActiveSection('ajustes')}>Ajustes</li>
                        <li className={activeSection === 'notificaciones' ? 'active' : ''} onClick={() => setActiveSection('notificaciones')}>Notificaciones</li>
                        <li className={activeSection === 'perfilPublico' ? 'active' : ''} onClick={() => setActiveSection('perfilPublico')}>Perfil Publico</li>
                    <li className={activeSection === 'guardaropa' ? 'active' : ''} onClick={() => setActiveSection('guardaropa')}>Tu Guardaropa</li>
                        <li className={activeSection === 'wearLook' ? 'active' : ''} onClick={() => setActiveSection('wearLook')}>WearLook (Probador Virtual)</li>
                        <li onClick={openModal}>Cerrar Sesion</li>
                    </ul>
                </div>}
                <div className="user-profile-settings">
                    {activeSection === 'ajustes' && (
                        <div className="user-profile-settings-section">
                            <div className="user-profile-settings-item">
                                <label className="user-profile-settings-item-label">Nombre</label>
                                <input
                                    type="text"
                                    name="first_name"
                                    value={firstName || ''}
                                    onChange={handleInputChange}
                                    className="user-profile-settings-item-value"
                                />
                            </div>
                            <div className="user-profile-settings-item">
                                <label className="user-profile-settings-item-label">Apellido</label>
                                <input
                                    type="text"
                                    name="last_name"
                                    value={lastName || ''}
                                    onChange={handleInputChange}
                                    className="user-profile-settings-item-value"
                                />
                            </div>
                            <div className="user-profile-settings-item">
                                <label className="user-profile-settings-item-label">DNI</label>
                                <input
                                    type="text"
                                    name="id_number"
                                    value={id || ''}
                                    onChange={handleInputChange}
                                    className="user-profile-settings-item-value"
                                />
                            </div>
                            <div className="user-profile-settings-item">
                                <label className="user-profile-settings-item-label">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={email || ''}
                                    onChange={handleInputChange}
                                    className="user-profile-settings-item-value"
                                />
                            </div>
                            <div className="user-profile-settings-item">
                                <label className="user-profile-settings-item-label">Teléfono</label>
                                <input
                                    type="text"
                                    name="phone_number"
                                    value={phone_number || ''}
                                    onChange={handleInputChange}
                                    className="user-profile-settings-item-value"
                                />
                            </div>
                            <div className="user-profile-settings-item">
                                <label className="user-profile-settings-item-label">Calle</label>
                                <input
                                    type="text"
                                    name="street"
                                    value={user_address?.street || ''}
                                    onChange={handleInputChange}
                                    className="user-profile-settings-item-value"
                                />
                            </div>
                            <div className="user-profile-settings-item">
                                <label className="user-profile-settings-item-label">Nº de Calle</label>
                                <input
                                    type="text"
                                    name="street_number"
                                    value={street_number || ''}
                                    onChange={handleInputChange}
                                    className="user-profile-settings-item-value"
                                />
                            </div>
                            <div className="user-profile-settings-item">
                                <label className="user-profile-settings-item-label">Nº de Apartamento</label>
                                <input
                                    type="text"
                                    name="apt_number"
                                    value={apt_number || ''}
                                    onChange={handleInputChange}
                                    className="user-profile-settings-item-value"
                                />
                            </div>
                            <div className="user-profile-settings-item">
                                <label className="user-profile-settings-item-label">Piso</label>
                                <input
                                    type="text"
                                    name="floor"
                                    value={floor || ''}
                                    onChange={handleInputChange}
                                    className="user-profile-settings-item-value"
                                />
                            </div>
                            <div className="user-profile-settings-item">
                                <label className="user-profile-settings-item-label">Barrio</label>
                                <input
                                    type="text"
                                    name="neighborhood"
                                    value={neighborhood || ''}
                                    onChange={handleInputChange}
                                    className="user-profile-settings-item-value"
                                />
                            </div>
                            <div className="user-profile-settings-item">
                                <label className="user-profile-settings-item-label">Ciudad</label>
                                <input
                                    type="text"
                                    name="city"
                                    value={city || ''}
                                    onChange={handleInputChange}
                                    className="user-profile-settings-item-value"
                                />
                            </div>
                            <div className="user-profile-settings-item">
                                <label className="user-profile-settings-item-label">Provincia</label>
                                <select
                                    name="province"
                                    value={province || ''}
                                    onChange={handleInputChange}
                                    className="user-profile-settings-item-value"
                                    style={{
                                        padding: '10px',
                                        border: 'none',
                                        borderRadius: '10px',
                                        fontSize: '12px',
                                        fontWeight: '700',
                                        backgroundColor: '#f0f0f0',
                                        appearance: 'none',
                                        backgroundImage: 'url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E")',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'right 10px top 50%',
                                        backgroundSize: '12px auto'
                                      }}
                                    >
                                    {provinces.map((prov, index) => (
                                        <option key={index} value={prov}>{prov}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="user-profile-settings-item">
                                <label className="user-profile-settings-item-label">Codigo Postal</label>
                                <input
                                    type="text"
                                    name="postal_code"
                                    value={postal_code || ''}
                                    onChange={handleInputChange}
                                    className="user-profile-settings-item-value"
                                />
                            </div>
                            <div className="user-profile-settings-item">
                                <label className="user-profile-settings-item-label">Notas</label>
                                <input
                                    type="text"
                                    name="notes"
                                    value={notes || ''}
                                    onChange={handleInputChange}
                                    className="user-profile-settings-item-value"
                                />
                            </div>
                        </div>
                    )}
                    {activeSection === 'notificaciones' && (
                        <div className="user-profile-settings-section">
                            <h3>Notificaciones</h3>
                            <div className="user-profile-settings-item">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="new_features"
                                        checked={userData.preferences.new_features || false}
                                        onChange={handleCheckboxChange}
                                    />
                                    Nuevas funcionalidades y actualizaciones
                                </label>
                            </div>
                            <div className="user-profile-settings-item">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="trends_campaigns"
                                        checked={userData.preferences.trends_campaigns || false}
                                        onChange={handleCheckboxChange}
                                    />
                                    Tendencias, campañas y más
                                </label>
                            </div>
                            <div className="user-profile-settings-item">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="promotions"
                                        checked={userData.preferences.promotions || false}
                                        onChange={handleCheckboxChange}
                                    />
                                    Promociones
                                </label>
                            </div>
                            <div className="user-profile-settings-item">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="purchase_updates"
                                        checked={userData.preferences.purchase_updates || false}
                                        onChange={handleCheckboxChange}
                                    />
                                    Actualizaciones de compras
                                </label>
                            </div>
                            {/* <div className="user-profile-settings-item">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="sale_tips"
                                        checked={userData.preferences.sale_tips || false}
                                        onChange={handleCheckboxChange}
                                    />
                                    Consejos de venta y actualizaciones
                                </label>
                            </div> */}
                            <div className="user-profile-settings-item">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="personalized_recommendations"
                                        checked={userData.preferences.personalized_recommendations || false}
                                        onChange={handleCheckboxChange}
                                    />
                                    Recomendaciones personalizadas
                                </label>
                            </div>
                            <div className="user-profile-settings-item">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="special_offers"
                                        checked={userData.preferences.special_offers || false}
                                        onChange={handleCheckboxChange}
                                    />
                                    Ofertas especiales de vendedores
                                </label>
                            </div>
                        </div>
                    )}
                    {activeSection === 'perfilPublico' && (
                        <div className="user-profile-settings-section">
                            <h3>Perfil Publico</h3>
                            <p>Estamos trabajando en esta sección</p>
                        </div>
                    )}
                    {activeSection === 'guardaropa' && (
                        <div className="user-profile-settings-section">
                            <h3>Tu Guardaropa</h3>
                            {userPurchases.length > 0 ? (
                                userPurchases.map((purchase, index) => (
                                    <div key={index} style={{ cursor: 'pointer', padding: '10px', boxSizing: 'border-box', border: '1px solid #E0E0E0', borderRadius: '15px', margin: '10px' }}>
                                        {purchase.cart_items.map((item, itemIndex) => (
                                            <div key={itemIndex} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', boxSizing: 'border-box', borderRadius: '15px', margin: '5px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {item.itemData ? (
                                                        <>
                                                            <img src={item.itemData.item_images[0]} alt={item.itemData.name} style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px', borderRadius: '15px' }} />
                                                            <div style={{ marginTop: '0', marginBottom: '5px', alignSelf: 'flex-start' }}>
                                                                <h4 style={{ marginTop: '0', marginBottom: '10px' }}>Purchase ID: #{purchase.purchase_id}</h4>
                                                                <h4 style={{ marginTop: '0', marginBottom: '10px' }}>{item.itemData.name}</h4>
                                                                <h4 style={{ marginTop: '0', marginBottom: '10px' }}>{item.itemData.brand}</h4>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <p>Item data not available</p>
                                                    )}
                                                </div>
                                                <div>
                                                    {/* ${item.itemData.price} */}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))
                            ) : (
                                <p>No purchases found</p>
                            )}
                        </div>
                    )}
                    {activeSection === 'wearLook' && (
                        <div className="user-profile-settings-section">
                            <h3>WearLook (Probador Virtual)</h3>
                            <div 
                                onClick={handleWearLookUpload}
                                style={{
                                    width: '250px',
                                    height: '330px',
                                    borderRadius: '25px',
                                    objectFit: 'cover',
                                    border: '4px dashed #E0E0E0',
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: '#A0A0A0',
                                    cursor: 'pointer'
                                }}
                            >
                                Subir una foto
                            </div>
                            {userData.user_vton_image ? (
                                <img style={{width: '250px', height: '100%', borderRadius: '25px', objectFit: 'cover', border: '4px dashed #E0E0E0'}} src={userData.user_vton_image} alt="wearLook" />
                            ) : (
                                <p style={{width: '250px', height: '330px', borderRadius: '25px', objectFit: 'cover', border: '4px dashed #E0E0E0'}} />
                            )}
                        </div>
                    )}
                    {activeSection !== 'guardaropa' && activeSection !== 'perfilPublico' && (
                        <button style={{marginBottom: '50px'}} className="user-profile-save-changes-btn" onClick={handleSaveChanges} disabled={savingSettings}>
                            {savingSettings ? 'Guardando...' : 'Guardar Cambios'}
                        </button>
                    )}
                </div>
                <ToastContainer />
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    contentLabel="Confirm Sign Out"
                    className="user-profile-modal-sign-out"
                    overlayClassName="user-profile-modal-sign-out-overlay"
                >
                    <div style={{marginTop: 0}} className="user-profile-modal-sign-out-content">
                        <h2 className="user-profile-modal-sign-out-header">Confirm Sign Out</h2>
                        <p className="user-profile-modal-sign-out-text">Are you sure you want to sign out?</p>
                        <div className="user-profile-modal-sign-out-buttons">
                            <button onClick={handleSignOut} className="user-profile-modal-sign-out-button">Yes</button>
                            <button onClick={closeModal} className="user-profile-modal-sign-out-button">No</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
}

export default ProfileSettings;
