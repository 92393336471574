// Creat a simple page with the pilcha logo and a serach bar in the middle of the page. If the user is not logged in show a email and password input. If the user is logged in show a logout button.

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ReactComponent as SearchIcon } from '../Assets/magnifying-glass-solid.svg';
import Logo from '../Assets/wai-logo-white.png';
import QRCode from "react-qr-code";


const PilchaPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [textSearch, setTextSearch] = useState(''); // Add this state
  const user = auth.currentUser;
  const functions = getFunctions();
  const searchMagic = httpsCallable(functions, 'pilcha_search');
  const getItemsByStyle = httpsCallable(functions, 'get_items_by_style_pilcha');
  const logAnalyticsEventPilcha = httpsCallable(functions, 'click_analytics_event_pilcha');
  const [placeholder, setPlaceholder] = useState(""); // Add this placeholder
  const [itemList, setItemList] = useState([]);
  const [placeholderIndex, setPlaceholderIndex] = useState(null);
  const [typingIndex, setTypingIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingPhrase, setLoadingPhrase] = useState('');
  const [queryId, setQueryId] = useState('');
  const [suggestedStyles, setSuggestedStyles] = useState(["streetwear", "casual", "vintage", "formal", "retro", 'avantgarde', 'sporty']);
  const occasions_examples_list = [
    "Tengo una joda en la playa.",
    "Vestime como Taylor Swift.",
    "Reunion en la oficina.",
    // "https://pinterest.com/pin/...",
    "Yendo a un casamiento.",
    "Quiero verme como la Rosalia",
    "Un día de museo.",
    "Dame el estilo de CRO.",
    // 'https://instagram.com/p/...',
    // "https://pinterest.com/pin/...",
    "Vamos a un boliche.",
    "Noche de cine.",
    "Picnic de verano en la plaza.",
    // "https://pinterest.com/pin/...",
    "Volley en la playa.",
    "Mañana de GYM.",
    // 'https://instagram.com/p/...',
    "Festejo de cumpleaños.",
    // "https://pinterest.com/pin/...",
    "Brunch de domingo con la flia.",
    "Me voy a hacer ski a la montaña.",
    "Me voy al fashionweek.",
];
const loading_image_array = [
    'https://acdn.mitiendanube.com/stores/001/177/291/products/cargomarinofrente-cab943f6b382888c5b17132410230292-1024-1024.webp',
    'https://acdn.mitiendanube.com/stores/001/177/291/products/g1-42ad8fb2dddfd82d6e17130512410782-1024-1024.webp',
    'https://acdn.mitiendanube.com/stores/951/831/products/hoodiefront2-f0c84b0d650d1d18c117176216772017-1024-1024.webp',
    'https://acdn.mitiendanube.com/stores/951/831/products/hype_web_png_v21-min-b3916e48ae0c6f714317134535649845-1024-1024.webp',
    'https://acdn.mitiendanube.com/stores/001/691/011/products/web-44-740d40988f97f5d7df17173751018814-1024-1024.webp',
    'https://img.ssensemedia.com/images/g_center,f_auto/241232F127015_1/rick-owens-black-mega-bumper-sneakers.jpg',
    'https://acdn.mitiendanube.com/stores/002/012/649/products/campera-negra-tienda-1-b3d4fcc18e17584c3d17135584568571-1024-1024.webp',
    'https://www.acnestudios.com/dw/image/v2/AAXV_PRD/on/demandware.static/-/Sites-acne-product-catalog/default/dwbf9d7240/images/A0/A00443-/2000x/A00443-BUF_F.jpg?sw=2000&sh=3000',
    'https://img.ssensemedia.com/images/b_white,c_lpad,g_south,h_1086,w_724/c_scale,h_480/f_auto,dpr_2.0/241553F048021_1/jacquemus-blue-le-petit-bambimou-bag.jpg',
    'https://us.kowtowclothing.com/cdn/shop/products/oversized_boxy_tee-white-grid-01copy.jpg?v=1707354614&width=1946',
    'https://media.gq.com/photos/5aaa963999d53d444a5d8f9d/3:4/w_748%2Cc_limit/Zara-pleated-trousers.jpg',
    'https://acdn.mitiendanube.com/stores/003/975/385/products/jordan-negro-03-1498a69c2cca1bd8fb17019794225329-1024-1024.webp',
    'https://acdn.mitiendanube.com/stores/001/339/958/products/foto-tn-f6b1170901ec9c708317121673523791-1024-1024.webp',
    'https://www.acnestudios.com/dw/image/v2/AAXV_PRD/on/demandware.static/-/Sites-acne-product-catalog/default/dwbf9d7240/images/A0/A00443-/2000x/A00443-BUF_F.jpg?sw=2000&sh=3000',
    'https://i.pinimg.com/474x/91/ac/34/91ac343035f22cb37ffffddea3c06ea4.jpg',
    'https://i.pinimg.com/474x/d3/82/53/d38253cbd281df2838203c79c4aaa0c6.jpg',
    'https://i.pinimg.com/564x/fb/14/8e/fb148ec1ea5c5ae403b8ad80a11ccd4a.jpg',
    'https://acdn.mitiendanube.com/stores/001/339/958/products/base-foto-tienda-nube_mesa-de-trabajo-1-copia-61-a4fcba5aecbbd74c3416890049135587-1024-1024.webp',
    'https://acdn.mitiendanube.com/stores/003/975/385/products/milan-habano-03-7c1582f920b00ff71e17019789042479-1024-1024.webp',
    'https://acdn.mitiendanube.com/stores/001/587/536/products/buzonegro-8e291d60922ca2b4d517159729830109-1024-1024.webp',
    'https://i.pinimg.com/474x/fe/6e/27/fe6e278697f7890c4a5a2dce3bcc0f90.jpg',
    'https://i.pinimg.com/474x/04/4e/9a/044e9a67303ce126a735a01069dda086.jpg',
    'https://i.pinimg.com/564x/bd/a4/50/bda450802ad0dcb60ce3d2c7d96c0bc7.jpg',
    'https://i.pinimg.com/736x/92/30/8a/92308ada6b30291398071c028f90df09.jpg',
    'https://i.pinimg.com/736x/4f/eb/5b/4feb5b15915122ebbda54116874ea925.jpg',
    'https://acdn.mitiendanube.com/stores/001/807/964/products/frame-607-3b7076d74d11184a2517172815922340-1024-1024.webp',
]
const [currentLoadingImages, setCurrentLoadingImages] = useState(loading_image_array.slice(0, 5));
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupQR, setShowPopupQR] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [idleTimeout, setIdleTimeout] = useState(null);
  const [clothingType, setClothingType] = useState('');
  const [showClothingTypePopup, setShowClothingTypePopup] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState(null);

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);

    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setShowClothingTypePopup(true);
  };

  const handleClothingTypeSelection = async (type) => {
    setClothingType(type);
    setShowClothingTypePopup(false);
    setLoading(true);
    setItemList([]);
    console.log(user.uid);
    try {
      let result;
      if (selectedStyle) {
        result = await getItemsByStyle({ style: selectedStyle, gender: type });
      } else {
        result = await searchMagic({ search_text: textSearch, gender: type });
      }
      console.log(result.data);
      setItemList(result.data.results || result.data);
      setQueryId(result.data.query_id);
      setLoading(false);
      setSelectedStyle(null);
    } catch (error) {
      console.error('Search failed:', error);
      setLoading(false);
    }
  };

    const clearSearchClick = () => {
        setTextSearch('');
        setItemList(null);
    }

    useEffect(() => {
        const phrases = ["Buscando el outfit más cool...", "Casi listo...", "Un momento mágico...", "Encontrando las mejores combinaciones...", "Preparando tu look perfecto...", "Creando magia en tu armario..."];
        let phraseIndex = 0;

        const intervalId = setInterval(() => {
            setLoadingPhrase(phrases[phraseIndex]);
            phraseIndex = (phraseIndex + 1) % phrases.length;
        }, 3000); // Change phrase every 3 seconds

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        let lastRandomIndex = -1;
        const changePlaceholderIntervalId = setInterval(() => {
            let randomIndex;
            do {
                randomIndex = Math.floor(Math.random() * occasions_examples_list.length);
            } while (randomIndex === lastRandomIndex);
            lastRandomIndex = randomIndex;
            setPlaceholderIndex(randomIndex);
            setTypingIndex(0); // Reset typing index for new phrase
        }, 5000); // Change phrase every 3 seconds TODO: CHange to make it adaptable to phrase length
    
        return () => clearInterval(changePlaceholderIntervalId);
    }, []);

    const numberFormat = (value) =>
        new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(value);
    
    useEffect(() => {
        let typewriterIntervalId;
        if (placeholderIndex !== null) {
            typewriterIntervalId = setInterval(() => {
                setTypingIndex((prevTypingIndex) => {
                    const phrase = occasions_examples_list[placeholderIndex];
                    if (prevTypingIndex < phrase.length) {
                        setPlaceholder(phrase.slice(0, prevTypingIndex + 1));
                        return prevTypingIndex + 1;
                    } else {
                        clearInterval(typewriterIntervalId);
                        return prevTypingIndex;
                    }
                });
            }, 150);
        }
    
        return () => clearInterval(typewriterIntervalId);
    }, [placeholderIndex]);

    const handleItemClick = (item) => {
      setSelectedItem(item);
      setShowPopup(true);

      logAnalyticsEventPilcha({ query_id: queryId, item_id: item.wearly_item_id });
    };

    const closePopup = () => {
      setShowPopup(false);
      setSelectedItem(null);
    };

    const handleLogoClick = () => {
      setItemList(null);
      setQueryId('');
      setTextSearch('');
      setShowPopup(false);
    };

    useEffect(() => {
      let timeoutId;

      const resetIdleTimeout = () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        if (!loading && itemList && itemList.length > 0) {
          timeoutId = setTimeout(() => {
            setItemList(null);
            setQueryId('');
            setTextSearch('');
            setShowPopup(false);
          }, 60000);
        }
      };

      const handleUserActivity = () => {
        if (!loading && itemList && itemList.length > 0) {
          resetIdleTimeout();
        }
      };

      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity);
      window.addEventListener('scroll', handleUserActivity);

      return () => {
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
        window.removeEventListener('scroll', handleUserActivity);
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }, [loading, itemList]);

    const handleStyleClicked = (style) => {
      setSelectedStyle(style);
      setShowClothingTypePopup(true);
    };

    const handleQueryClicked = (query) => {
      console.log(query);
    }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen w-full">
      {!user ? (
        <div className="w-1/2">
          <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <button onClick={handleLogin}>Login</button>
        </div>
      ) : (
        <div className='flex flex-col items-center justify-center h-screen min-h-screen w-full' style={{ margin: '0 auto', maxWidth: '1200px' }}>
            <div style={{display:'flex', flexDirection:'column', alignItems:'center', position: 'relative', height: '950px', marginLeft: '-100px', marginRight: '-100px'}}>
                <video 
                    autoPlay 
                    loop 
                    muted 
                    playsInline 
                    webkit-playsinline="true"
                    preload="true"
                    poster={require("../Assets/main-home-video.png")}
                    style={{position: 'absolute', width: '100%', height: '100%', objectFit: 'cover', zIndex: -1}}
                    ref={(videoElement) => {
                        if (videoElement) {
                            videoElement.play().catch(error => {
                                console.error("Error attempting to play video:", error);
                            });
                        }
                    }}
                >
                    <source src={require("../Assets/main-home-video.mp4")} type="video/mp4" />
                </video>
                {/* <div className="main-home-video" style={{position: 'absolute', width: '100%', height: '100%', objectFit: 'fill', zIndex: -1, backgroundColor: 'rgba(0,0,0,0.5)'}}></div> */}
                <div style={{position: 'relative', zIndex: 1, width: '100%', height: '100%', textAlign:'center', backgroundColor: 'rgba(0,0,0,0.5)'}}>
                    <div className="flex justify-center items-center w-full h-full" style={{ width: '750px', height: 'auto', margin: 'auto' }}>
                      <div onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
                        <img src={Logo} alt="Pilcha Logo" style={{ width: '100%', height: 'auto', margin: 'auto' }}/>
                      </div>
                    </div>
                    <div className="carousel-wrapper" style={{justifyContent: 'center', alignItems: 'center'}}>
                        {/* <ScrollingImages images={imageGridArray} /> */}
                        <div className="overlay" style={{display:'flex', flexDirection:'column'}}>
                            <h2 style={{color: 'white', paddingTop: '0%', textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)', fontSize: '60px'}} className='title-landing'>EXPLORA TODO EL PILCHA ACA</h2>
                          <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', width: '70%'}}>
                              <h3 style={{
                                  color: 'white', 
                                  paddingTop: '0%', 
                                  textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)', 
                                  fontSize: '30px', 
                                  marginBottom: '10px',
                                  alignSelf: 'flex-start'
                              }} className='subtitle'>
                                  Apreta aca para buscar lo que quieras:
                              </h3>
                              <form onSubmit={handleSearch} className='input-wrapper-landing' style={{ width: '100%', height: '85px', position: 'relative', marginRight: '0px', marginTop: '2%'}}>
                                  <input
                                      type="text"
                                      placeholder={placeholder}
                                      value={textSearch}
                                      className='search-bar'
                                      required
                                      onChange={e => setTextSearch(e.target.value)}
                                      onKeyDown={(e) => { if (e.key === 'Enter') { handleSearch(e); } }}
                                      style={{ 
                                          fontFamily: 'Raleway', 
                                          fontWeight:'700',
                                          height: '100%', 
                                          width: '100%',
                                          padding: '10px',
                                          borderRadius: '15px', 
                                          paddingLeft: '50px',
                                          paddingRight: '20px',
                                          boxSizing: 'border-box',
                                          animation: 'fadeEffect 1.5s',
                                          cursor: 'pointer',
                                      }}
                                  />
                                  <div className="search-icon-landing" style={{ position: 'absolute', left: '20px', top: '50%', transform: 'translateY(-50%)'}}>
                                      <SearchIcon style={{ width: '20px', height: '20px' }} />
                                  </div>
                              </form>
                          </div>
                            <h2 style={{color: 'white', paddingTop: '0%', textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)', marginTop:'18% !important'}} className='title-landing-pilcha'>TU ESTILO PERSONAL EN UNA APP</h2>
                            <h2 className='subtitle' style={{textAlign:'center', color:'white', paddingBottom: '8%', textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)'}}>Compra a través de inteligencia artificial <br></br>las mejores marcas de Argentina </h2>
                        </div>
                    </div>
                </div>
            </div>
            {/* <h1 style={{ fontFamily: 'Raleway, sans-serif', fontSize: '45px', fontWeight: '900' }}>TU ESTILO PERSONAL EN UNA APP</h1> */}
            {/* <h3 style={{ fontFamily: 'Raleway, sans-serif', fontSize: '25px', fontWeight: '400', marginBottom: '20px', marginTop: '140px' }}>Busca como quieras</h3> */}
            
            {/* <div className="w-1/2">
                <button onClick={handleLogout}>Logout</button>
            </div> */}
            {itemList && itemList.length > 0 ? (
            <div style={{marginLeft: '20px', marginRight: '20px'}}>
            <h2 style={{ fontSize: '20px', fontWeight: '900', marginBottom: '20px', marginTop: '50px' }}>Resultados</h2>
              <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '20px'
              }}>
                {itemList.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => handleItemClick(item)}
                    className='store-item-wrapper'
                    style={{
                      cursor: 'pointer',
                      padding: '10px',
                      boxSizing: 'border-box',
                    //   border: '1px solid #E0E0E0',
                      borderRadius: '15px',
                      margin: '10px',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.05), 0 0 20px rgba(0, 0, 0, 0.05)'
                    }}
                  >
                    <div className="store-item">
                        <img 
                            src={item.item_images && item.item_images.length > 0 ? item.item_images[0] : item.item_image} 
                            alt={item.item_name} 
                            className="store-item-img" 
                        />
                        {/* <div className="new-tag">NEW</div> */}
                    </div>
                    <div className="store-item-text" style={{ textAlign: 'left' }}>
                        <div style={{ display: 'flex', justifyContent: 'left'}}>
                            <h2 style={{ fontSize: '10px', color: 'grey', fontWeight: '9000' }}>
                                {item.item_brand ? item.item_brand.toUpperCase() : 'UNKNOWN BRAND'}
                            </h2>
                        </div>
                        <h2 style={{ fontSize: '14px', textTransform: 'capitalize' }}>{item.item_name}</h2>
                        {/* <div className="price-container" style={{ display: 'flex', flexDirection: 'column' }}>
                            {item.product_promotional_price ? (
                                <>
                                    <div style={{textAlign: 'left', width: '100%', marginBottom: '5px'}} className="price-line original-price">{numberFormat(item.product_price)}</div>
                                    <div style={{textAlign: 'left', width: '100%', marginBottom: '5px'}} className="price-line promotional-price">{numberFormat(item.product_promotional_price)}</div>
                                    <div style={{textAlign: 'left', width: '100%', marginBottom: '5px'}} className="price-line discount-percentage">
                                        {`(${Math.round(((item.product_price - item.product_promotional_price) / item.product_price) * 100)}% OFF)`}
                                    </div>
                                </>
                            ) : (
                                <h3 style={{textAlign: 'left', width: '100%', marginBottom: '5px'}}>{numberFormat(item.product_price)}</h3>
                            )}
                        </div> */}
                    </div>
                  </div>
                ))}
              </div>
              
            </div>
            ) : (
            <div style={{marginLeft: '0px', marginRight: '0px'}}>
                {suggestedStyles && suggestedStyles.length > 0 && (
                <div style={{marginLeft: '20px', marginRight: '20px'}}>
                    <h2 className='section-title' style={{color:'black', textAlign:'left', marginTop: '4%', fontFamily: 'Raleway', fontWeight: '900'}}>Compra por Estilos</h2>
                    <div className="styles-images" style={{ 
                        display: 'flex', 
                        overflowX: 'scroll',
                        overflowY: 'hidden', 
                        marginTop: '4%', 
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                        }}>
                        {suggestedStyles.map((style, index) => {
                            const styleLowerCase = style.toLowerCase();
                            const profileImage = require(`../Assets/${styleLowerCase}-profile-image.png`);
                            return (
                                <div key={index} className="style-image" style={{ 
                                    position: 'relative',
                                    borderRadius: '50%',
                                    height: '180px', 
                                    width: '180px', 
                                    flex: '0 0 auto',
                                    marginRight: '10px',
                                    marginLeft: index === 0 ? '0' : '4%',
                                    cursor: 'pointer',
                                    }} onClick={() => handleStyleClicked(style)}>
                                    <img src={profileImage} alt={style} style={{
                                        borderRadius: '50%',
                                        height: '100%', 
                                        width: '100%', 
                                        objectFit: 'cover',
                                    }} />
                                    <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        height: '100%',
                                        width: '100%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                        display: 'flex', 
                                        justifyContent: 'center', 
                                        alignItems: 'center',
                                        borderRadius: '50%',
                                    }}>
                                        <h3 style={{
                                            color:'white', 
                                            textAlign:'center', 
                                            fontFamily: 'Raleway', 
                                            fontWeight: '900',
                                            cursor: 'pointer',
                                            textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)'
                                            }} onClick={() => handleStyleClicked(style)}>
                                            {style.toUpperCase()}
                                        </h3>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}

            <div style={{marginLeft: '20px', marginRight: '20px'}}>
                <h2 className='section-title' style={{color:'black', textAlign:'left', marginTop: '4%', fontFamily: 'Raleway', fontWeight: '900', marginBottom: '20px'}}>Popping en Argentina</h2>
                <div className='side-by-side-img-rectangles' style={{display: 'flex', justifyContent: 'space-between', gap: '40px'}}>
                    <div className='inner-side-by-side' style={{position: 'relative', height: '258px', width: 'calc(50% - 30px)', borderRadius: '20px', cursor: 'pointer'}} onClick={() => setTextSearch("Quiero ropa Streetwear")}>
                        <img src={require('../Assets/streetwear-argentina.webp')} alt='Rectangle 1' style={{height: '100%', width: '100%', borderRadius: '20px', objectFit: 'cover'}} />
                        <div style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <h1 style={{color: 'white', fontWeight: 'bold', textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)'}}>Streetwear en Argentina</h1>
                        </div>
                    </div>
                    <div className='inner-side-by-side' style={{position: 'relative', height: '258px', width: 'calc(50% - 30px)', borderRadius: '20px', cursor: 'pointer'}} onClick={() => setTextSearch("Ropa baggy fit")}>
                        <img src={require('../Assets/baggy-pants.webp')} alt='Rectangle 2' style={{height: '100%', width: '100%', borderRadius: '20px', objectFit: 'cover'}} />
                        <div style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <h1 style={{color: 'white', fontWeight: 'bold', textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)'}}>Los Mejores Baggy Fit de Argentina</h1>
                        </div>
                    </div>
                </div>
                <div className='side-by-side-img-rectangles' style={{display: 'flex', justifyContent: 'space-between', gap: '40px', marginTop: '40px'}}>
                    <div className='inner-side-by-side' style={{position: 'relative', height: '258px', width: 'calc(50% - 30px)', borderRadius: '20px', cursor: 'pointer'}} onClick={() => setTextSearch('Quiero vestirme como Duki')}>
                        <img src={require('../Assets/vestite-como-famoso.webp')} alt='Rectangle 1' style={{height: '100%', width: '100%', borderRadius: '20px', objectFit: 'cover'}} />
                        <div style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <h1 style={{color: 'white', fontWeight: 'bold', textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)'}}>Vestite como una Celebridad</h1>
                        </div>
                    </div>
                    <div className='inner-side-by-side' style={{position: 'relative', height: '258px', width: 'calc(50% - 30px)', borderRadius: '20px', cursor: 'pointer'}} onClick={() => setTextSearch("Quiero lo mas buscado")}>
                        <img src={require('../Assets/shop-preview.webp')} alt='Rectangle 2' style={{height: '100%', width: '100%', borderRadius: '20px', objectFit: 'cover'}} />
                        <div style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <h1 style={{color: 'white', fontWeight: 'bold', textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)'}}>Encontra las Mejores Marcas</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
              marginLeft: '20px',
              marginRight: '20px',
              marginTop: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }} onClick={() => setShowPopupQR(true)}>
              {/* <h1 className='title' style={{color:'black', textAlign:'center', marginTop: '4%', fontFamily: 'Raleway', fontWeight: '900', marginBottom: '20px'}}>EXPLORA TODO EL PILCHA ACA</h1> */}
              <img src={require('../Assets/WEARLYXPILCHALOGO.png')} alt='Wearly Logo' style={{width: '70%', height: 'auto', marginBottom: '20px'}} />
            </div>
            </div>
            )}
        </div>
      )}
      {loading && 
        <div className="loading-overlay">
            <div className="loading-images-container">
                    {currentLoadingImages.map((url, index) => (
                        <div key={index} className="loading-image-wrapper">
                            <img src={url} alt={`Loading ${index}`} className="loading-image" />
                        </div>
                    ))}
                </div>
            <div className="loading-phrase">{loadingPhrase}</div>
        </div>
        }
      {showPopup && selectedItem && (
        
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
              {/* Improved banner */}
                <img src={require(`../Assets/MAP-${selectedItem.item_brand.toUpperCase()}.webp`)} alt='Wearly Logo' style={{width: '1000px', height: 'auto', marginBottom: '20px'}} />
                <h2 style={{ fontSize: '24px', fontWeight: '900', marginBottom: '20px' }}>Únete a Wearly!</h2>
                <p style={{ fontSize: '18px', marginBottom: '30px' }}>Escanea el código QR para descargar nuestra app y <br></br> obtener acceso a ofertas exclusivas y recomendaciones personalizadas.</p>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '60px',marginTop: '60px' }}>
                  <QRCode value={`https://wearlyapp.com?pilcha=${queryId}`} size={200} />
                </div>
          </div>
        </div>
      )}
      {showPopupQR && (
        
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
              {/* Improved banner */}
              {/* close button x */}
              <button onClick={() => setShowPopupQR(false)} style={{position: 'absolute', top: '10px', right: '10px', fontSize: '20px', background: 'none', border: 'none', cursor: 'pointer'}}>X</button>
                {/* <img src={require(`../Assets/MAP-${selectedItem.item_brand.toUpperCase()}.webp`)} alt='Wearly Logo' style={{width: '1000px', height: 'auto', marginBottom: '20px'}} /> */}
                <h2 style={{ fontSize: '24px', fontWeight: '900', marginBottom: '20px' }}>Únete a Wearly!</h2>
                <p style={{ fontSize: '18px', marginBottom: '30px' }}>Escanea el código QR para descargar nuestra app y <br></br> obtener acceso a ofertas exclusivas y recomendaciones personalizadas.</p>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '60px',marginTop: '60px' }}>
                  <QRCode value={`https://wearlyapp.com?pilcha=${queryId}`} size={200} />
                </div>
          </div>
        </div>
      )}
      {showClothingTypePopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2 style={{ fontSize: '24px', fontWeight: '900', marginBottom: '20px' }}>Selecciona el tipo de ropa</h2>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
              <button 
                onClick={() => handleClothingTypeSelection('female')}
                style={{
                  backgroundColor: '#f8f8f8',
                  border: 'none',
                  borderRadius: '10px',
                  padding: '15px 30px',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
                onMouseEnter={(e) => e.target.style.backgroundColor = '#e0e0e0'}
                onMouseLeave={(e) => e.target.style.backgroundColor = '#f8f8f8'}
              >
                Mujer
              </button>
              <button 
                onClick={() => handleClothingTypeSelection('male')}
                style={{
                  backgroundColor: '#f8f8f8',
                  border: 'none',
                  borderRadius: '10px',
                  padding: '15px 30px',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
                onMouseEnter={(e) => e.target.style.backgroundColor = '#e0e0e0'}
                onMouseLeave={(e) => e.target.style.backgroundColor = '#f8f8f8'}
              >
                Hombre
              </button>
            </div>
          </div>
        </div>
      )}
      <style jsx>{`
        .popup-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999;
        }

        .popup-content {
          background-color: white;
          padding: 20px;
          border-radius: 10px;
          text-align: center;
          position: relative;
        }

        .close-button {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 20px;
          background: none;
          border: none;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};

export default PilchaPage;