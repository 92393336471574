import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs, getDoc, query, where, updateDoc, doc, arrayUnion, addDoc, deleteDoc, setDoc, arrayRemove } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import '../CSS/CartPage.css';
import { ReactComponent as ArrowBackIcon } from '../../../Assets/arrow-left-solid.svg';
import { ReactComponent as TrashCanIcon } from '../../../Assets/trash-can-regular.svg';
import { ReactComponent as ProfileIcon } from '../../../Assets/user-solid.svg';
import { ReactComponent as CartIcon } from '../../../Assets/bag-shopping-solid.svg';
import { ReactComponent as ShieldIcon } from '../../../Assets/shield-halved-solid.svg';
import { ReactComponent as HeartHallowIcon } from '../../../Assets/heart-regular.svg';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { ReactComponent as SettingsIcon } from '../../../Assets/search-settings-icon.svg';
import Logo from '../../../Assets/wai-logo.png';
import { ReactComponent as MoreIcon } from '../../../Assets/more-menu-icon.svg';
import { ReactComponent as SearchIcon } from '../../../Assets/magnifying-glass-solid.svg';
import { db, auth, functions } from '../../../firebase';
import { storage } from '../../../firebase';
import { ReactComponent as TruckFastSolidIcon } from '../../../Assets/truck-fast-solid.svg';
import LogoBlack from '../../../Assets/wai-logo-black.png';
import { ReactComponent as InstagramIcon } from '../../../Assets/instagram.svg';
import { ReactComponent as TwitterIcon } from '../../../Assets/x-twitter.svg';
import { ReactComponent as LinkedInIcon } from '../../../Assets/linkedin-in.svg';
import { ReactComponent as TikTokIcon } from '../../../Assets/tiktok.svg';

const UserCartPage = () => {
  const [itemList, setItemList] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUserTalle, setSelectedUserTalle] = useState('');
  const [error, setError] = useState('');
  const [itemStockLeft, setItemStockLeft] = useState(1);
  const [emptyCart, setEmptyCart] = useState(false);
  const [total, setTotal] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const cartTotalRef = useRef(null);
  const [userData, setUserData] = useState(null);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const getCheckoutPaymentUrl = httpsCallable(functions, 'macroClickGetCheckoutPaymentUrl');
  const getShippingCost = httpsCallable(functions, 'tiendaNubeGetShipping');
  const [checkoutUrlLoading, setCheckoutUrlLoading] = useState(false);
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [textSearch, setTextSearch] = useState('');
  const [showSearchSettingsModal, setShowSearchSettingsModal] = useState(false);
  const [placeholder, setPlaceholder] = useState('');
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [typingIndex, setTypingIndex] = useState(0);
  const cartItemsContainerRef = useRef(null);
  const occasions_examples_list = [
    "Tengo una joda en la playa.",
    "Vestime como Taylor Swift.",
    "Reunion en la oficina.",
    // "https://pinterest.com/pin/...",
    "Yendo a un casamiento.",
    "Quiero verme como la Rosalia",
    "Un día de museo.",
    "Dame el estilo de CRO.",
    // 'https://instagram.com/p/...',
    // "https://pinterest.com/pin/...",
    "Vamos a un boliche.",
    "Noche de cine.",
    "Picnic de verano en la plaza.",
    // "https://pinterest.com/pin/...",
    "Volley en la playa.",
    "Mañana de GYM.",
    // 'https://instagram.com/p/...',
    "Festejo de cumpleaños.",
    // "https://pinterest.com/pin/...",
    "Brunch de domingo con la flia.",
    "Me voy a hacer ski a la montaña.",
    "Me voy al fashionweek.",
  ];
  // New states for suggestions
  const [completaElLook, setCompletaElLook] = useState([]);
  const [recomendaciones, setRecomendaciones] = useState([]);

  useEffect(() => {
    const getCartItems = async () => {
      setIsLoading(true);
      if (auth.currentUser && auth.currentUser.uid) {
        const docRef = doc(db, 'users', auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists() && docSnap.data().user_cart) {
          console.log(docSnap.data().user_cart);
          const userCartMap = docSnap.data().user_cart;
          const items = [];
          for (const [key, cartItem] of Object.entries(userCartMap)) {
            const wearlyItemId = key.split('_')[1];
            console.log("Wearly Item ID: ", wearlyItemId);
            if (wearlyItemId) {
              const itemRef = doc(db, 'products', wearlyItemId);
              const itemSnap = await getDoc(itemRef);
              if (itemSnap.exists()) {
                let itemData = itemSnap.data();
                console.log("Item Data: ", itemData);
                itemData.wearly_item_id = wearlyItemId;
                itemData.user_selected_size = cartItem.selected_size;
                itemData.user_date_added = cartItem.date_added;
                itemData.firebase_doc_id = key;
                items.push(itemData);
              } else {
                console.log("Item Snap does not exist");
              }
            }
          }
          setItemList(items);
          setEmptyCart(items.length === 0);
        } else {
          setEmptyCart(true);
        }
      } else {
        console.error("No current user found.");
        setEmptyCart(true);
      }
      setIsLoading(false);
    };
    getCartItems();
  }, []);

  useEffect(() => {
    let typewriterIntervalId;
    if (placeholderIndex !== null) {
        typewriterIntervalId = setInterval(() => {
            setTypingIndex((prevTypingIndex) => {
                const phrase = occasions_examples_list[placeholderIndex];
                if (prevTypingIndex < phrase.length) {
                    setPlaceholder(phrase.slice(0, prevTypingIndex + 1));
                    return prevTypingIndex + 1;
                } else {
                    clearInterval(typewriterIntervalId);
                    return prevTypingIndex;
                }
            });
        }, 150);
    }

    return () => clearInterval(typewriterIntervalId);
  }, [placeholderIndex]);

  useEffect(() => {
    const getUserData = async () => {
      const userRef = doc(db, 'users', auth.currentUser.uid);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        setUserData(userSnap.data());
      }
    };
    getUserData();
  }, []);

  useEffect(() => {
    if (userData && !userData.user_address) {
      setShowAddressModal(true);
    }
  }, [userData]);

  useEffect(() => {
    calculateTotal();
    calculateSubtotal();
  }, [itemList]);

  // Fetch suggestions when the component mounts
  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const completeLookResponse = await get_complete_outfit_recomendations();
        const recommendationsResponse = await get_item_recomendations();

        console.log("Completa el Look Response: ", completeLookResponse.data);
        console.log("Recomendaciones Response: ", recommendationsResponse.data);

        const filterValidItems = (item) => {
          return item && Object.keys(item).length > 0;
        };

        // Handle completeLookResponse
        let filteredCompletaElLook = [];
        if (completeLookResponse.data && typeof completeLookResponse.data === 'object') {
          const { tops, bottoms, shoes } = completeLookResponse.data;
          filteredCompletaElLook = [tops, bottoms, shoes].filter(filterValidItems);
        } else {
          console.error("Completa el Look Response is not an object:", completeLookResponse.data);
        }

        // Handle recommendationsResponse
        const filterValidImages = (items) => {
          if (!Array.isArray(items)) {
            console.error("Expected an array, but received:", items);
            return [];
          }
          return items.filter(item => item && item.item_image !== null && item.item_image !== "");
        };
        const filteredRecomendaciones = filterValidImages(recommendationsResponse.data);

        console.log("Filtered Completa el Look:", filteredCompletaElLook);
        setCompletaElLook(filteredCompletaElLook);
        setRecomendaciones(filteredRecomendaciones);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    };

    fetchSuggestions();
  }, []);

  const handleShowMoreMenu = () => {
    setShowMoreMenu(!showMoreMenu);
  }

  const handleSearch = async (e) => {
    e.preventDefault();
    let searchText = '';
    // Check if the event is triggered from the form submission
    if (e.target.tagName === 'FORM') {
        searchText = e.target.elements[0].value; // Assuming the first element is the input
    } else if (e.target.tagName === 'INPUT') {
        // If the event is triggered by onKeyDown on the input
        searchText = e.target.value;
    }

    if (searchText) {
        if (searchText.startsWith('https://')) {
            // no url search yet
            console.log("no url search yet");

        } else {
            // Handle text search
            // send to home with search text
            navigate('/', { state: { searchText } });
        }
    }
  }

  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth > 750) {
            setShowMoreMenu(true);
        } else {
            setShowMoreMenu(false);
        }
    };

    window.addEventListener('resize', handleResize);
    // Set initial state based on window size
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const get_complete_outfit_recomendations = httpsCallable(functions, 'get_complete_outfit_recomendations');
  const get_item_recomendations = httpsCallable(functions, 'get_item_recomendations');

  const AddressModal = ({ onClose, onSave }) => {
    const [id_number, setId] = useState("");
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [phone_number, setPhoneNumber] = useState("");
    const [user_address, setUserAddress] = useState("");
    const [street_number, setStreetNumber] = useState("");
    const [apt_number, setApartment] = useState("");
    const [floor, setFloor] = useState("");
    const [neighborhood, setNeighborhood] = useState("");
    const [city, setCity] = useState("");
    const [postal_code, setZipCode] = useState("");
    const [notes, setNotes] = useState("");
    const [province, setProvince] = useState('Buenos Aires');
    const provinces = ["Buenos Aires", "Ciudad Autónoma de Buenos Aires", "Catamarca", "Chaco", "Chubut", "Córdoba", "Corrientes", "Entre Ríos", "Formosa", "Jujuy", "La Pampa", "La Rioja", "Mendoza", "Misiones", "Neuquén", "Río Negro", "Salta", "San Juan", "San Luis", "Santa Cruz", "Santa Fe", "Santiago del Estero", "Tierra del Fuego", "Tucumán"];

    return (
      <div className="modal">
        <div className="modal-content">
          <button onClick={onClose} style={{ position: 'absolute', right: '10px', top: '10px' }}>x</button>
          <h2>Agregar dirección 🏡</h2>
          <input type="number" value={id_number} onChange={(e) => setId(e.target.value)} required placeholder="Documento DNI" />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <input type="text" value={first_name} onChange={(e) => setFirstName(e.target.value)} required placeholder="Nombre" style={{ marginRight: '10px' }} />
            <input type="text" value={last_name} onChange={(e) => setLastName(e.target.value)} required placeholder="Apellido" />
          </div>
          <input type="tel" value={phone_number} onChange={(e) => setPhoneNumber(e.target.value)} required placeholder="Teléfono (ultimos 6 digitos)" />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <input type="text" value={user_address} onChange={(e) => setUserAddress(e.target.value)} placeholder="Calle" required style={{ flex: 1, marginRight: '10px' }} />
            <input type="number" value={street_number} onChange={(e) => setStreetNumber(e.target.value)} placeholder="Nº" style={{ width: '20%' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <input type="number" value={apt_number} onChange={(e) => setApartment(e.target.value)} placeholder="Nº Apartamento" style={{ marginRight: '10px' }} />
            <input type="number" value={floor} onChange={(e) => setFloor(e.target.value)} placeholder="Piso" />
          </div>
          <input type="text" value={neighborhood} onChange={(e) => setNeighborhood(e.target.value)} required placeholder="Barrio" />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <input type="text" value={city} onChange={(e) => setCity(e.target.value)} required placeholder="Ciudad" style={{ flex: 1, marginRight: '10px' }} />
            <input type="number" value={postal_code} onChange={(e) => setZipCode(e.target.value)} required placeholder="Código Postal" style={{ width: '40%' }} />
          </div>
          <textarea value={notes} onChange={(e) => setNotes(e.target.value)} placeholder="Notas"></textarea>
          <select value={province} onChange={(e) => setProvince(e.target.value)} style={{
              padding: '10px',
              border: 'none',
              borderRadius: '4px',
              fontSize: '12px',
              fontWeight: '700',
              backgroundColor: '#f0f0f0',
              appearance: 'none',
              backgroundImage: 'url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E")',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right 10px top 50%',
              backgroundSize: '12px auto'
            }}
          >
            {provinces.map((prov, index) => (
              <option key={index} value={prov}>{prov}</option>
            ))}
          </select>
          <button onClick={() => onSave({ id_number, first_name, last_name, phone_number, user_address, street_number, apt_number, floor, neighborhood, city, postal_code, province })}>Guardar</button>
        </div>
      </div>
    );
  };

  const goBack = () => {
    if (showOrderSummary) {
      setShowOrderSummary(false);
      return;
    } else {
      navigate('/');
    }
  }

  const numberFormat = (value) =>
    new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS'
    }).format(value);

  const removeFromCart = async (item) => {
    console.log('Removing item from cart:', item);
    if (!item.wearly_item_id || item.user_selected_size === undefined) {
      console.error('One or more required fields are undefined.', item);
      return;
    }
    const cartRef = doc(db, 'users', auth.currentUser.uid);
    try {
      const cartSnap = await getDoc(cartRef);
      if (cartSnap.exists()) {
        const userCartMap = cartSnap.data().user_cart;
        if (userCartMap[item.firebase_doc_id]) {
          delete userCartMap[item.firebase_doc_id];
          await updateDoc(cartRef, {
            user_cart: userCartMap
          });
        }
      }
      const newItems = itemList.filter((i) => i.wearly_item_id !== item.wearly_item_id);
      setItemList(newItems);
      calculateTotal();
      calculateSubtotal();
    } catch (error) {
      console.error('Error removing item from cart:', error);
    }
  }

  const calculateTotal = () => {
    let total = 0;
    if (itemList && Array.isArray(itemList)) {
      itemList.forEach((item) => {
        const price = item.product_promotional_price && item.product_promotional_price !== 0 ? item.product_promotional_price : item.product_price;
        total += price;
      });
    }
    setTotal(total);
  }

  const calculateSubtotal = async () => {
    try {
      const result = await getShippingCost({});
      console.log('Shipping cost:', result);
      if (result.data) {
        setSubtotal(result.data.subTotalCart);
        setTotal(result.data.totalCart);
        setShippingCost(result.data.shippingCost);
      } else {
        console.error('No shipping cost returned from get_tiendanube_shipping');
      }
    } catch (error) {
      console.error('Error calling get_tiendanube_shipping:', error);
    }
  }

  const checkoutButtonClick = async () => {
    if (showOrderSummary) {
      setCheckoutUrlLoading(true);
      await getCheckoutPaymentUrl({ /* any required data */ })
        .then(result => {
          console.log('Checkout URL:', result);
          // if (result.data && result.data.checkoutUrl) {
          if (result.data) {
            setCheckoutUrlLoading(false);
          
            const tempContainer = document.createElement('div');
            tempContainer.innerHTML = result.data.html;
            
            // Find the form in the container
            const form = tempContainer.querySelector('form');
            
            if (form) {
              // Remove the button from the form
              const button = form.querySelector('button');
              if (button) {
                button.remove();
              }
              
              // Append the form to the body
              document.body.appendChild(form);
              
              // Submit the form automatically
              form.submit();
            } else {
              throw new Error('Form not found in the response');
            }

          } else {
            console.error('No URL returned from get_checkout_payment_url');
            setCheckoutUrlLoading(false);
          }
        })
        .catch(error => {
          console.error('Error calling get_checkout_payment_url:', error);
          setCheckoutUrlLoading(false);
        });
    } else {
      setShowOrderSummary(true);
      setCheckoutUrlLoading(false);
    }
  }

  const handleItemClick = (item) => {
    navigate(`/product/${item.wearly_item_id}`);
  }

  const navigateToWishlist = () => {
    navigate('/wishlist');
  };

  const handleScroll = () => {
    const cartItemsContainer = cartItemsContainerRef.current;
    const cartTotal = cartTotalRef.current;
    if (cartItemsContainer && cartTotal) {
      const containerBottom = cartItemsContainer.getBoundingClientRect().bottom;
      const viewportHeight = window.innerHeight;
      if (containerBottom <= viewportHeight) {
        cartTotal.style.position = 'fixed';
        cartTotal.style.bottom = '0';
        cartTotal.style.top = 'auto';
      } else {
        cartTotal.style.position = 'sticky';
        cartTotal.style.top = '0';
        cartTotal.style.bottom = 'auto';
      }
    }
  };
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      {showAddressModal && (
        <AddressModal
          style={{ zIndex: 9999 }}
          onClose={() => setShowAddressModal(false)}
          onSave={async (addressData) => {
            try {
              const userRef = doc(db, 'users', auth.currentUser.uid);
              await setDoc(userRef, {
                user_address: addressData
              }, { merge: true });
              setUserData({ ...userData, user_address: addressData });
              setShowAddressModal(false);
            } catch (error) {
              console.error("Error saving address:", error);
            }
          }}
        />
      )}
      <div className="content">
        {showMoreMenu &&
          <footer className="footer" style={{margin: '0px', padding: '0px', display: 'flex', justifyContent: 'space-between'}}>
              <ul className="footer-links" style={{textAlign: 'left', alignItems: 'left', marginLeft: '0px'}}>
                  <li style={{marginLeft: '0px', cursor: 'pointer'}}><a href="/">Home</a></li>
                  {/* <li style={{ marginLeft: '0px', cursor: 'pointer' }} onMouseEnter={() => setActiveItem('brands')} onMouseLeave={() => setActiveItem(null)}>
                      <a>Marcas Destacadas</a>
                      {activeItem === 'brands' && (
                          <div>
                              <ul className="hover-options">
                                  <li className="hover-button"><a>NXBO</a></li>
                                  <li className="hover-button"><a>Tranca</a></li>
                                  <li className="hover-button"><a>El del Barrio Chino</a></li>
                              </ul>
                          </div>
                      )}
                  </li> */}
                  {/* <li style={{ marginLeft: '0px', cursor: 'pointer' }} onMouseEnter={() => setActiveItem('categories')} onMouseLeave={() => setActiveItem(null)}>
                      <a>Categorias</a>
                      {activeItem === 'categories' && (
                          <div>
                              <ul className="hover-options">
                                  <li className="hover-button"><a>Mas Vendidos</a></li>
                                  <li className="hover-button"><a>Nuevos</a></li>
                              </ul>
                              <ul className="hover-options">
                                  <li className="hover-button"><a>Remeras</a></li>
                                  <li className="hover-button"><a>Camperas</a></li>
                                  <li className="hover-button"><a>Pantalones</a></li>
                              </ul>
                          </div>
                      )}
                  </li>
                  <li style={{ marginLeft: '0px', cursor: 'pointer' }} onMouseEnter={() => setActiveItem('collections')} onMouseLeave={() => setActiveItem(null)}>
                      <a>Colecciones</a>
                      {activeItem === 'collections' && (
                          <ul className="hover-options">
                              <li className="hover-button"><a>Streetwear</a></li>
                              <li className="hover-button"><a>Vintage</a></li>
                              <li className="hover-button"><a>Sneakers</a></li>
                          </ul>
                      )}
                  </li> */}
              </ul>
              {window.innerWidth > 750 &&
                  <ul className="footer-links" style={{textAlign: 'right', alignItems: 'right', marginRight: '0px'}}>
                      <li style={{marginRight: '0px', fontStyle: 'italic', textDecoration: 'underline'}}><a href="/wishlist">Lista de Deseos</a></li>
                      <li style={{marginRight: '0px', fontStyle: 'italic', textDecoration: 'underline'}}><a href="/profile-settings">Perfil</a></li>
                  </ul>
              }
          </footer>
        }

        <div className='mobile-navbar' style={{margin: '0 auto'}}>
            <div className='mobile-navbar-cart'>
                <button className='navbar-more-button' onClick={() => handleShowMoreMenu()}>
                    <MoreIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
                </button>
            </div>
            <div className='mobile-navbar-logo'>
                <div className='logo' style={{width:'140px', height:'60px'}}>
                    <img src={Logo} onClick={() => navigate('/')} alt="Logo" className='logo-img' style={{ width: '100%', height: '100%', objectFit: 'contain', padding:'10%' }} />
                </div>
            </div>
            <div className='navbar-profile'>
                <button className='navbar-profile-button' onClick={() => navigate('/profile-settings')}>
                    <ProfileIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
                </button>
            </div>
            <div className='navbar-heart'>
                <button className='navbar-heart-button' onClick={() => navigate('/wishlist')}>
                    <HeartHallowIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
                </button>
            </div>
            <div className='mobile-navbar-cart'>
                <button className='navbar-cart-button' onClick={() => navigate('/cart')}>
                    <CartIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
                </button>
            </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', marginTop: '20px', margin: '0 auto' }}>
            <button className="back-button-client" style={{ marginBottom: '0px' }} onClick={goBack}>
                <ArrowBackIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
            </button>
            <h1 className='cart-title' style={{ marginLeft: '20px', fontWeight: '900' }}>Tu Carrito</h1>
        </div>
        {emptyCart && 
          <div className="empty-cart-container" style={{margin: '0 auto'}}>
            <h2>Tu carrito está vacío</h2>
            <p>¡Agrega productos para comenzar a comprar!</p>
          </div>
        }
        {!emptyCart && 
          <div className="page-container" style={{margin: '0 auto', marginTop: '2%'}}>
            <div className="cart-items-container">
              {itemList && itemList.length > 0 ? itemList.map((item, index) => (
                <div key={item.id} className="cart-item">
                  {index !== 0 && <div className="line"></div>}
                  <div className="side-by-side-container-cart">
                    <div className='event-container' style={{ display: 'inline-block', marginTop: '0px' }}>
                      <div className="single-image-container-cart">
                        <img style={{ cursor: 'pointer' }} onClick={() => navigate(`/product/${item.wearly_item_id}`)} src={item.item_images[0]} alt="Item" />
                      </div>
                    </div>
                    <div className='new-recomended-items-container' style={{ marginTop: '0px' }}>
                      <div className="item-text-container">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <h2 style={{ cursor: 'pointer' }} onClick={() => navigate(`/product/${item.wearly_item_id}`)}>{item.name}</h2>
                          {!showOrderSummary ? <TrashCanIcon style={{ cursor: 'pointer', width: '15px', height: '15px', fill: 'grey' }} onClick={() => removeFromCart(item)} /> : <div></div>}
                        </div>
                        <div className="price-container">
                          {item.product_promotional_price ? (
                            <>
                              <span className="original-price">{numberFormat(item.product_price)}</span>
                              <span className="promotional-price">{numberFormat(item.product_promotional_price)}</span>
                              <span className="discount-percentage">
                                {`(${Math.round(((item.product_price - item.product_promotional_price) / item.product_price) * 100)}% OFF)`}
                              </span>
                            </>
                          ) : (
                            <h3>{numberFormat(item.product_price)}</h3>
                          )}
                        </div>
                        <h3>Talle: {item.user_selected_size}</h3>
                        <p style={{ fontWeight: '700', fontStyle: 'italic', textDecoration: 'underline' }}>by {item.brand_name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )) : <p>No items in the cart.</p>}
            </div>
            <div className="cart-total-container" ref={cartTotalRef}>
              <div className="line" ></div>
              {showOrderSummary &&
                <div>
                  {userData && userData.user_address &&
                    <div style={{ fontSize: "13px", fontWeight: "400" }}>
                      <h2>Resumen:</h2>
                      <p>{userData.user_address.first_name} {userData.user_address.last_name} ({userData.user_address.id_number})</p>
                      <p>{userData.email}</p>
                      <p>+54 9 11 {userData.user_address.phone_number}</p>
                      <p>{userData.user_address.user_address} {userData.user_address.street_number} {userData.user_address.apt_number}, {userData.user_address.neighborhood}, {userData.user_address.city}, {userData.user_address.province}, {userData.user_address.postal_code}</p>
                    </div>
                  }
                </div>
              }
              {!showOrderSummary &&
                <div>
                  <p>Productos: {numberFormat(subtotal)}</p>
                  {userData && userData.user_address == null ? 
                    <p style={{ marginTop: '0px', marginBottom: '0px' }}>Envío: <button style={{ color: 'blue', background: 'none', border: 'none', padding: '0', textDecoration: 'underline', cursor: 'pointer', fontWeight: '700', fontFamily: 'Raleway' }} onClick={() => setShowAddressModal(true)}>Agregar dirección</button></p> 
                    : 
                    <p style={{ marginTop: '0px', marginBottom: '5px' }}>Envío: {shippingCost === 0 ? <div className="spinner" style={{ width: '10px', height: '10px' }}></div> : numberFormat(shippingCost)}</p>
                  }
                  {userData && userData.user_address &&
                    <div onClick={() => setShowAddressModal(true)} className="address-container" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'left', alignItems: 'left', fontSize: '10px', marginTop: '0px', marginBottom: '0px' }}>
                      <p style={{ marginTop: '0px', marginBottom: '0px', display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => setShowAddressModal(true)}>
                          ✏️
                        </span>
                        Enviar a: {userData.user_address.first_name} {userData.user_address.last_name}
                      </p>
                      <p style={{ marginTop: '0px', marginBottom: '0px' }}> - {userData.user_address.user_address} {userData.user_address.street_number} {userData.user_address.apt_number} {userData.user_address.floor} {userData.user_address.neighborhood} {userData.user_address.city} {userData.user_address.postal_code} {userData.user_address.province}</p>
                    </div>
                  }
                </div>
              }
              <h2>Total: {shippingCost === 0 ? <div className="spinner" style={{ width: '10px', height: '10px' }}></div> : numberFormat(subtotal + shippingCost)}</h2>
              {checkoutUrlLoading ? <div className="spinner" style={{ width: '10px', height: '10px' }}></div> : (
                <>
                  <button className="checkout-button" onClick={() => checkoutButtonClick()} disabled={shippingCost === 0}>
                    {showOrderSummary ? `Confirmar ${numberFormat(subtotal + shippingCost)}` : 'Ir al checkout'}
                  </button>
                  <div className="secure-purchase">
                    <ShieldIcon style={{ width: '14px', height: '14px', fill: '#3C9C47' }} />
                    <span style={{ marginLeft: '5px', fontWeight: '700', color: '#3C9C47', fontSize: '12px' }}>
                      compra segura
                    </span>
                  </div>
                </>
              )}
            </div>
            {completaElLook.length > 0 && (
              <div>
                <h1 className='cart-title' style={{marginTop: '50px'}}>Completa el Look</h1>
                <div style={{ display: 'flex', overflowX: 'scroll', scrollbarWidth: 'none' }}>
                  {completaElLook.map((item, index) => (
                    <div key={index} className='suggestion-item suggestion-item-cart' onClick={() => handleItemClick(item)} style={{ cursor: 'pointer', padding: '10px', width: 'auto' }}>
                      <img className='suggestion-item-img suggestion-item-img-small suggestion-item-cart-img suggestion-item-cart-img-small' src={item.item_images[0]} alt={item.item_name} style={{ objectFit: 'cover', borderRadius: '20px' }} />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* Add Recomendaciones section */}
            {recomendaciones.length > 0 && (
              <div>
                <h1 className='cart-title'>Recomendaciones</h1>
                <div style={{ display: 'flex', overflowX: 'scroll', scrollbarWidth: 'none' }}>
                  {recomendaciones.map((item, index) => (
                    <div className='suggestion-item suggestion-item-cart' key={index} onClick={() => handleItemClick(item)} style={{ cursor: 'pointer', padding: '10px', width: 'auto' }}>
                      <img className='suggestion-item-img suggestion-item-img-small suggestion-item-cart-img suggestion-item-cart-img-small' src={item.item_images[0]} alt={item.item_name} style={{ objectFit: 'cover', borderRadius: '20px' }} />
                      {/* <p>{item.item_name}</p> */}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Add TruckFastSolidIcon */}
            <div className="truck-fast-solid-icon-container">
              <TruckFastSolidIcon style={{ width: '30px', height: '20px', fill: 'black' }} />
            </div>
            <p style={{ fontSize: '12px', fontWeight: '700', color: 'black', textAlign: 'center', justifyContent: 'center', alignItems: 'center', marginLeft: '10%', marginRight: '10%', marginBottom: '40px' }}>En Wearly podes comprar de todas las marcas en 3 simples pasos. Nosotros nos encargamos de pedirle a cada marca que compres que envie el paquete.</p>
          </div>
        }
        <footer className="footer footer-landing" style={{margin: '0px', padding: '0px', textAlign: 'left'}}>
          <div className="footer-content footer-content-landing">
            <div className="footer-logo footer-logo-landing">
              <img src={LogoBlack} alt="Wearly Logo" style={{height: '60px'}} />
            </div>
            <ul className="footer-links footer-links-landing">
              <li><a href="/">Home</a></li>
              <li><a href="/terms">Términos y Condiciones</a></li>
              <li><a href="/privacy">Política de Privacidad</a></li>
              <li><a href="https://wearly-tech.notion.site/Wearly-FAQ-44912421f4684846b815004e31931923?pvs=74">FAQ</a></li>
              <li><a href="mailto:contactwearly@gmail.com">Soporte</a></li>
              <li><a href="https://brands.wearlyapp.com">Soy una marca</a></li>
            </ul>
            <div className="footer-social footer-social-landing">
              <a href="https://www.instagram.com/wearlyapp" target="_blank" rel="noopener noreferrer">
                <InstagramIcon style={{width: '24px', height: '24px', marginRight: '10px'}} />
              </a>
              <a href="https://www.tiktok.com/@wearlyapp" target="_blank" rel="noopener noreferrer">
                <TikTokIcon style={{width: '24px', height: '24px', marginRight: '10px'}} />
              </a>
              <a href="https://x.com/wearlyapp" target="_blank" rel="noopener noreferrer">
                <TwitterIcon style={{width: '24px', height: '24px', marginRight: '10px'}} />
              </a>
              <a href="https://www.linkedin.com/company/wearlyai" target="_blank" rel="noopener noreferrer">
                <LinkedInIcon style={{width: '24px', height: '24px'}} />
              </a>
            </div>
          </div>
        </footer>
        <div className="copyright" style={{margin: '0px', padding: '0px', textAlign: 'left', marginTop: '15px'}}>
            <p>&copy; {new Date().getFullYear()} Wearly. All rights reserved.</p>
        </div>
        {isLoading && 
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        }
      </div>
    </div>
  );
};

export default UserCartPage;
