import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, collection, query, where, getDocs, limit } from 'firebase/firestore';
import '../CSS/PublicBrandProfile.css';
import ArrowBackIcon from '../../../Assets/arrow-left-solid.svg';

const PublicBrandProfile = () => {
    const [brandProfile, setBrandProfile] = useState(null);
    const [products, setProducts] = useState([]);
    const { id } = useParams(); // This will get the :id from the route
    const db = getFirestore();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBrandData = async () => {
            const brandDocRef = doc(db, 'brands_user', id);
            const brandDocSnap = await getDoc(brandDocRef);
            if (brandDocSnap.exists()) {
                setBrandProfile(brandDocSnap.data());
                console.log('Document data:');
                console.log('Document data:', brandDocSnap.data());
                // Get products from doc items, where brand == brandDocSnap.data().name
                const wearlyBrandId = brandDocSnap.data().wearly_brand_id;
                if (wearlyBrandId) {
                    const productsQuery = query(collection(db, 'products'), 
                        where('wearly_brand_id', '==', wearlyBrandId), 
                        where('is_product_published', '==', true)
                    );
                    const productsQuerySnapshot = await getDocs(productsQuery);
                    const productsData = [];
                    productsQuerySnapshot.forEach((doc) => {
                    console.log(doc.id, ' => ', doc.data());
                    productsData.push({ id: doc.id, ...doc.data() });
                    });
                    setProducts(productsData);
                } else {
                    console.log('Wearly brand ID is undefined');
                }
            } else {
                console.log('No such document!');
            }
        };

        fetchBrandData();
    }, [id]);

    if (!brandProfile) {
        return <div>Loading...</div>;
    }

    const goBack = () => {
        navigate('/');
    }

    const handleItemClick = (id) => {
        navigate(`/product/${id}`);
    }

    return (
        <div className="brand-profile">
            <button className="back-button-client" onClick={goBack}>
                <img src={ArrowBackIcon} alt="Back" />
            </button>
            <div className="brand-header">
                <div className="brand-header-overlay">
                    <img src={brandProfile.brand_profile_image} className="profile-image" alt={`${brandProfile.brand_name} logo`} style={{objectFit: 'contain'}} />
                </div>
                <h1 style={{marginTop: '15px', fontWeight: '900'}}>{brandProfile.brand_name}</h1>
                <p style={{fontWeight: '500', fontSize: '12px', marginLeft: '10%', marginRight: '10%', textAlign: 'center', justifyContent: 'center'}}>{brandProfile.brand_description}</p>
            </div>
            <h2 style={{fontWeight: '900'}}>Productos</h2>
            <div className="products-grid">
                {products.filter((product) => Array.isArray(product.item_images) && product.item_images.length > 0).map((product) => (
                    <div style={{cursor: 'pointer'}} key={product.wearly_item_id} className="product-card" onClick={() => handleItemClick(product.wearly_item_id)}>
                        <img src={product.item_images[0]} alt={product.name} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PublicBrandProfile;
