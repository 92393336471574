import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs, getDoc, query, where, updateDoc, doc, setDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { db, auth } from '../../../firebase';
import { useParams, useNavigate } from 'react-router-dom';
import '../CSS/ItemDetails.css';
import ArrowBackIcon from '../../../Assets/arrow-left-solid.svg';
import { ReactComponent as ProfileIcon } from '../../../Assets/user-solid.svg';
import { ReactComponent as CartIcon } from '../../../Assets/shopping-cart-solid.svg';
import { ReactComponent as HeartHallowIcon } from '../../../Assets/heart-regular.svg';
import { ReactComponent as HeartFilledIcon } from '../../../Assets/heart-solid.svg';
import LogoBlack from '../../../Assets/wai-logo-black.png';
import Logo from '../../../Assets/wai-logo.png';
import { ReactComponent as SearchIcon } from '../../../Assets/magnifying-glass-solid.svg';
import { ReactComponent as InstagramIcon } from '../../../Assets/instagram.svg';
import { ReactComponent as TwitterIcon } from '../../../Assets/x-twitter.svg';
import { ReactComponent as LinkedInIcon } from '../../../Assets/linkedin-in.svg';
import { ReactComponent as TikTokIcon } from '../../../Assets/tiktok.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ItemDetailsPage = () => {
  const [item, setItem] = useState({});
  const { id } = useParams();
  const [textSearch, setTextSearch] = useState('');
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUserTalle, setSelectedUserTalle] = useState('');
  const functions = getFunctions();
  const saveQueryAddToCart = httpsCallable(functions, 'save_query_add_to_cart');
  const vtonDemo = httpsCallable(functions, 'vton_prod_demo');
  const [userVTONImage, setUserVTONImage] = useState(null);
  const [loadingVTON, setLoadingVTON] = useState(false);
  const [error, setError] = useState('');
  const [itemStockLeft, setItemStockLeft] = useState({});
  const [brandProfileData, setBrandProfileData] = useState({});
  const [buttonText, setButtonText] = useState('Agregar al Carrito');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [userLikedItems, setUserLikedItems] = useState([]);
  const query_id = new URLSearchParams(window.location.search).get('query');
  const sizeOrder = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'];
  const occasions_examples_list = [
    "Tengo una joda en la playa.",
    "Vestime como Taylor Swift.",
    "Reunion en la oficina.",
    "https://pinterest.com/pin/...",
    "Yendo a un casamiento.",
    "Quiero verme como la Rosalia",
    "Un día de museo.",
    "Dame el estilo de CRO.",
    'https://instagram.com/p/...',
    "https://pinterest.com/pin/...",
    "Vamos a un boliche.",
    "Noche de cine.",
    "Picnic de verano en la plaza.",
    "https://pinterest.com/pin/...",
    "Volley en la playa.",
    "Mañana de GYM.",
    'https://instagram.com/p/...',
    "Festejo de cumpleaños.",
    "https://pinterest.com/pin/...",
    "Brunch de domingo con la flia.",
    "Me voy a hacer ski a la montaña.",
    "Me voy al fashionweek.",
  ];
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [typingIndex, setTypingIndex] = useState(0);
  const [placeholder, setPlaceholder] = useState('');

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      const firstChild = container.firstChild;
      if (firstChild) {
        const style = window.getComputedStyle(firstChild);
        const width = parseFloat(style.width);
        const marginRight = parseFloat(style.marginRight);
        const scrollTo = currentIndex * (width + marginRight);
        container.scrollLeft = scrollTo;
      }
    }
  }, [currentIndex]);

  useEffect(() => {
    const getItem = async () => {
      setIsLoading(true);
      const docRef = doc(db, 'products', id);
      const docSnap = await getDoc(docRef);
      const itemData = docSnap.exists() ? docSnap.data() : null;
      if (itemData) {
        setItem(itemData);
        const wearlyBrandId = itemData.wearly_brand_id;
        const brandQuery = query(collection(db, 'brands_user'), where('wearly_brand_id', '==', wearlyBrandId));
        const brandQuerySnapshot = await getDocs(brandQuery);
        brandQuerySnapshot.forEach((doc) => {
          setBrandProfileData(doc.data());
        });
        const userVTONImage = localStorage.getItem('userVTONImage-' + item.wearly_item_id);
        if (userVTONImage) {
          console.log(userVTONImage);
          setUserVTONImage(userVTONImage);
        }
        // Fetch stock information directly from the item document
        const stockData = itemData.stocks || {};
        setItemStockLeft(stockData);
      } else {
        navigate('/404');
      }
      setIsLoading(false);
    };
    getItem();
  }, [id, navigate]);

  useEffect(() => {
    let lastRandomIndex = -1;
    const changePlaceholderIntervalId = setInterval(() => {
      let randomIndex;
      do {
        randomIndex = Math.floor(Math.random() * occasions_examples_list.length);
      } while (randomIndex === lastRandomIndex);
      lastRandomIndex = randomIndex;
      setPlaceholderIndex(randomIndex);
      setTypingIndex(0);
    }, 5000);

    return () => clearInterval(changePlaceholderIntervalId);
  }, []);

  useEffect(() => {
    let typewriterIntervalId;
    if (placeholderIndex !== null) {
      typewriterIntervalId = setInterval(() => {
        setTypingIndex((prevTypingIndex) => {
          const phrase = occasions_examples_list[placeholderIndex];
          if (prevTypingIndex < phrase.length) {
            setPlaceholder(phrase.slice(0, prevTypingIndex + 1));
            return prevTypingIndex + 1;
          } else {
            clearInterval(typewriterIntervalId);
            return prevTypingIndex;
          }
        });
      }, 150);
    }

    return () => clearInterval(typewriterIntervalId);
  }, [placeholderIndex]);

  useEffect(() => {
    const fetchUserData = async () => {
      const userRef = doc(db, 'users', auth.currentUser.uid);
      const userSnap = await getDoc(userRef);
      setUser(userSnap.data());
      if (userSnap.data().wishlist) {
        setUserLikedItems(userSnap.data().wishlist);
      }
    };
    if (auth.currentUser) {
      fetchUserData();
    }
  }, []);

  const goBack = () => {
    // navifate one less
    navigate(-1);
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS'
    }).format(value);

  const setSelectedUserTalleClick = (talle) => {
    setSelectedUserTalle(talle);
    if (error === 'Por favor selecciona un talle') {
      setError('');
    }
  };

  const addToCart = () => {
    if (selectedUserTalle === '') {
      setError('Por favor selecciona un talle');
      return;
    }

    if (itemStockLeft[selectedUserTalle] === 0) {
      setError('No hay stock');
      return;
    }

    const cartRef = doc(db, 'users', auth.currentUser.uid);
    const cartSnap = getDoc(cartRef);
    cartSnap.then(async (cart) => {
      const newItemEntry = {
        [`item_${item.wearly_item_id}_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`]: {
          date_added: new Date(),
          selected_size: selectedUserTalle,
          wearly_item_id: item.wearly_item_id,
          wearly_brand_id: item.wearly_brand_id,
          platform_product_id: item.platform_product_id,
          platform_product_variant_id: item.platform_product_variant_id,
          platform_store_id: item.platform_store_id,
        }
      };

      if (cart.exists()) {
        updateDoc(cartRef, {
          user_cart: {
            ...cart.data().user_cart,
            ...newItemEntry
          }
        });
      } else {
        setDoc(cartRef, {
          user_cart: newItemEntry
        });
      }

      // setAlertMessage('Agregaste ' + item.name + ' al carrito!');
      toast.success('Agregaste ' + item.name + ' al carrito!');
      // setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);

      try {
        const result = await saveQueryAddToCart({ item_id: item.wearly_item_id, query_id: query_id });
        const data = result.data;
      } catch (error) {
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log('Error:', code + message + details);
      }

      setButtonText('Agregado!');
      setTimeout(() => setButtonText('Agregar al Carrito'), 3000);
    });
  };

  const handleVTON = async () => {
    setLoadingVTON(true);
    try {
      const result = await vtonDemo({ 
        user: auth.currentUser.uid, 
        garment_des: item.description, 
        garment_img: Array.isArray(item.item_images) ? item.item_images[0] : item.item_image
      });
      console.log(result);
      setUserVTONImage(result.data.data.image); // Ensure the correct path to the image URL
      // save cache
      localStorage.setItem('userVTONImage-' + item.wearly_item_id, result.data.data.image);
      setLoadingVTON(false);
    } catch (error) {
      console.error("Error in VTON:", error);
      setError("Failed to generate virtual try-on image");
    }
  };

  const handleLikeClick = async (item) => {
    const userRef = doc(db, 'users', auth.currentUser.uid);
    const userSnap = await getDoc(userRef);
    const userLikedItems = userSnap.data().wishlist || [];
    toast.success('Agregaste ' + item.name + ' a tu lista de deseos!');

    if (userLikedItems.includes(item.wearly_item_id)) {
      // Remove from wishlist
      await updateDoc(userRef, {
        wishlist: arrayRemove(item.wearly_item_id)
      });
      setUserLikedItems(userLikedItems.filter(likedItem => likedItem !== item.wearly_item_id));
    } else {
      // Add to wishlist
      await updateDoc(userRef, {
        wishlist: arrayUnion(item.wearly_item_id)
      });
      setUserLikedItems([...userLikedItems, item.wearly_item_id]);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    let searchText = '';
    // Check if the event is triggered from the form submission
    if (e.target.tagName === 'FORM') {
        searchText = e.target.elements[0].value; // Assuming the first element is the input
    } else if (e.target.tagName === 'INPUT') {
        // If the event is triggered by onKeyDown on the input
        searchText = e.target.value;
    }

    if (searchText) {
        if (searchText.startsWith('https://')) {
            // no url search yet
            console.log("no url search yet");

        } else {
            // Handle text search
            // send to home with search text
            navigate('/', { state: { searchText } });
        }
    }
  }

  const navigateToWishlist = () => {
    navigate('/wishlist');
  };

  return (
    <div>
      <ToastContainer />
      {/* {showAlert && 
        <div className="custom-alert">{alertMessage}</div>
      } */}
      <div className="item-page-client">
        <div className='mobile-navbar-item'>
          <div className='mobile-navbar-logo-item'>
            <button className="back-button-client-item" onClick={goBack}>
              <img src={ArrowBackIcon} alt="Back" />
            </button>
          </div>
          <div className='navbar-profile-item'>
            <button className='navbar-profile-button-item' onClick={() => navigate('/profile-settings')}>
              <ProfileIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
            </button>
          </div>
          <div className='navbar-heart-item'>
            <button className='navbar-heart-button-item' onClick={navigateToWishlist}>
              <HeartHallowIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
            </button>
          </div>
          <div className='mobile-navbar-cart-item'>
            <button className='navbar-cart-button-item' onClick={() => navigate('/cart')}>
              <CartIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
            </button>
          </div>
        </div>
        <div className="side-by-side-container">
          <div className='event-container' style={{ display: 'inline-block', marginTop: '3%', width: '50%' }}>
            {userVTONImage ? (
              <div >
                <img src={userVTONImage} alt="Virtual Try-On" style={{ width: '100%', height: '450px', marginBottom: '20px', borderRadius: '15px', objectFit: 'cover' }} />
                <img src={Array.isArray(item.item_images) ? item.item_images[0] : item.item_image} alt="Preview" style={{ width: '100px', height: '100px', marginTop: '0px', objectFit: 'cover', borderRadius: '15px' }} />
              </div>
            ) : (
              <div className="single-image-container">
                <img src={Array.isArray(item.item_images) ? item.item_images[0] : item.item_image} alt="Preview" style={{ maxWidth: '400px', maxHeight: '600px', marginTop: '0px', objectFit: 'contain', borderRadius: '15px' }} />
              </div>
            )}
          </div>
          <div className='new-recomended-items-container' style={{ marginTop: '3%' }}>
            <div className="item-text-container">
              <h2 style={{ marginTop: '0px', fontSize: '24px' }}>{item.name}</h2>
              <div className="price-container">
                {item.product_promotional_price ? (
                  <>
                    <span style={{ fontSize: '20px' }} className="original-price">{numberFormat(item.product_price)}</span>
                    <span style={{ fontSize: '20px' }} className="promotional-price">{numberFormat(item.product_promotional_price)}</span>
                    <span style={{ fontSize: '20px' }} className="discount-percentage">
                      {`(${Math.round(((Number(item.product_price) - Number(item.product_promotional_price)) / Number(item.product_price)) * 100)}% OFF)`}
                    </span>
                  </>
                ) : (
                  <h3 style={{ fontSize: '20px' }}>{numberFormat(item.product_price)}</h3>
                )}
              </div>
              <div className="line"></div>
              <div className="size-container">
                <h3 style={{ marginBottom: '10px', fontSize: '18px' }}>Talle</h3>
                <div className="size-buttons-container">
                {item.sizes && Object.entries(item.sizes)
                  .sort(([, a], [, b]) => {
                    const aIndex = sizeOrder.indexOf(a);
                    const bIndex = sizeOrder.indexOf(b);
                    if (aIndex !== -1 && bIndex !== -1) {
                      return aIndex - bIndex;
                    } else if (aIndex !== -1) {
                      return -1;
                    } else if (bIndex !== -1) {
                      return 1;
                    } else {
                      return a.localeCompare(b, undefined, {numeric: true});
                    }
                  })
                  .map(([key, sizeName]) => (
                    <button
                      key={key}
                      onClick={() => setSelectedUserTalleClick(sizeName)}
                      className={`size-button ${selectedUserTalle === sizeName ? 'selected' : ''}`}
                      disabled={!itemStockLeft || itemStockLeft[key] === 0}
                      style={{ color: !itemStockLeft || itemStockLeft[key] === 0 ? 'gray' : 'black' }}
                    >
                      {sizeName}
                    </button>
                  ))}
                </div>
              </div>
              <div className="container-buttons">
                {Object.values(itemStockLeft).reduce((acc, val) => acc + val, 0) === 0 ? (
                  <div className="button-with-icon" style={{ backgroundColor: '#af4c4c' }}>
                    <span>SOLD OUT</span>
                  </div>
                ) : (
                  <div className="button-with-icon" onClick={addToCart}>
                    <span>{buttonText}</span>
                  </div>
                )}
                <button className="heart-button" onClick={() => handleLikeClick(item)}>
                  {userLikedItems && userLikedItems.includes(item.wearly_item_id) ? <HeartFilledIcon className="heart-icon" /> : <HeartHallowIcon className="heart-icon" />}
                </button>
              </div>
              <button 
                className="vton-button" 
                onClick={() => {
                  setLoadingVTON(true);
                  handleVTON();
                }} 
                style={{
                  cursor: 'pointer',
                  color: 'blue',
                  display: 'inline-block',
                  marginTop: '10px',
                  backgroundColor: 'transparent',
                  border: 'none',
                  padding: '0',
                  fontSize: '10px',
                  fontWeight: 'bold',
                  marginTop: '20px',
                }}
              >
                {loadingVTON ? 'Generando VTON...' : 'Probar VTON (alpha)'}
              </button>
              <div style={{ marginTop: '20px' }}>
                <h2 style={{ fontSize: '20px', marginBottom: '10px' }}>Descripción</h2>
                <div className='item-description' dangerouslySetInnerHTML={{ __html: item.description }} style={{ fontSize: '18px' }} />
              </div>
              <p style={{ marginTop: '10px', color: 'red' }}>{error}</p>
              <h3 style={{ marginTop: '20px', marginBottom: '10px' }}>Tienda:</h3>
              <div style={{ display: 'flex', cursor: 'pointer' }} className="brand-container" onClick={() => navigate(`/brand/${brandProfileData.wearly_brand_id}`)}>
                <img 
                  src={brandProfileData.brand_profile_image} 
                  alt="Brand Logo" 
                  style={{
                    objectFit: 'contain',
                  }}
                />
                <p style={{ marginLeft: '10px' }}>{brandProfileData.brand_name}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && 
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      }
    </div>
  );
};

export default ItemDetailsPage;
