import React, { useState, useEffect, useRef } from 'react';
import '../CSS/OnboardingPage.css';
import ArrowBackIcon from '../../../Assets/arrow-left-solid.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import ConfettiExplosion from 'react-confetti-explosion';
import { db, auth } from '../../../firebase';
import { signInWithCustomToken, updatePassword } from 'firebase/auth';

const Onboarding = ({ setIsVerifyingToken }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [foundError, setFoundError] = useState(false);
    const [suggestedStyles, setSuggestedStyles] = useState([
        "streetwear", "casual", "vintage", "formal", "retro", 
        'beachwear', 'avantgarde', 'businesscasual', 'sporty'
    ]);
    const [userGender, setUserGender] = useState('');
    const [selectedStyles, setSelectedStyles] = useState([]); 
    const [selectedBudget, setSelectedBudget] = useState([]);
    const [animation, setAnimation] = useState('enter'); 
    const [questionIndex, setQuestionIndex] = useState(0);
    const [questionsCount] = useState(4);
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const styleContainerRef = useRef(null);

    console.log("Component rendered, questionIndex:", questionIndex);

    useEffect(() => {
        console.log("questionIndex changed:", questionIndex);
    }, [questionIndex]);

    useEffect(() => {
        const token = new URLSearchParams(location.search).get('token');
        const email = new URLSearchParams(location.search).get('email');
        setEmail(email);
        if (!token) {
          setQuestionIndex(1);
          return;
        }
    
        setIsVerifyingToken(true);
        setLoading(true);
        signInWithCustomToken(auth, token)
          .then(() => {
            setLoading(false);
            setSuccess(true);
            setQuestionIndex(0);
          })
          .catch((error) => {
            setLoading(false);
            setQuestionIndex(1);
          })
          .finally(() => {
            setIsVerifyingToken(false);
          });
    }, [location, navigate, setIsVerifyingToken]);

    useEffect(() => {
        const fetchData = async () => {
            if (!auth.currentUser) {
                navigate('/');
                return;
            }
            setLoading(true);
            const userRef = doc(db, "users", auth.currentUser.uid);
            const userDoc = await getDoc(userRef);
            const userData = userDoc.data();
            setLoading(false);
            if (userData.onboarding_index === undefined) {
                setQuestionIndex(0);
            } else {
                setQuestionIndex(userData.onboarding_index);
            }
        };
        fetchData();
    }, [navigate]);

    const handleSelect = (item, setter, state) => {
        if (setter === setUserGender) {
            // Convert display text to Firebase value
            const firebaseValue = item === "Mujer" ? "female" : item === "Hombre" ? "male" : item;
            setter(firebaseValue);
        } else {
            setter(prevState => 
                prevState.includes(item) 
                    ? prevState.filter(s => s !== item) 
                    : [...prevState, item]
            );
        }
    };

    const saveDataToFirestore = async () => {
        if (!auth.currentUser) {
            console.error("User not authenticated");
            setError("User not authenticated");
            setFoundError(true);
            return;
        }
    
        const userRef = doc(db, "users", auth.currentUser.uid);
    
        try {
            if (questionIndex === 0) {
                if (password !== confirmPassword) {
                    setError('Passwords do not match');
                    setFoundError(true);
                    return;
                }
    
                const user = auth.currentUser;
                if (user) {
                    await updatePassword(user, password);
                    setSuccess(true);
                    goToNextQuestion();
                } else {
                    throw new Error('User not authenticated');
                }
            } else if (questionIndex === 1) {
                if (userGender) {
                    await setDoc(userRef,
                        {
                            preferences: {gender: userGender},
                            onboarding_index: questionIndex + 1
                        }, {merge: true}
                    );
                    goToNextQuestion();
                } else {
                    setError("Please select a gender");
                    setFoundError(true);
                }
            } else if (questionIndex === 2) {
                if (selectedStyles.length) {
                    await setDoc(userRef, 
                        {
                            preferences: {style: selectedStyles}, 
                            onboarding_index: questionIndex + 1
                        }, {merge: true}
                    );
                    goToNextQuestion();
                } else {
                    setError("Please select at least one style");
                    setFoundError(true);
                }
            } else if (questionIndex === 3) {
                if (selectedBudget.length) {
                    await setDoc(userRef,
                        {
                            preferences: {budget: selectedBudget},
                            onboarding_index: questionIndex + 1,
                        }, {merge: true}
                    );
                    console.log("Onboarding completed");
                    setQuestionIndex(4);
                    console.log("Question index set to 4");
                    return;
                } else {
                    setError("Please select at least one budget");
                    setFoundError(true);
                }
            }
        } catch (error) {
            console.error("Error saving data to Firestore:", error);
            setError(error.message);
            setFoundError(true);
        }
    };

    const endOnboarding = async () => {
        // set onboarding_status to true
        const userRef = doc(db, "users", auth.currentUser.uid);
        await setDoc(userRef, {onboarding_status: true}, {merge: true});
        // wait 3 seconds and send to home
        setTimeout(() => {
            navigate('/');
        }, 3000);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        saveDataToFirestore();
    };

    const goToNextQuestion = () => {
        console.log("questionIndex:", questionIndex);
        if (questionIndex < questionsCount - 1) {
            setIsAnimating(true);
            setTimeout(() => {
                setQuestionIndex(prevIndex => prevIndex + 1);
                setIsAnimating(false);
            }, 500); // This should match the animation duration
        }
    };

    return (
        <div className="onboarding-container">
            <video autoPlay loop muted playsInline style={{position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover', zIndex: -1}}>
                <source src={require("../../../Assets/background-video-1.mp4")} type="video/mp4" />
            </video>
            <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 0}}></div>
            
            <div className={`onboarding-card ${isAnimating ? 'question-exit' : 'question-enter'}`}>
                <div className="navigation-container">
                    {(questionIndex > 1 || questionIndex === 4) && (
                        <button className="back-button-onboarding" onClick={() => {
                            if (questionIndex === 2) {
                                setUserGender('');
                            } else if (questionIndex === 3) {
                                setSelectedStyles([]);
                            } else if (questionIndex === 4) {
                                setSelectedBudget([]);
                            }
                            setQuestionIndex(questionIndex - 1);
                        }}>
                            <img src={ArrowBackIcon} alt="Back" />
                        </button>
                    )}
                    <div className="progress-bar-container">
                        <div className="progress-bar" style={{ width: `${(questionIndex / questionsCount) * 100}%` }}></div>
                    </div>
                </div>
                {questionIndex === 0 && (
                    <div>
                        <h1>Establece tu contraseña</h1>
                        <h5>Crea una contraseña segura para tu cuenta</h5>
                        <form onSubmit={handleSubmit} style={password_styles.form}>
                      <input
                        type="password"
                        id="password"
                        placeholder="Nueva contraseña"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        style={password_styles.input}
                      />
                      <input
                        type="password"
                        id="confirmPassword"
                        placeholder="Confirmar contraseña"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        style={password_styles.input}
                      />
                      <button type="submit" style={password_styles.button}>
                        Establecer contraseña
                            </button>
                        </form>
                    </div>
                )}
                {questionIndex === 1 && (
                    <div>
                        <h1 style={{fontSize: "30px"}}>¿Qué tipo de ropa prefieres?</h1>
                        <p style={{marginBottom: "2rem"}}>Elige el tipo de ropa que prefieras.</p>
                        {foundError && <p className="error-message">{error}</p>}
                        <div className="gender-selection-area">
                            <div className="gender-select-container">
                                {[
                                    { display: "Mujer", value: "female" },
                                    { display: "Hombre", value: "male" },
                                    { display: "Unisex", value: "unisex" }
                                ].map(({ display, value }) => (
                                    <div
                                        key={value}
                                        className={`gender-select-box ${userGender === value ? 'selected' : ''}`}
                                        onClick={() => handleSelect(display, setUserGender, userGender)}
                                    >
                                        <p className="gender-select-text">{display}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                {questionIndex === 2 && (
                    <div>
                        <h1 style={{fontSize: "30px"}}>Elige tu estilo</h1>
                        <p style={{marginBottom: "2rem"}}>Elige el estilo que mejor represente tus preferencias de moda.</p>
                        <div className="style-image-container-onboarding" ref={styleContainerRef}>
                            {suggestedStyles.map((style) => (
                                <div
                                    key={style}
                                    className={`style-image-box-onboarding ${selectedStyles.includes(style) ? 'selected' : ''}`}
                                    onClick={() => handleSelect(style, setSelectedStyles, selectedStyles)}
                                >
                                    <img
                                        src={require(`../../../Assets/${style}-profile-image.png`)}
                                        alt={style}
                                        className="style-image-onboarding"
                                    />
                                    <div className="style-text-onboarding">{style.toUpperCase()}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {questionIndex === 3 && (
                    <div>
                        <h1 style={{fontSize: "30px"}}>¿Cuanto estas dispuesto a gastar?</h1>
                        <p style={{marginBottom: "2rem"}}>¿Cuanto estas dispuesto a gastar en una prenda de vestir?</p>
                        <div className="gender-selection-area">
                            <div className="gender-select-container">
                                {["$", "$$", "$$$", "$$$$"].map((budget, index) => (
                                    <div
                                        key={index}
                                        className={`gender-select-box ${selectedBudget.includes(budget) ? 'selected' : ''}`}
                                        onClick={() => handleSelect(budget, setSelectedBudget, selectedBudget)}
                                    >
                                        <p className="gender-select-text">{budget}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                {questionIndex === 4 && (
                    <div>
                        {console.log("Rendering final screen")}
                        <h1 style={{fontSize: "30px"}}>Muchas gracias por completar tu perfil!</h1>
                        <p style={{marginBottom: "2rem"}}>Ahora podras disfrutar de una experiencia de compra personalizada.</p>
                        <ConfettiExplosion />
                        <button className="next-button-onboarding" onClick={endOnboarding}>
                            Comenzar a explorar!
                        </button>
                    </div>
                )}
                {questionIndex < questionsCount && questionIndex !== 0 && (
                    <div className="flex-container">
                        <button 
                            className="next-button-onboarding"
                            onClick={saveDataToFirestore}
                            disabled={isAnimating}
                        >
                            Continue
                        </button>
                    </div>
                )}
            </div>
            {loading && 
                <div className="loading-overlay">   
                    <div className="spinner"></div>
                </div>
            }
        </div>
    );
};

const password_styles = {
    signup: {
      maxWidth: '600px',
      margin: '0 auto',
      padding: '20px',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
    input: {
      padding: '10px',
      margin: '5px 0',
      borderRadius: '7px',
      border: 'none',
      backgroundColor: '#f3f3f3',
    },
    button: {
      padding: '15px 0',
      marginTop: '20px',
      fontSize: '16px',
      textAlign: 'center',
      color: '#fff',
      backgroundColor: '#000',
      border: 'none',
      borderRadius: '7px',
      cursor: 'pointer',
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    error: {
      color: 'red',
    },
    backButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '40px',
      height: '40px',
      borderRadius: '7px',
      backgroundColor: '#f3f3f3',
      cursor: 'pointer',
      border: 'none',
    },
    backButtonImg: {
      width: '50%',
      height: 'auto',
    },
  };
  

export default Onboarding;


