import React, { useState, useEffect } from 'react';
import '../CSS/LogIn.css';
import Logo from '../../../Assets/wai-logo.png';
import ArrowBackIcon from '../../../Assets/arrow-left-solid.svg';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook for navigation
import { getFunctions, httpsCallable } from "firebase/functions";
import { signInWithEmailLink, isSignInWithEmailLink, signInWithEmailAndPassword } from 'firebase/auth';
import { collection, getDocs, getDoc, query, where, updateDoc, doc, arrayUnion, addDoc, deleteDoc, setDoc, arrayRemove } from 'firebase/firestore';
import { getAnalytics, logEvent } from "firebase/analytics";
import { ReactComponent as ShareIcon } from '../../../Assets/share-solid.svg';
import ConfettiExplosion from 'react-confetti-explosion';
import { db, auth } from '../../../firebase';

const LogIn = () => {
    const navigate = useNavigate();
    const [error, setError] = useState('');    
    const functions = getFunctions();
    const [emailResult, setEmailResult] = useState('');
    const [loading, setLoading] = useState(false);
    const [foundError, setFoundError] = useState(false);
    const [email, setEmail] = useState('');
    const [feedbackAsk, setFeedbackAsk] = useState(false);
    const [userCountry, setUserCountry] = useState('');
    const [userWaitlistPosition, setUserWaitlistPosition] = useState(0);
    const [isWaitlistPositionLoading, setIsWaitlistPositionLoading] = useState(true);
    const [code, setCode] = useState('');
    const [refCode, setRefCode] = useState('');
    const analytics = getAnalytics();
    const searchSignup = httpsCallable(functions, 'signup')

    const handleRefShare = (email) => {
        const shareUrl = `https://wearlyapp.com?ref=${email}`
        navigator.clipboard.writeText(shareUrl).then(() => {
            
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    }

    const handleFeedback = (e) => {
        setFeedbackAsk(false);
        e.preventDefault();
        if (!email) {
            return;
        }
        const brand = e.target[0].value;
        const reason = e.target[1].value;
        const feedback = { brand: brand, reason: reason, email: email};
        addDoc(collection(db, 'feedback'), feedback)
        .then((result) => {
            console.log(result);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const handleSubmit = (e) => {

        if (e && e.preventDefault) {
            e.preventDefault();
        }
        const email = e.target[0].value;
        const password = e.target[1].value;
        setEmail(email);
        setLoading(true);
        setError('');
        setFoundError(false);
        setEmailResult('');
        if (email && password) {
            // try signing in with email and password
            signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                console.log(user);
                setLoading(false);
                navigate('/');
            })
            .catch((error) => {
                searchSignup({ email: email, code: code, country: userCountry, refCode: refCode })
                .then((result) => {
                    const data = result.data;
                    if (data.code === 'login_link') {
                        setEmailResult('Bienvenido devuelta! Te enviamos un link a tu email.');
                        window.localStorage.setItem('emailForSignIn', email);
                        logEvent(analytics, data.code);
                    } else if (data.code == 'waitlisted') {
                        setFeedbackAsk(true);
                        setEmailResult('Gracias! Estas en la lista de espera.');
                        setUserWaitlistPosition(data.waitlist_position); // Update waitlist position
                        setIsWaitlistPositionLoading(false);
                        logEvent(analytics, data.code);
                    } else if (data.code == 'login_link_w_code') {
                        setEmailResult('Invitacion aceptada. Te enviamos un link a tu email!');
                        window.localStorage.setItem('emailForSignIn', email);
                        logEvent(analytics, data.code);
                    } else if (data.code == 'waitlisted_w_code') {
                        setEmailResult('El codigo ha sido usado anteriormente, ahora estas en la lista de espera.');
                        setUserWaitlistPosition(data.waitlist_position); // Update waitlist position
                        setIsWaitlistPositionLoading(false);
                        setFeedbackAsk(true);
                        logEvent(analytics, "waitlisted");
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    const code = error.code;
                    const message = error.message;
                    const details = error.details;
                    console.log('Error:', code + message + details);
                    setLoading(false);
                    setFoundError(true);
                    setError(error);
                });
            });
        }
    }

    const goBack = () => {
        navigate(-1); // Use navigate(-1) to go back
    };

    return (
        <div className='login-container-video'>
            <video autoPlay loop muted playsInline style={{position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover', zIndex: -1}}>
                <source src={require("../../../Assets/background-video-1.mp4")} type="video/mp4" />
            </video>
            <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 0}}></div>
            <div className="login-container">
                <button className="back-button-login" onClick={goBack}>
                    <img src={ArrowBackIcon} alt="Back" />
                </button>
                <div className="login-card">
                    <div className="logo-container-login" style={{width: '100%'}}>
                        <img src={Logo} alt="Logo" className='logo' style={{objectFit: 'contain', width: '170px', height: '170px'}} />
                    </div>
                    <h1 style={{textAlign: 'left', fontFamily: 'Raleway', fontWeight: 'bold'}}>Log In</h1>
                    <p style={{textAlign: 'left', fontFamily: 'Raleway', fontWeight: 'bold', marginBottom: '10px', marginRight: '5%'}}>Inicia sesión en tu cuenta.</p>
                    <form onSubmit={handleSubmit}>
                        <div className="input-group" style={{marginBottom: '30px'}}>
                            <input type="email" className='input-login' placeholder="Email" required style={{fontFamily: 'Raleway', fontWeight: 'bold', padding: '5%'}} />
                            <input type="password" className='input-login' placeholder="Password" required style={{fontFamily: 'Raleway', fontWeight: 'bold', padding: '5%', marginTop:'10px'}} />
                        </div>
                        <button type="submit" className="login-button" style={{fontFamily: 'Raleway', fontWeight: 'bold', textAlign: 'center', marginBottom: '10px', marginTop: '30%'}}>Let's Go!</button>
                        <p style={{color:"black"}}>{emailResult}</p>
                        <p style={{color:"black"}}>{error.message}</p> {/* Render error.message instead of error */}
                    </form>
                </div>
                {feedbackAsk && 
                    <div className='modal no-scroll'>
                        <div className='modal-content'>
                            <button className='modal-close-btn' onClick={() => setFeedbackAsk(false)}>X</button>
                            <div className='modal-confetti'>
                                <ConfettiExplosion />
                            </div>
                            <div className='modal-header'>
                                <h2>Gracias por unirte a nuestra lista de espera! 🥳</h2>
                            </div>
                            <div className='modal-body'>
                                <p style={{fontSize:'14px'}}>Te notificarmenos cuando salgas de la lista de espera.</p>
                                <h1 style={{textAlign:'center'}}>
                                    {isWaitlistPositionLoading ? (
                                        <div>Loading...</div> // Show loading message or spinner
                                    ) : (
                                        `#${userWaitlistPosition}` // Show waitlist position when not loading
                                    )}
                                </h1>
                                <p style={{fontSize:'14px'}}>Para avanzar en la lista de espera, puedes compartir Wearly con el siguiente enlace con cualquier persona que conozcas y que pueda estar interesada.</p>
                                <div style={{marginTop:'5%'}}>
                                    Compartir Wearly
                                    <button
                                        type="button"
                                        style={{
                                            cursor: 'pointer',
                                            backgroundColor: 'transparent',
                                            border: '0px',
                                            boxSizing: 'border-box',
                                            fill: 'black',
                                        }}
                                        onClick={() => handleRefShare(email)}
                                    >
                                        <ShareIcon style={{ width: '20px', height: '20px' }} />
                                    </button>
                                </div>
                                <br/>
                                <a className='share-url' href={`https://wearlyapp.com/?ref=${email}`} target="_blank" rel='noreferrer' style={{marginTop:'5%'}}><i>https://wearlyapp.com/?ref={email}</i></a>
                                <form onSubmit={handleFeedback} style={{marginTop:'7%'}}>
                                    <p style={{fontSize:'14px'}}><i>Nos encantaría conocerte mas!</i></p>
                                    <input type="text" placeholder="Marca Favorita" />
                                    <input type="text" placeholder="¿Por qué te interesa Wearly?" />
                                    <button type="submit">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                }
                {loading && 
                    <div className="loading-overlay">
                        <div className="spinner"></div>
                    </div>
                }
            </div>
        </div>
    );
};

export default LogIn;