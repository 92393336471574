import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as SearchIcon } from '../Assets/magnifying-glass-solid.svg';
import { ReactComponent as HeartHallowIcon } from '../Assets/heart-regular.svg';
import { ReactComponent as HeartFilledIcon } from '../Assets/heart-solid.svg';
import { ReactComponent as ProfileIcon } from '../Assets/user-solid.svg';
import { ReactComponent as CartIcon } from '../Assets/bag-shopping-solid.svg';
import { ReactComponent as MoreIcon } from '../Assets/more-menu-icon.svg';
import { ReactComponent as TredningIcon } from '../Assets/arrow-trend-up-solid.svg';
import { ReactComponent as ArrowBackIcon } from '../Assets/arrow-left-solid.svg';
import { collection, getDocs, getDoc, query, where, updateDoc, doc, arrayUnion, arrayRemove, onSnapshot } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { db, auth } from '../firebase';
import { storage } from '../firebase';
import { v4 as uuidv4 } from 'uuid';
import './Home.css';
import Logo from '../Assets/wai-logo.png';
import { ReactComponent as SettingsIcon } from '../Assets/search-settings-icon.svg';
import { ReactComponent as SortIcon } from '../Assets/sort-settings-icon.svg';
import '../spinner.css';
import { getAnalytics, logEvent } from "firebase/analytics";
import Resizer from "react-image-file-resizer";
// import mainAddImage from '../Assets/main-add-image.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import pilchaEventTickets from '../Assets/pilcha-event-tickets.png';
import LogoBlack from '../Assets/wai-logo-black.png';
import { ReactComponent as InstagramIcon } from '../Assets/instagram.svg';
import { ReactComponent as TwitterIcon } from '../Assets/x-twitter.svg';
import { ReactComponent as LinkedInIcon } from '../Assets/linkedin-in.svg';
import { ReactComponent as TikTokIcon } from '../Assets/tiktok.svg';

function Home() {
    const navigate = useNavigate();
    const analytics = getAnalytics();
    const [menuOpen, setMenuOpen] = useState(false);
    const [textSearch, setTextSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [user, setUser] = useState(null);
    const [userPrivate, setUserPrivate] = useState(null);
    const [codes, setCodes] = useState(null);
    const [uploadData, setUploadData] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [signinModal, setSigninModal] = useState(false);
    const [foundError, setFoundError] = useState(false);
    const [genderSearch, setGenderSearch] = useState('');
    const [clothingType, setClothingType] = useState('');
    const [error, setError] = useState('');
    const [userImage, setUserImage] = useState(null);
    const functions = getFunctions();
    const searchMagic = httpsCallable(functions, 'natural_langue_processing');
    const searchSignup = httpsCallable(functions, 'signup');
    const saveQueryClick = httpsCallable(functions, 'save_query_click');
    const getRecomendedSearches = httpsCallable(functions, 'query_suggestion');
    const getItemSuggestions = httpsCallable(functions, 'item_suggestion');
    const getNewItemsSuggestions = httpsCallable(functions, 'new_items_suggestion');
    const getRecomendedBrands = httpsCallable(functions, 'get_recomended_brands');
    const getItemsSuggestedSearch = httpsCallable(functions, 'get_items_suggested_search');
    const getItemsByStyle = httpsCallable(functions, 'get_items_by_style');
    // const getSuggestedOutfit = httpsCallable(functions, 'get_suggested_outfit');
    const [suggestedOutfit, setSuggestedOutfit] = useState([]);
    const [userLikedItems, setUserLikedItems] = useState([]);
    const [recomendedSearches, setRecomendedSearches] = useState(null);
    const [recomendedItems, setRecomendedItems] = useState([]);
    const [recomendedBrands, setRecomendedBrands] = useState([]);
    const [suggestedStyles, setSuggestedStyles] = useState(["streetwear", "casual", "vintage", "formal", "retro", 'beachwear', 'avantgarde', 'businesscasual', 'sporty']);
    const [communityPosts, setCommunityPosts] = useState(["pilcha-event-tickets"]);
    const [newRecomendedItems, setNewRecomendedItems] = useState([]);
    const [sortOrder, setSortOrder] = useState('relevant'); // Default to 'relevant'
    const loading_image_array = [
        'https://acdn.mitiendanube.com/stores/001/177/291/products/cargomarinofrente-cab943f6b382888c5b17132410230292-1024-1024.webp',
        'https://acdn.mitiendanube.com/stores/001/177/291/products/g1-42ad8fb2dddfd82d6e17130512410782-1024-1024.webp',
        'https://acdn.mitiendanube.com/stores/951/831/products/hoodiefront2-f0c84b0d650d1d18c117176216772017-1024-1024.webp',
        'https://acdn.mitiendanube.com/stores/951/831/products/hype_web_png_v21-min-b3916e48ae0c6f714317134535649845-1024-1024.webp',
        'https://acdn.mitiendanube.com/stores/001/691/011/products/web-44-740d40988f97f5d7df17173751018814-1024-1024.webp',
        'https://img.ssensemedia.com/images/g_center,f_auto/241232F127015_1/rick-owens-black-mega-bumper-sneakers.jpg',
        'https://acdn.mitiendanube.com/stores/002/012/649/products/campera-negra-tienda-1-b3d4fcc18e17584c3d17135584568571-1024-1024.webp',
        'https://www.acnestudios.com/dw/image/v2/AAXV_PRD/on/demandware.static/-/Sites-acne-product-catalog/default/dwbf9d7240/images/A0/A00443-/2000x/A00443-BUF_F.jpg?sw=2000&sh=3000',
        'https://img.ssensemedia.com/images/b_white,c_lpad,g_south,h_1086,w_724/c_scale,h_480/f_auto,dpr_2.0/241553F048021_1/jacquemus-blue-le-petit-bambimou-bag.jpg',
        'https://us.kowtowclothing.com/cdn/shop/products/oversized_boxy_tee-white-grid-01copy.jpg?v=1707354614&width=1946',
        'https://media.gq.com/photos/5aaa963999d53d444a5d8f9d/3:4/w_748%2Cc_limit/Zara-pleated-trousers.jpg',
        'https://acdn.mitiendanube.com/stores/003/975/385/products/jordan-negro-03-1498a69c2cca1bd8fb17019794225329-1024-1024.webp',
        'https://acdn.mitiendanube.com/stores/001/339/958/products/foto-tn-f6b1170901ec9c708317121673523791-1024-1024.webp',
        'https://www.acnestudios.com/dw/image/v2/AAXV_PRD/on/demandware.static/-/Sites-acne-product-catalog/default/dwbf9d7240/images/A0/A00443-/2000x/A00443-BUF_F.jpg?sw=2000&sh=3000',
        'https://i.pinimg.com/474x/91/ac/34/91ac343035f22cb37ffffddea3c06ea4.jpg',
        'https://i.pinimg.com/474x/d3/82/53/d38253cbd281df2838203c79c4aaa0c6.jpg',
        'https://i.pinimg.com/564x/fb/14/8e/fb148ec1ea5c5ae403b8ad80a11ccd4a.jpg',
        'https://acdn.mitiendanube.com/stores/001/339/958/products/base-foto-tienda-nube_mesa-de-trabajo-1-copia-61-a4fcba5aecbbd74c3416890049135587-1024-1024.webp',
        'https://acdn.mitiendanube.com/stores/003/975/385/products/milan-habano-03-7c1582f920b00ff71e17019789042479-1024-1024.webp',
        'https://acdn.mitiendanube.com/stores/001/587/536/products/buzonegro-8e291d60922ca2b4d517159729830109-1024-1024.webp',
        'https://i.pinimg.com/474x/fe/6e/27/fe6e278697f7890c4a5a2dce3bcc0f90.jpg',
        'https://i.pinimg.com/474x/04/4e/9a/044e9a67303ce126a735a01069dda086.jpg',
        'https://i.pinimg.com/564x/bd/a4/50/bda450802ad0dcb60ce3d2c7d96c0bc7.jpg',
        'https://i.pinimg.com/736x/92/30/8a/92308ada6b30291398071c028f90df09.jpg',
        'https://i.pinimg.com/736x/4f/eb/5b/4feb5b15915122ebbda54116874ea925.jpg',
        'https://acdn.mitiendanube.com/stores/001/807/964/products/frame-607-3b7076d74d11184a2517172815922340-1024-1024.webp',
    ]
    const [currentLoadingImages, setCurrentLoadingImages] = useState(loading_image_array.slice(0, 5));
    const occasions_examples_list = [
        "Tengo una joda en la playa.",
        "Vestime como Taylor Swift.",
        "Reunion en la oficina.",
        // "https://pinterest.com/pin/...",
        "Yendo a un casamiento.",
        "Quiero verme como la Rosalia",
        "Un día de museo.",
        "Dame el estilo de CRO.",
        // 'https://instagram.com/p/...',
        // "https://pinterest.com/pin/...",
        "Vamos a un boliche.",
        "Noche de cine.",
        "Picnic de verano en la plaza.",
        // "https://pinterest.com/pin/...",
        "Volley en la playa.",
        "Mañana de GYM.",
        // 'https://instagram.com/p/...',
        "Festejo de cumpleaños.",
        // "https://pinterest.com/pin/...",
        "Brunch de domingo con la flia.",
        "Me voy a hacer ski a la montaña.",
        "Me voy al fashionweek.",
    ];
    const [showSortDropdown, setShowSortDropdown] = useState(false);
    const toggleSortDropdown = () => setShowSortDropdown(!showSortDropdown);
    const [placeholderIndex, setPlaceholderIndex] = useState(0);
    const [typingIndex, setTypingIndex] = useState(0);
    const [placeholder, setPlaceholder] = useState('');
    const [loadingPhrase, setLoadingPhrase] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 40;
    const [showMoreMenu, setShowMoreMenu] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [showSortOption, setShowSortOption] = useState(false);
    const [showSortIcon, setShowSortIcon] = useState(false);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentItems = itemList ? itemList.slice(indexOfFirstItem, indexOfLastItem) : [];
    const [shareButtonText, setShareButtonText] = useState('Share Search');
    const [showSearchSettingsModal, setShowSearchSettingsModal] = useState(false);
    const [searchBy, setSearchBy] = useState('item_name');
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const locationState = useLocation();
    // Calculate page numbers
    const sortedItemList = itemList
    ? itemList.slice().sort((a, b) => {
        const priceA = a.product_promotional_price && a.product_promotional_price !== 0 ? a.product_promotional_price : a.product_price;
        const priceB = b.product_promotional_price && b.product_promotional_price !== 0 ? b.product_promotional_price : b.product_price;

        if (sortOrder === 'lowestPrice') {
            return priceA - priceB;
        } else if (sortOrder === 'highestPrice') {
            return priceB - priceA;
        }
        return 0;
    })
    : [];
    const currentItems = sortedItemList.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(itemList ? itemList.length / itemsPerPage : 0);
    const maxPageNumbersToShow = 5; // Adjust as needed for the number of page buttons to show

    const notifyNoItems = () => {
        toast.error("No items available for this search!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      };
    
    // Function to generate the array of page numbers to display
    const generatePageNumbers = (currentPage, totalPages, maxPageNumbersToShow) => {
        const halfWay = Math.floor(maxPageNumbersToShow / 2);
        let start = Math.max(currentPage - halfWay, 1);
        let end = Math.min(start + maxPageNumbersToShow - 1, totalPages);

        if (end === totalPages) {
            start = Math.max(end - maxPageNumbersToShow + 1, 1);
        }

        const range = (start, end) => Array.from({ length: (end - start) + 1 }, (_, i) => start + i);
        return range(start, end);
    };

    const displayPageNumbers = generatePageNumbers(currentPage, totalPages, maxPageNumbersToShow);

    useEffect(() => {
        // Attempt to retrieve itemList from sessionStorage
        const cachedItemList = sessionStorage.getItem('itemList');
        console.log('Cached Item List:', cachedItemList);
        if (cachedItemList) {
            const parsedItemList = JSON.parse(cachedItemList);
            const filteredItemList = parsedItemList.filter(item => item.item_image !== null && item.item_image !== "");
            setItemList(filteredItemList);
        } else {
            // Optionally, fetch initial itemList data or set to a default value
            setItemList([]);
        }
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentLoadingImages(() => {
                const shuffled = [...loading_image_array].sort(() => 0.5 - Math.random());
                return shuffled.slice(0, 5);
            });
        }, 3000);
    
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const container = document.querySelector('.new-recomended-items-container-items');
        if (container) {
            const itemCount = newRecomendedItems.filter(item => item.item_image !== null && item.item_image !== "").length;
            const rowCount = itemCount <= 10 ? 1 : 2;
            container.style.gridTemplateRows = `repeat(${rowCount}, 1fr)`;
        }
    }, [newRecomendedItems]);
    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const queryId = urlParams.get('query');
    
        if (queryId) {
            setLoading(true);
            console.log(`Received query ID: ${queryId}`);
            const fetchItems = async () => {
                try {
                    const getQueryById = httpsCallable(functions, 'get_items_suggested_search');
                    const result = await getQueryById({ query_id: queryId });
                    
                    const items = result.data.filter(item => item.item_image !== null && item.item_image !== "");
                    console.log('Items fetched:', items);
                    setItemList(items);
                    // add query to URL
                    const currentUrl = new URL(window.location);
                    currentUrl.searchParams.set('query', queryId);
                    window.history.pushState({}, '', currentUrl);
                    // add search to input
                    if (items.length > 0) {
                        setTextSearch(items[0].query_input);
                    }
                    sessionStorage.setItem('itemList', JSON.stringify(items));
                    setLoading(false);
                } catch (error) {
                    console.error("Failed to fetch items by query ID:", error);
                    setError("Failed to fetch items: " + error.message);
                    setFoundError(true);
                    setLoading(false);
                }
            };
    
            fetchItems();
        }
    }, []);

    useEffect(() => {
        if (locationState.state?.searchText) {
            setTextSearch(locationState.state.searchText);
            functionsExecute(locationState.state.searchText, 'text_search');
        }
    }, [locationState.state]);

    useEffect(() => {
        const fetchData = async () => {
            // Check if data is in sessionStorage
            const cachedCodes = sessionStorage.getItem('codes');
            if (cachedCodes) {
                setCodes(JSON.parse(cachedCodes));
            } else if (auth.currentUser) {
                window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
                const codesCollectionRef = collection(db, 'codes');
                const codesQuery = query(codesCollectionRef, where('user_id', '==', auth.currentUser.uid));
                try {
                    const codesSnapshot = await getDocs(codesQuery);
                    const codesList = codesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    setCodes(codesList);
                    console.log('Codes:', codesList);
                    // Cache the data in sessionStorage
                    sessionStorage.setItem('codes', JSON.stringify(codesList));
                } catch (error) {
                    console.error("Failed to fetch documents:", error);
                }
            }
            console.log('Codes:', codes);
        };
        
        fetchData();
    }, [auth.currentUser]);

    useEffect(() => {
        const fetchData = async () => {
            const cachedRecomendedSearches = sessionStorage.getItem('recomendedSearches');
            if (cachedRecomendedSearches) {
                setRecomendedSearches(JSON.parse(cachedRecomendedSearches));
            } else {
                const result = await getRecomendedSearches();
                console.log('Recomended Searches:', result.data);
                const data = result.data;
                setRecomendedSearches(data);
                sessionStorage.setItem('recomendedSearches', JSON.stringify(data));
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const cachedRecomendedItems = sessionStorage.getItem('recomendedItems');
            if (cachedRecomendedItems) {
                setRecomendedItems(JSON.parse(cachedRecomendedItems));
            } else {
                const result = await getItemSuggestions();
                console.log('Item Suggestions:', result.data);
                const data = result.data;
                setRecomendedItems(data);
                sessionStorage.setItem('recomendedItems', JSON.stringify(data));
            }
            const cachedNewRecomendedItems = sessionStorage.getItem('newRecomendedItems');
            if (cachedNewRecomendedItems) {
                setNewRecomendedItems(JSON.parse(cachedNewRecomendedItems));
            } else {
                const resultNew = await getNewItemsSuggestions();
                const dataNew = resultNew.data;
                setNewRecomendedItems(dataNew);
                console.log('New Item Suggestions:', resultNew.data);
                sessionStorage.setItem('newRecomendedItems', JSON.stringify(dataNew));
            }
            const cachedRecomendedBrands = sessionStorage.getItem('recomendedBrands');
            if (cachedRecomendedBrands) {
                setRecomendedBrands(JSON.parse(cachedRecomendedBrands));
            } else {
                const resultBrands = await getRecomendedBrands();
                const dataBrands = resultBrands.data;
                setRecomendedBrands(dataBrands);
                sessionStorage.setItem('recomendedBrands', JSON.stringify(dataBrands));
            }
        }
        fetchData();
    }, []);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const cachedSuggestedOutfit = sessionStorage.getItem('suggestedOutfit');
    //         if (cachedSuggestedOutfit) {
    //             setSuggestedOutfit(JSON.parse(cachedSuggestedOutfit));
    //         } else {
    //             const result = await getSuggestedOutfit();
    //             const data = result.data;
    //             setSuggestedOutfit(data);
    //             sessionStorage.setItem('suggestedOutfit', JSON.stringify(data));
    //         }
    //     }
    //     fetchData();
    // }, []);

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'codes'), (snapshot) => {
            const codesList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setCodes(codesList);
            sessionStorage.setItem('codes', JSON.stringify(codesList));
        });

        return () => unsubscribe();
    }, []);

    const [credits, setCredits] = useState(null);

    useEffect(() => {
        const privateDocRef = doc(db, 'users', auth.currentUser.uid, 'private', 'data');
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        const unsubscribe = onSnapshot(privateDocRef, (doc) => {
            if (doc.exists()) {
                const privData = doc.data();
                const credits = privData.credits;
                setUserPrivate(privData);
                setCredits(credits);
            }
        });
        onSnapshot(userDocRef, (doc) => {
            if (doc.exists()) {
                const userData = doc.data();
                setUser(userData);
                console.log(userData.user_cart);
                setUserLikedItems(userData.wishlist);
                setGenderSearch(userData.preferences.gender);
            }
        });

        return () => unsubscribe();
    }, []);


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const sharedCode = urlParams.get('share');

        if (sharedCode) {
            console.log(`Received shared code: ${sharedCode}`);
        }
    }, []);

    useEffect(() => {
        let lastRandomIndex = -1;
        const changePlaceholderIntervalId = setInterval(() => {
            let randomIndex;
            do {
                randomIndex = Math.floor(Math.random() * occasions_examples_list.length);
            } while (randomIndex === lastRandomIndex);
            lastRandomIndex = randomIndex;
            setPlaceholderIndex(randomIndex);
            setTypingIndex(0); // Reset typing index for new phrase
        }, 5000); // Change phrase every 3 seconds TODO: CHange to make it adaptable to phrase length
    
        return () => clearInterval(changePlaceholderIntervalId);
    }, []);
    
    useEffect(() => {
        let typewriterIntervalId;
        if (placeholderIndex !== null) {
            typewriterIntervalId = setInterval(() => {
                setTypingIndex((prevTypingIndex) => {
                    const phrase = occasions_examples_list[placeholderIndex];
                    if (prevTypingIndex < phrase.length) {
                        setPlaceholder(phrase.slice(0, prevTypingIndex + 1));
                        return prevTypingIndex + 1;
                    } else {
                        clearInterval(typewriterIntervalId);
                        return prevTypingIndex;
                    }
                });
            }, 150);
        }
    
        return () => clearInterval(typewriterIntervalId);
    }, [placeholderIndex]);
    
    useEffect(() => {
        const phrases = ["Buscando el outfit más cool...", "Casi listo...", "Un momento mágico...", "Encontrando las mejores combinaciones...", "Preparando tu look perfecto...", "Creando magia en tu armario..."];
        let phraseIndex = 0;

        const intervalId = setInterval(() => {
            setLoadingPhrase(phrases[phraseIndex]);
            phraseIndex = (phraseIndex + 1) % phrases.length;
        }, 3000); // Change phrase every 3 seconds

        return () => clearInterval(intervalId);
    }, []);

    const handleSearchImgage = async (fileToUpload) => {
        // if (auth.currentUser.uid) {
            setSortOrder('relevant');
            setLoading(true);
            try {
                if (fileToUpload) {
                    console.log(fileToUpload);
                    const storageRef = ref(storage, `uitems/${uuidv4()}`);
                    const uploadTask = uploadBytesResumable(storageRef, fileToUpload);
                    await uploadTask.on('state_changed', 
                        (snapshot) => {
                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                        },
                        (error) => {
                            console.log(error);
                        }, 
                        async () => {
                            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                            functionsExecute(downloadURL, 'image_search');                  
                        }
                    );
                } else {
                    console.log('Image file is null');
                    setLoading(false);
                }
            }
            catch (error) {
                console.error("Error during the upload or Firestore save operation: ", error);
                setLoading(false);
                setFoundError(true);
                setError("Error during the upload or Firestore save operation: ", error);
            }
        // } else {
        //     setSigninModal(true);
        // }
    }

    // Function to separate types of functions
    const handleSearch = async (e) => {
        e.preventDefault();
        let searchText = '';
        setSortOrder('relevant');
        // Check if the event is triggered from the form submission
        if (e.target.tagName === 'FORM') {
            searchText = e.target.elements[0].value; // Assuming the first element is the input
        } else if (e.target.tagName === 'INPUT') {
            // If the event is triggered by onKeyDown on the input
            searchText = e.target.value;
        }
    
        setUserImage(null);
        setItemList(null);
    
        if (searchText) {
            if (searchText.startsWith('https://')) {
                // Handle URL search
                functionsExecute(searchText, 'url_search');
                let newImage = {
                    item_image: searchText,
                    item_name: 'Uploaded Item'
                };
                setUserImage(newImage);
            } else {
                // Handle text search
                functionsExecute(searchText, 'text_search');
            }
        }
    }

    // Main function for backend functions.
    const functionsExecute = async (parmToSearch, type) => {
        setFoundError(false);
        setError('');
        setLoading(true);
        if (parmToSearch) {
            try {
                // const result = await searchMagic({ user_search_parm: parmToSearch, gender: genderSearch });                
                const result = await searchMagic({ user_search_parm: parmToSearch, gender: genderSearch, clothing_type: clothingType });                
                const data = result.data;
                console.log('Data:', data);
                logEvent(analytics, type);
                const uploadItem = {
                    'type': data.type,
                    'color': data.color,
                    'pattern': data.pattern,
                    'style': data.style,
                    'id': data.id,
                    'query_id': data.query_id,
                    'wearly_item_id': data.wearly_item_id,
                };
                setUploadData(uploadItem);
                setItemList(data.results.filter(item => item.item_image !== null && item.item_image !== ""));
                setLoading(false);
                if (data.image_url) {
                    let newImage = {
                        item_image: data.image_url,
                        item_name: 'Uploaded Item'
                    };
                    setUserImage(newImage);
                } else {
                    setUserImage(null);
                }
                sessionStorage.setItem('itemList', JSON.stringify(data.results));
                const currentUrl = new URL(window.location);
                currentUrl.searchParams.set('query', data.query_id);
                window.history.pushState({}, '', currentUrl);
            } catch (error) {
                const code = error.code;
                const message = error.message;
                const details = error.details;
                console.log('Error:', code + message + details);
                setLoading(false);
                setFoundError(true);
                setError(error.message);
            }
        } else {
            console.log('Search term is null');
            setLoading(false);
        }
    }

    const handleFileChange = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            handleSearchImgage(file);
            setFoundError(false);
            setError('');
    
            let newImage = {
                item_image: URL.createObjectURL(file),
                item_name: 'Uploaded Item'
            };

            setUserImage(newImage);
            
        } else {
            console.log('No file selected');
        }
    };

    const handleSuggestedSearchClick = async (suggestion) => {
        setLoading(true);
        setTextSearch(suggestion.query_input);
        const suggestion_query_id = suggestion.query_id;
        const result = await getItemsSuggestedSearch({ query_id: suggestion_query_id });
        const data = result.data;
        const filteredItemList = data.filter(item => item.item_image !== null && item.item_image !== "");
        setItemList(filteredItemList);
        setLoading(false);
        // Cache the itemList in sessionStorage
        const currentUrl = new URL(window.location);
        currentUrl.searchParams.set('query', suggestion_query_id);
        window.history.pushState({}, '', currentUrl);
        sessionStorage.setItem('itemList', JSON.stringify(data));
    }

    const handleStyleClick = async (style) => {
        setLoading(true);
        setTextSearch(style);
        const result = await getItemsByStyle({ style: style });
        const data = result.data;
        const filteredItemList = data.filter(item => item.item_image !== null && item.item_image !== "");
        setItemList(filteredItemList);
        setLoading(false);
        if (data.length === 0) {
          notifyNoItems();
        }
        // Cache the itemList in sessionStorage
        sessionStorage.setItem('itemList', JSON.stringify(data));
      };


    // TODO: - Make item page!
    const handleItemClick = async (item) => {
        console.log('Item:', item);
        // Check if wearly_item_id exists, fallback to another property (e.g., id) if not
        const itemId = item.wearly_item_id || item.id || item.uid;
        console.log('Item:', itemId);

        if (!itemId) {
            console.error('Item ID is undefined');
            return;
        }
        const urlParams = new URLSearchParams(window.location.search);
        const queryId = urlParams.get('query');
        console.log('Query ID:', queryId);
        if (queryId) {
            navigate(`/product/${itemId}?query=${queryId}`);
        } else {
            navigate(`/product/${itemId}`);
        }

        try {
            const result = await saveQueryClick({ item_id: item.uid, query_id: uploadData.query_id });
            const data = result.data;
        }
        catch (error) {
            const code = error.code;
            const message = error.message;
            const details = error.details;
            console.log('Error:', code + message + details);
        }
    }
    
    const handleUGChange = (event, fieldName) => {
        const value = event.target.value;
        setUploadData({ ...uploadData, [fieldName]: value });
        db.collection('uitems').doc(uploadData.id).update({ [fieldName]: value })
            .then(() => console.log('Document successfully updated!'))
            .catch((error) => console.error('Error updating document: ', error));
    };

    const handleEmail = (e) => {
        e.preventDefault();
        const email = e.target[0].value;
        if (email) {
            searchSignup({ email: email })
            .then((result) => {
                const data = result.data;
                logEvent(analytics, data.code);
                
                if (data.code === 'login_link') {
                    // Email Link Sent
                } else if (data.code == 'waitlisted') {
                    // Waitlisted
                } else if (data.code == 'login_link_w_code') {
                    // Email Link Sent
                }
                setLoading(false);
                setSigninModal(false);
            })
            .catch((error) => {
                const code = error.code;
                const message = error.message;
                const details = error.details;
                console.log('Error:', code + message + details);
                setSigninModal(false);
                setLoading(false);
                setFoundError(true);
                setError(error);
            });
        }
    }

    const handleLogout = () => {
        auth.signOut()
          .then(() => {
            // Log out successful
          })
          .catch((error) => {
            // Handle error
          });
      };

    const handleCodeShare = (code) => {
        const shareUrl = `https://wearlyapp.com?invite=${code}`
        navigator.clipboard.writeText(shareUrl).then(() => {
            
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
            file,
            224,
            224,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
            );
    });

    const numberFormat = (value) =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    }).format(value);

    const clearSearchClick = () => {
        setTextSearch('');
        setItemList(null);
        setUserImage(null);
        // Clear the itemList from sessionStorage
        sessionStorage.removeItem('itemList');
        setError('');
        setFoundError(false);
        setLoading(false);
        // remove query from URL
        const currentUrl = new URL(window.location);
        currentUrl.searchParams.delete('query');
        window.history.pushState({}, '', currentUrl);
        setSortOrder('relevant');
    }

    useEffect(() => {
        const handleBeforeUnload = () => {
            sessionStorage.clear(); // This clears the entire sessionStorage
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const handleRelevantOrder = () => {
        setSortOrder('relevant');
        setCurrentPage(1);
    };
    
    const handleLowestPriceOrder = () => {
        setSortOrder('lowestPrice');
        setCurrentPage(1);
    };
    
    const handleHighestPriceOrder = () => {
        setSortOrder('highestPrice');
        // change page to 1
        setCurrentPage(1);
    };

    const handleShowMoreMenu = () => {
        setShowMoreMenu(!showMoreMenu);
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 750) {
                setShowMoreMenu(true);
                setShowSortIcon(false);
                setShowSortOption(true);
            } else {
                setShowMoreMenu(false);
                setShowSortIcon(true);
                setShowSortOption(false);
            }
        };

        window.addEventListener('resize', handleResize);
        // Set initial state based on window size
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Function to handle clicking on a menu item
    const toggleDropdown = (item) => {
        if (activeItem === item) {
            setActiveItem(null); // Close dropdown if it's already open
        } else {
            setActiveItem(item); // Open dropdown for the clicked item
        }
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const closeDropdown = (event) => {
            if (!event.target.closest('.footer-links')) {
                setActiveItem(null);
            }
        };

        document.addEventListener('click', closeDropdown);
        return () => document.removeEventListener('click', closeDropdown);
    }, []);

    useEffect(() => {
        const updateDropdownPosition = () => {
            const dropdowns = document.querySelectorAll('.hover-options');
            dropdowns.forEach(dropdown => {
                let rect = dropdown.getBoundingClientRect();
                if (rect.right > window.innerWidth) {
                    dropdown.style.left = `calc(100% - ${rect.width}px)`;
                    dropdown.style.transform = 'translateX(0)';
                }
            });
        };
    
        window.addEventListener('resize', updateDropdownPosition);
        updateDropdownPosition();
    
        return () => window.removeEventListener('resize', updateDropdownPosition);
    }, []);

    const handleLikeClick = async (item) => {
        // Ensure userLikedItems is an array before using it
        const likedItems = userLikedItems || [];
        console.log('likedItems', likedItems); // or the relevant variable in other parts of the code
        toast.success('Agregaste ' + item.item_name + ' a tu lista de deseos!');
        if (likedItems.includes(item.uid)) {
            const updatedLikedItems = likedItems.filter(likedItem => likedItem !== item.uid);
            setUserLikedItems(updatedLikedItems);
            await updateDoc(doc(db, "users", auth.currentUser.uid), {
                wishlist: arrayRemove(item.uid)
            });
        } else {
            const updatedLikedItems = [...likedItems, item.uid];
            setUserLikedItems(updatedLikedItems);
            await updateDoc(doc(db, "users", auth.currentUser.uid), {
                wishlist: arrayUnion(item.uid)
            });
        }
    };

    const getUnusedCode = () => {
        const unusedCodes = codes.filter(code => code.used === false);
        if (unusedCodes.length > 0) {
            return unusedCodes[0].code;
        }
        return null;
    };

    const handleShare = () => {
        const unusedCode = getUnusedCode();
        if (unusedCode) {
            const link = `https://wearlyapp.com/invite?invite_code=${unusedCode}&friend=${user.first_name}`;
            navigator.clipboard.writeText(link);
            toast.success('Link copiado al portapapeles!');
        } else {
            toast.error('No hay códigos de invitación disponibles.');
        }
    };

    return (
        <div style={{ overflowX: 'hidden' }}>
            <ToastContainer />
            <div className="content" style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
                {showMoreMenu &&
                    <footer className="footer" style={{margin: '0px', padding: '0px', display: 'flex', justifyContent: 'space-between'}}>
                        <ul className="footer-links" style={{textAlign: 'left', alignItems: 'left', marginLeft: '0px'}}>
                            <li style={{marginLeft: '0px', cursor: 'pointer'}}><a href="/">Home</a></li>
                            {/* <li style={{ marginLeft: '0px', cursor: 'pointer' }} onMouseEnter={() => setActiveItem('brands')} onMouseLeave={() => setActiveItem(null)}>
                                <a>Marcas Destacadas</a>
                                {activeItem === 'brands' && (
                                    <div>
                                        <ul className="hover-options">
                                            <li className="hover-button"><a>NXBO</a></li>
                                            <li className="hover-button"><a>Tranca</a></li>
                                            <li className="hover-button"><a>El del Barrio Chino</a></li>
                                        </ul>
                                    </div>
                                )}
                            </li>
                            <li style={{ marginLeft: '0px', cursor: 'pointer' }} onMouseEnter={() => setActiveItem('categories')} onMouseLeave={() => setActiveItem(null)}>
                                <a>Categorias</a>
                                {activeItem === 'categories' && (
                                    <div>
                                        <ul className="hover-options">
                                            <li className="hover-button"><a>Mas Vendidos</a></li>
                                            <li className="hover-button"><a>Nuevos</a></li>
                                        </ul>
                                        <ul className="hover-options">
                                            <li className="hover-button"><a>Remeras</a></li>
                                            <li className="hover-button"><a>Camperas</a></li>
                                            <li className="hover-button"><a>Pantalones</a></li>
                                        </ul>
                                    </div>
                                )}
                            </li>
                            <li style={{ marginLeft: '0px', cursor: 'pointer' }} onMouseEnter={() => setActiveItem('collections')} onMouseLeave={() => setActiveItem(null)}>
                                <a>Colecciones</a>
                                {activeItem === 'collections' && (
                                    <ul className="hover-options">
                                        <li className="hover-button"><a>Streetwear</a></li>
                                        <li className="hover-button"><a>Vintage</a></li>
                                        <li className="hover-button"><a>Sneakers</a></li>
                                    </ul>
                                )}
                            </li> */}
                        </ul>
                        {window.innerWidth > 750 &&
                            <ul className="footer-links" style={{textAlign: 'right', alignItems: 'right', marginRight: '0px'}}>
                                <li style={{marginRight: '0px', fontStyle: 'italic', textDecoration: 'underline'}}><a href="/wishlist">Lista de Deseos</a></li>
                                <li style={{marginRight: '0px', fontStyle: 'italic', textDecoration: 'underline'}}><a href="/profile-settings">Perfil</a></li>
                            </ul>
                        }
                    </footer>
                }

                <div className='mobile-navbar'>
                    <div className='mobile-navbar-cart'>
                        <button className='navbar-more-button' onClick={() => handleShowMoreMenu()}>
                            <MoreIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
                        </button>
                    </div>
                    <div className='mobile-navbar-logo'>
                        <div className='logo' style={{width:'140px', height:'60px'}}>
                            <img src={Logo} onClick={() => clearSearchClick()} alt="Logo" className='logo-img' style={{ width: '100%', height: '100%', objectFit: 'contain', padding:'10%' }} />
                        </div>
                    </div>
                    <div className='navbar-profile'>
                        <button className='navbar-profile-button' onClick={() => navigate('/profile-settings')}>
                            <ProfileIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
                        </button>
                    </div>
                    <div className='navbar-heart'>
                        <button className='navbar-heart-button' onClick={() => navigate('/wishlist')}>
                            <HeartHallowIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
                        </button>
                    </div>
                    <div className='mobile-navbar-cart'>
                        <button className='navbar-cart-button' onClick={() => navigate('/cart')} style={{ position: 'relative' }}>
                            {user && user.user_cart && Object.keys(user.user_cart).length > 0 && (
                                <span style={{ 
                                    position: 'absolute', 
                                    top: '-8px', 
                                    right: '-8px', 
                                    background: 'red', 
                                    color: 'white', 
                                    borderRadius: '50%', 
                                    width: '18px', 
                                    height: '18px', 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    fontSize: '12px',
                                    zIndex: 1,
                                    lineHeight: '18px', // Added to ensure vertical centering
                                    textAlign: 'center', // Added to ensure horizontal centering
                                    padding: '0' // Added to remove any potential padding
                                }}>
                                    {Object.keys(user.user_cart).length}
                                </span>
                            )}
                            <CartIcon style={{ width: '20px', height: '20px', fill: 'black' }} />
                        </button>
                    </div>
                </div>
                <div className="navbar">
                    <div className="navbar-logo">
                        <div className="logo" style={{width:'110px', height:'70px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img src={Logo} onClick={() => clearSearchClick()} alt="Logo" className='logo-img' style={{ width: '80%', height: '80%', objectFit: 'contain', padding:'10%' }} />
                        </div>
                    </div>
                    <div className="navbar-search">
                        <form onSubmit={handleSearch} style={{ display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                            <div className='input-wrapper' style={{ width: '100%', height: '50px', position: 'relative'}}>
                                <input
                                    type="text"
                                    placeholder={placeholder}
                                    value={textSearch}
                                    className='search-bar'
                                    required
                                    onChange={e => setTextSearch(e.target.value)}
                                    onKeyDown={(e) => { if (e.key === 'Enter') { handleSearch(e); } }} // Use Enter key to submit
                                    style={{ 
                                        fontFamily: 'Raleway', 
                                        fontWeight:'700',
                                        height: '100%', 
                                        padding: '10px',
                                        borderRadius: '15px', 
                                        paddingLeft: '50px', // Adjusted paddingLeft to make space for the icon
                                        paddingRight: '20px', // Adjusted paddingRight
                                        boxSizing: 'border-box',
                                        animation: 'fadeEffect 1.5s' // CSS animation for fade effect
                                    }}
                                />
                                <div className="search-icon" style={{ position: 'absolute', left: '20px', top: '50%', transform: 'translateY(-50%)'}}>
                                    <SearchIcon style={{ width: '20px', height: '20px', color: '#C4C4C4' }} />
                                </div>
                                {/* <div className="settings-icon-landing" style={{
                                    position: 'absolute',
                                    right: '20px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: '50%',
                                    width: '40px',
                                    height: '40px',
                                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%',
                                        cursor: 'pointer'
                                    }} onClick={() => setShowSearchSettingsModal(true)}>
                                        <SettingsIcon style={{ width: '15px', height: '15px' }} />
                                    </div>
                                </div> */}
                            </div>
                            {/* Removed the button to make the form submit with Enter key press */}
                        </form>
                    </div>
                    <div className="navbar-cart">
                        <button className="navbar-cart-button" onClick={() => navigate('/cart')}>
                            CART ({user && user.user_cart ? Object.keys(user.user_cart).length : 0})
                        </button>
                    </div>
                </div>
                {recomendedSearches && recomendedSearches.length > 0 && <h3 style={{fontFamily: 'Raleway', fontWeight: '900', marginTop: '20px', marginBottom: '20px'}}>Trending: Lo mas buscado</h3>}
                <div className="recent-searches" style={{ marginTop: '20px',display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap', width: '100%', scrollbarWidth: 'none', WebkitOverflowScrolling: 'touch', MsOverflowStyle: 'none', '&::-webkit-scrollbar': { display: 'none' } }}>
                    {recomendedSearches && recomendedSearches.map((search, index) => (
                        <button 
                            key={index}
                            style={{ 
                                margin: '0 10px', 
                                marginLeft: index === 0 ? '0' : '10px',
                                fontFamily: 'Raleway', 
                                fontWeight: '700', 
                                color: 'white', 
                                height: '42px',
                                background: 'black', 
                                border: '1px solid white', 
                                borderRadius: '10px',
                                padding: '10px 20px',
                                cursor: 'pointer',
                                whiteSpace: 'nowrap',
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            onClick={() => handleSuggestedSearchClick(search)}
                        >
                            <TredningIcon style={{ width: '15px', height: '15px', marginRight: '15px' }} />
                            <span>{search.query_input.charAt(0).toUpperCase() + search.query_input.slice(1)}</span>
                        </button>
                    ))}
                </div>
                    <div style={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center', 
                    height: '80px', 
                    background: 'linear-gradient(90deg, #000000 58%, #666666 100%)',
                    padding: '0 20px',
                    borderRadius: '15px',
                    margin: '20px 0'
                }}>
                    <div style={{ color: 'white' }}>
                        <div style={{ fontSize: 'clamp(10px, 3.3vw, 18px)', fontWeight: 'bold' }}>Compartí Wearly y probate ropa!</div>
                        <div style={{ fontSize: 'clamp(4px, 2vw, 14px)' }}>Por cada persona que se una con tu link podes probarte 2 prendas con Inteligencia Artificial.</div>
                    </div>
                    <button 
                        onClick={handleShare}
                        style={{
                            background: 'transparent',
                            color: 'white',
                            border: '0px solid white',
                            borderRadius: '5px',
                            padding: '10px 20px',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            fontSize: '14px'
                        }}
                    >
                        Compartir
                        </button>
                </div>
                <div style={{textAlign:'center'}} className='content-wrapper'>
                    {foundError &&
                        <p style={{ display: 'flex', fontSize:'14px', alignItems: 'center', justifyContent:'center', color:'red', marginTop:'2%'}}>{error}</p>
                    }
                    
                    {itemList && itemList.length > 0 && 
                        <div>
                            <div style={{ textAlign: 'left', display: 'flex', marginTop: '2%' }}>
                                <h2 className='section-title' style={{ fontFamily: 'Raleway', fontWeight: '900' }}>Resultados</h2>
                            </div>
                            <div className="order-buttons-container" style={{ justifyContent: 'space-between', overflowX: 'auto' }}>
                                {showSortIcon &&
                                    <button style={{margin: '10px', display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:'transparent', border:'none', cursor:'pointer'}} onClick={() => setShowSortOption(!showSortOption)}>
                                        <SortIcon alt="Filter" style={{ width: '20px', height: '20px', fill: 'black' }} />
                                    </button>
                                }
                                {showSortOption &&
                                    <div style={{display:'flex'}}>
                                        <button 
                                            className="order-button" 
                                            onClick={() => handleRelevantOrder()} 
                                            style={{ textDecoration: sortOrder === 'relevant' ? 'underline' : 'none' }}
                                        >
                                            Recomendado
                                        </button>
                                        <button 
                                            className="order-button" 
                                            onClick={() => handleLowestPriceOrder()} 
                                            style={{ textDecoration: sortOrder === 'lowestPrice' ? 'underline' : 'none', whiteSpace: 'nowrap' }}
                                        >
                                            Más Barato
                                        </button>
                                        <button 
                                            className="order-button" 
                                            onClick={() => handleHighestPriceOrder()} 
                                            style={{ textDecoration: sortOrder === 'highestPrice' ? 'underline' : 'none', whiteSpace: 'nowrap' }}
                                        >
                                            Más Caro
                                        </button>
                                    </div>
                                }
                                <button  className='share-button' onClick={() => {
                                    navigator.clipboard.writeText(window.location.href);
                                    setShareButtonText('Copied Link!');
                                    setTimeout(() => setShareButtonText('Share Search'), 3000);
                                }}>
                                    <span className='share-button-label'>{shareButtonText || 'Share Search'}</span>
                                </button>
                            </div>
                            <div className="store-container">
                                {userImage && userImage.item_image != null && (
                                    <div>
                                        <div className="store-item">
                                            <img src={userImage.item_image} alt="user image" className="store-item-img" />
                                        </div>
                                        <div className="store-item-text" style={{ textAlign: 'left' }}>
                                            <h2 style={{ fontSize: '14px' }}>{userImage.item_name}</h2>
                                        </div>
                                    </div>
                                )}
                                {currentItems
                                    .slice()
                                    .sort((a, b) => {
                                        const priceA = a.product_promotional_price && a.product_promotional_price !== 0 ? a.product_promotional_price : a.product_price;
                                        const priceB = b.product_promotional_price && b.product_promotional_price !== 0 ? b.product_promotional_price : b.product_price;

                                        if (sortOrder === 'lowestPrice') {
                                            return priceA - priceB;
                                        } else if (sortOrder === 'highestPrice') {
                                            return priceB - priceA;
                                        }
                                        return 0;
                                    })
                                    .map((item, index) => (
                                        <div
                                            key={index}
                                            onClick={() => handleItemClick(item)}
                                            className='store-item-wrapper'
                                            style={{ cursor: 'pointer', padding: '10px', boxSizing: 'border-box', border: '1px solid #E0E0E0', borderRadius: '15px', margin: '10px' }}
                                        >
                                            <div className="store-item">
                                                <img src={item.item_image} alt={item.item_name} className="store-item-img" />
                                                <div className="new-tag">NEW</div>
                                                <button className="like-button" onClick={(e) => {
                                                    e.stopPropagation(); // Prevents the click from triggering other click events
                                                    handleLikeClick(item);
                                                }}>
                                                    {userLikedItems && userLikedItems.includes(item.uid) ? 
                                                        <HeartFilledIcon style={{ width: '20px', height: '20px', fill: 'red' }} /> : 
                                                        <HeartHallowIcon style={{ width: '20px', height: '20px' }} />
                                                    }
                                                </button>
                                            </div>
                                            <div className="store-item-text" style={{ textAlign: 'left' }}>
                                                <div style={{ display: 'flex', justifyContent: 'left'}}>
                                                    <h2 style={{ fontSize: '10px', color: 'grey', fontWeight: '9000' }}>
                                                        {item.item_brand ? item.item_brand.toUpperCase() : 'UNKNOWN BRAND'}
                                                    </h2>
                                                </div>
                                                <h2 style={{ fontSize: '14px', textTransform: 'capitalize' }}>{item.item_name}</h2>
                                                <div className="price-container" style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {item.product_promotional_price ? (
                                                        <>
                                                            <div style={{textAlign: 'left', width: '100%', marginBottom: '5px'}} className="price-line original-price">{numberFormat(item.product_price)}</div>
                                                            <div style={{textAlign: 'left', width: '100%', marginBottom: '5px'}} className="price-line promotional-price">{numberFormat(item.product_promotional_price)}</div>
                                                            <div style={{textAlign: 'left', width: '100%', marginBottom: '5px'}} className="price-line discount-percentage">
                                                                {`(${Math.round(((item.product_price - item.product_promotional_price) / item.product_price) * 100)}% OFF)`}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <h3 style={{textAlign: 'left', width: '100%', marginBottom: '5px'}}>{numberFormat(item.product_price)}</h3>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            <div className="pagination" style={{ fontFamily: 'Raleway', fontWeight: '900', marginTop: '4%', textAlign: 'left' }}>
                                {currentPage > 1 && (
                                    <button 
                                        className="pagination-button"
                                        onClick={() => {
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                            paginate(currentPage - 1);
                                        }}
                                        style={{ textAlign: 'left' }}
                                    >
                                        &lt;
                                    </button>
                                )}
                                {displayPageNumbers[0] > 1 && (
                                    <button 
                                        className="pagination-button"
                                        onClick={() => {
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                            paginate(1);
                                        }}
                                        style={{ textAlign: 'left' }}
                                    >
                                        1
                                    </button>
                                )}
                                {displayPageNumbers[0] > 2 && <span className="pagination-ellipsis" style={{ textAlign: 'left' }}>...</span>}
                                {displayPageNumbers.map(number => (
                                    <button
                                        key={number}
                                        className={`pagination-button ${currentPage === number ? 'active' : ''}`}
                                        onClick={() => {
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                            paginate(number);
                                        }}
                                        style={{ textAlign: 'left' }}
                                    >
                                        {number}
                                    </button>
                                ))}
                                {displayPageNumbers[displayPageNumbers.length - 1] < totalPages - 1 && <span className="pagination-ellipsis" style={{ textAlign: 'left' }}>...</span>}
                                {displayPageNumbers[displayPageNumbers.length - 1] < totalPages && (
                                    <button 
                                        className="pagination-button"
                                        onClick={() => {
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                            paginate(totalPages);
                                        }}
                                        style={{ textAlign: 'left' }}
                                    >
                                        {totalPages}
                                    </button>
                                )}
                                {currentPage < totalPages && (
                                    <button 
                                        className="pagination-button"
                                        onClick={() => {
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                            paginate(currentPage + 1);
                                        }}
                                        style={{ textAlign: 'left' }}
                                    >
                                        &gt;
                                    </button>
                                )}
                            </div>
                        </div>
                    }
                </div>
                {(!itemList || itemList.length === 0) && 
                    <div>
                        <div className="content-main-ad" style={{marginTop: '3%', marginLeft: '-20px', marginRight: '-20px', position: 'relative'}}>
                            <div className="content-main-ad-image" style={{ backgroundSize: 'cover', backgroundPosition: 'center', height:'380px' }}>
                                <video 
                                    autoPlay 
                                    loop 
                                    muted 
                                    playsInline 
                                    webkit-playsinline="true"
                                    preload="true"
                                    poster={require("../Assets/main-home-video.png")}
                                    style={{
                                        position: 'absolute', 
                                        width: '100%', 
                                        height: '100%', 
                                        objectFit: 'cover', 
                                        zIndex: -1
                                    }}
                                    ref={(videoElement) => {
                                        if (videoElement) {
                                            videoElement.play().catch(error => {
                                                console.error("Error attempting to play video:", error);
                                            });
                                        }
                                    }}
                                >
                                    <source src={require("../Assets/main-home-video.mp4")} type="video/mp4" />
                                </video>
                                <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)', zIndex: 0}}></div>
                                <div style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '40%',
                                    transform: 'translate(-50%, -50%)', // Adjusted for better centering
                                    textAlign: 'center',
                                    zIndex: 1,
                                    fontFamily: 'Raleway',
                                    fontWeight: '900',
                                    color: 'white',
                                }}>
                                    <span style={{ display: 'block', textAlign: 'left', fontSize: '20px', fontFamily: 'Raleway', fontWeight: '900' }}>
                                        DESCRIBI COMO QUERES VESTIRTE Y
                                    </span>
                                    <span style={{ display: 'block', textAlign: 'left', fontSize: '28px', fontFamily: 'Raleway', fontWeight: '900' }}>
                                        ENCONTRA TU OUTFIT PERFECTO 🔥
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div>
                            {/* {suggestedOutfit && suggestedOutfit.length > 0 &&
                                <div>
                                    <h2 className='section-title' style={{color:'black', textAlign:'left', marginTop:'4%', fontFamily: 'Raleway', fontWeight: '900'}}>OUTFIT PARA VOS</h2>
                                    <div className='suggestions-container' style={{ display: 'flex', overflowX: 'scroll', scrollbarWidth: 'none' }}>
                                        {suggestedOutfit.map((outfit, index) => (
                                            <div key={index} className='outfit-group' style={{ display: 'flex', marginRight: '25px' }} onClick={() => handleItemClick(outfit)}>
                                                <div className='suggestion-item' style={{ marginRight: '10px' }}>
                                                    <img src={outfit.tops.item_image} alt={outfit.tops.item_name} className='suggestion-item-img' style={{ width: '220px', height: '220px', objectFit: 'cover', borderRadius: '25px' }} />
                                                </div>
                                                <div className='suggestion-item' style={{ marginRight: '10px' }}>
                                                    <img src={outfit.bottoms.item_image} alt={outfit.bottoms.item_name} className='suggestion-item-img' style={{ width: '220px', height: '220px', objectFit: 'cover', borderRadius: '25px' }} />
                                                </div>
                                                <div className='suggestion-item'>
                                                    <img src={outfit.shoes.item_image} alt={outfit.shoes.item_name} className='suggestion-item-img' style={{ width: '220px', height: '220px', objectFit: 'cover', borderRadius: '25px' }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            } */}
                            {recomendedItems && recomendedItems.length > 0 &&
                                <div>
                                    <h2 className='section-title' style={{color:'black', textAlign:'left', marginTop:'4%', fontFamily: 'Raleway', fontWeight: '900'}}>Para tu estilo</h2>
                                    <div className='suggestions-container' style={{ display: 'flex', overflowX: 'scroll', scrollbarWidth: 'none' }}>
                                        {recomendedItems && recomendedItems.map((item, index) => (
                                            item.item_image != null && (
                                                <div key={index} className='suggestion-item' style={{ marginRight: '25px' }} onClick={() => handleItemClick(item)}>
                                                    <img src={item.item_image} alt={item.item_name} className='suggestion-item-img' style={{ width: '220px', height: '220px', objectFit: 'cover', borderRadius: '25px' }} />
                                                </div>
                                            )
                                        ))}
                                    </div>
                                </div>
                            }
                            {suggestedStyles && suggestedStyles.length > 0 && (
                                <div>
                                    <h2 className='section-title' style={{color:'black', textAlign:'left', marginTop: '4%', fontFamily: 'Raleway', fontWeight: '900'}}>Explora estos Estilos</h2>
                                    <div className="styles-images" style={{ 
                                        display: 'flex', 
                                        overflowX: 'scroll',
                                        overflowY: 'hidden', 
                                        marginTop: '4%', 
                                        scrollbarWidth: 'none',
                                        msOverflowStyle: 'none',
                                        }}>
                                        {suggestedStyles.map((style, index) => {
                                            const styleLowerCase = style.toLowerCase();
                                            const profileImage = require(`../Assets/${styleLowerCase}-profile-image.png`);
                                            return (
                                                <div key={index} className="style-image" style={{ 
                                                    backgroundImage: `url(${profileImage})`, 
                                                    backgroundSize: 'cover', 
                                                    backgroundPosition: 'center', 
                                                    borderRadius: '50%',
                                                    height: '180px', 
                                                    width: '180px', 
                                                    flex: '0 0 auto',
                                                    display: 'flex', 
                                                    justifyContent: 'center', 
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                    marginRight: '10px',
                                                    marginLeft: index === 0 ? '0' : '4%',
                                                    }} onClick={() => handleStyleClick(style)}>
                                                    <h3 style={{
                                                        color:'white', 
                                                        textAlign:'center', 
                                                        fontFamily: 'Raleway', 
                                                        fontWeight: '900',
                                                        cursor: 'pointer',
                                                        }} onClick={() => handleStyleClick(style)}>
                                                        {style.toUpperCase()}
                                                    </h3>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                            <div className="side-by-side-container">
                                {newRecomendedItems && newRecomendedItems.length > 0 && (
                                    <div className='new-recomended-items-container' style={{marginTop: '3%'}}>
                                        <div className="section-title-container">
                                            <h2 className='section-title' style={{color:'black', marginBottom:'0%', textAlign:'left', fontFamily: 'Raleway', fontWeight: '900'}}>Nuevos lanzamientos</h2>
                                            <p style={{color:'black', textAlign:'left', marginBottom:'4%', marginTop: '2px', fontFamily: 'Raleway', fontWeight: '900', fontStyle: 'italic'}}></p>
                                        </div>
                                        <div className='new-recomended-items-container-items'>
                                            {newRecomendedItems.map((item, index) => (
                                                item.item_image != null && (
                                                    <div key={index} className="suggestion-item" onClick={() => handleItemClick(item)}>
                                                        <img src={item.item_image} alt={item.item_name} className='suggestion-item-img' />
                                                    </div>
                                                )
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div>
                                <h2 className='section-title' style={{color:'black', textAlign:'left', marginTop: '4%', fontFamily: 'Raleway', fontWeight: '900'}}>Popping en Argentina</h2>
                                <div className='side-by-side-img-rectangles' style={{display: 'flex', justifyContent: 'space-between', gap: '40px'}}>
                                    <div className='inner-side-by-side' style={{position: 'relative', height: '258px', width: 'calc(50% - 30px)', borderRadius: '20px', cursor: 'pointer'}} onClick={() => handleStyleClick('streetwear')}>
                                        <img src={require('../Assets/streetwear-argentina.webp')} alt='Rectangle 1' style={{height: '100%', width: '100%', borderRadius: '20px', objectFit: 'cover'}} />
                                        <div style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <h1 style={{color: 'white', textAlign: 'center', fontWeight: 'bold', textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)'}}>Streetwear en Argentina</h1>
                                        </div>
                                    </div>
                                    <div className='inner-side-by-side' style={{position: 'relative', height: '258px', width: 'calc(50% - 30px)', borderRadius: '20px', cursor: 'pointer'}} onClick={() => { setTextSearch('baggy fit'); functionsExecute('baggy fit', 'text_search'); }}>
                                        <img src={require('../Assets/baggy-pants.webp')} alt='Rectangle 2' style={{height: '100%', width: '100%', borderRadius: '20px', objectFit: 'cover'}} />
                                        <div style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <h1 style={{color: 'white', textAlign: 'center', fontWeight: 'bold', textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)'}}>Los Mejores Baggy Fit de Argentina</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className='side-by-side-img-rectangles' style={{display: 'flex', justifyContent: 'space-between', gap: '40px', marginTop: '40px'}}>
                                    <div className='inner-side-by-side' style={{position: 'relative', height: '258px', width: 'calc(50% - 30px)', borderRadius: '20px', cursor: 'pointer'}} onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                        <img src={require('../Assets/vestite-como-famoso.webp')} alt='Rectangle 1' style={{height: '100%', width: '100%', borderRadius: '20px', objectFit: 'cover'}} />
                                        <div style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <h1 style={{color: 'white', textAlign: 'center', fontWeight: 'bold', textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)'}}>Vestite como una Celebridad</h1>
                                        </div>
                                    </div>
                                    <div className='inner-side-by-side' style={{position: 'relative', height: '258px', width: 'calc(50% - 30px)', borderRadius: '20px', cursor: 'pointer'}}>
                                        <img src={require('../Assets/shop-preview.webp')} alt='Rectangle 2' style={{height: '100%', width: '100%', borderRadius: '20px', objectFit: 'cover'}} />
                                        <div style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <h1 style={{color: 'white', textAlign: 'center', fontWeight: 'bold', textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)'}}>Encontra las Mejores Marcas</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {recomendedBrands && recomendedBrands.length > 0 && (
                                <div style={{marginBottom: '40px'}}>
                                    <h2 className='section-title' style={{color:'black', textAlign:'left', marginTop: '4%', fontFamily: 'Raleway', fontWeight: '900'}}>Las Mejores Marcas</h2>
                                    <div className="styles-images" style={{ 
                                        display: 'flex', 
                                        overflowX: 'scroll',
                                        overflowY: 'hidden', 
                                        marginTop: '4%', 
                                        scrollbarWidth: 'none',
                                        msOverflowStyle: 'none',
                                    }}>
                                        {recomendedBrands.map((brand, index) => {
                                            const brandName = brand.brand_name.toLowerCase();
                                            const profileImage = brand.brand_profile_image;
                                            return (
                                                <div 
                                                    key={index} 
                                                    className="style-image" 
                                                    style={{ 
                                                        backgroundImage: `url(${profileImage})`, 
                                                        backgroundSize: 'contain', 
                                                        backgroundPosition: 'center', 
                                                        backgroundRepeat: 'no-repeat',
                                                        borderRadius: '50%',
                                                        height: '180px', 
                                                        width: '180px', 
                                                        flex: '0 0 auto',
                                                        display: 'flex', 
                                                        justifyContent: 'center', 
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        marginRight: '10px',
                                                        marginLeft: index === 0 ? '0' : '4%',
                                                        position: 'relative',
                                                    }}
                                                    onClick={() => navigate(`brand/${brand.wearly_brand_id}`)}
                                                >
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        width: '100%',
                                                        height: '100%',
                                                        borderRadius: '50%',
                                                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                                    }}></div>
                                                    <h3 style={{
                                                        color:'white', 
                                                        textAlign:'center', 
                                                        fontFamily: 'Raleway', 
                                                        fontWeight: '900',
                                                        cursor: 'pointer',
                                                        position: 'relative',
                                                        zIndex: 1,
                                                    }}>
                                                        {brandName.toUpperCase()}
                                                    </h3>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                }

                {/* <footer className="footer" style={{margin: '0px', padding: '0px', textAlign: 'left'}}>
                    <ul className="footer-links" style={{textAlign: 'left', alignItems: 'left', marginLeft: '0px'}}>
                        <li style={{marginLeft: '0px'}}><a href="/">Home</a></li>
                        <li style={{marginLeft: '0px'}}><a href="https://wearly-tech.notion.site/Wearly-FAQ-44912421f4684846b815004e31931923?pvs=74">FAQ</a></li>
                        <li style={{marginLeft: '0px'}}><a href="https://brands.wearlyapp.com">Vender</a></li>
                        <li style={{marginLeft: '0px'}}><a href="mailto:contactwearly@gmail.com">Soporte</a></li>
                        <li style={{marginLeft: '0px'}}><a href="/terms">Términos y Condiciones</a></li>
                        <li style={{marginLeft: '0px'}}><a href="/privacy">Política de Privacidad</a></li>
                        <li style={{marginLeft: '0px'}}><a href="/sitemap">Sitemap</a></li>
                    </ul>
                </footer> */}
                <footer className="footer footer-landing" style={{margin: '0px', padding: '0px', textAlign: 'left'}}>
                    <div className="footer-content footer-content-landing">
                        <div className="footer-logo footer-logo-landing">
                            <img src={LogoBlack} alt="Wearly Logo" style={{height: '60px'}} />
                        </div>
                        <ul className="footer-links footer-links-landing">
                            <li><a href="/">Home</a></li>
                            <li><a href="/terms">Términos y Condiciones</a></li>
                            <li><a href="/privacy">Política de Privacidad</a></li>
                            <li><a href="https://wearly-tech.notion.site/Wearly-FAQ-44912421f4684846b815004e31931923?pvs=74">FAQ</a></li>
                            <li><a href="mailto:contactwearly@gmail.com">Soporte</a></li>
                            <li><a href="https://brands.wearlyapp.com">Soy una marca</a></li>
                        </ul>
                        <div className="footer-social footer-social-landing">
                            <a href="https://www.instagram.com/wearlyapp" target="_blank" rel="noopener noreferrer">
                                <InstagramIcon style={{width: '24px', height: '24px', marginRight: '10px'}} />
                            </a>
                            <a href="https://www.tiktok.com/@wearlyapp" target="_blank" rel="noopener noreferrer">
                                <TikTokIcon style={{width: '24px', height: '24px', marginRight: '10px'}} />
                            </a>
                            <a href="https://x.com/wearlyapp" target="_blank" rel="noopener noreferrer">
                                <TwitterIcon style={{width: '24px', height: '24px', marginRight: '10px'}} />
                            </a>
                            <a href="https://www.linkedin.com/company/wearlyai" target="_blank" rel="noopener noreferrer">
                                <LinkedInIcon style={{width: '24px', height: '24px'}} />
                            </a>
                        </div>
                    </div>
                </footer>
                <div className="copyright" style={{margin: '0px', padding: '0px', textAlign: 'left', marginTop: '15px'}}>
                    <p>&copy; {new Date().getFullYear()} Wearly. All rights reserved.</p>
                </div>
            </div>
            {showSearchSettingsModal &&
                <div className="modal" onClick={() => setShowSearchSettingsModal(false)}>
                    <div className="modal-content modal-content-search-settings" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header modal-header-search-settings">
                            <h2>Search Settings</h2>
                            <button className="modal-close-button modal-close-button-search-settings" onClick={() => setShowSearchSettingsModal(false)}>X</button>
                        </div>
                        <div className="modal-body">
                            <div className="modal-section model-section-search-settings">
                                <div className="model-section-search-settings-container">
                                    <label>Select gender</label>
                                    <div className='model-section-search-settings-button-container'>
                                        <button className={genderSearch === 'male' ? 'selected' : ''} onClick={() => setGenderSearch('male')}>Male</button>
                                        <button className={genderSearch === 'female' ? 'selected' : ''} onClick={() => setGenderSearch('female')}>Female</button>
                                        <button className={genderSearch === 'unisex' ? 'selected' : ''} onClick={() => setGenderSearch('unisex')}>Unisex</button>
                                    </div>
                                </div>
                                <div className="model-section-search-settings-container">
                                    <label>Select type of clothing</label>
                                    <div className='model-section-search-settings-button-container'>
                                        <button className={clothingType === 'all' ? 'selected' : ''} onClick={() => setClothingType('all')}>All</button>
                                        <button className={clothingType === 'tops' ? 'selected' : ''} onClick={() => setClothingType('tops')}>Tops</button>
                                        <button className={clothingType === 'bottoms' ? 'selected' : ''} onClick={() => setClothingType('bottoms')}>Bottoms</button>
                                        <button className={clothingType === 'shoes' ? 'selected' : ''} onClick={() => setClothingType('shoes')}>Shoes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {loading && 
                <div className="loading-overlay">
                    <div className="loading-images-container">
                            {currentLoadingImages.map((url, index) => (
                                <div key={index} className="loading-image-wrapper">
                                    <img src={url} alt={`Loading ${index}`} className="loading-image" />
                                </div>
                            ))}
                        </div>
                    <div className="loading-phrase">{loadingPhrase}</div>
                </div>
            }
        </div>
    );
}

export default Home;
