import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';

function PaymentProxy() {
  const { sessionId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const fetchPayment = async () => {
      try {
        const functions = getFunctions();
        const getPaymentSession = httpsCallable(functions, 'getPaymentSession');
        
        const result = await getPaymentSession({ sessionId });
    
        if (result.data && result.data.html) {
          // Create a temporary container
          const tempContainer = document.createElement('div');
          tempContainer.innerHTML = result.data.html;
          
          // Find the form in the container
          const form = tempContainer.querySelector('form');
          
          if (form) {
            // Remove the button from the form
            const button = form.querySelector('button');
            if (button) {
              button.remove();
            }
            
            // Append the form to the body
            document.body.appendChild(form);
            
            // Submit the form automatically
            form.submit();
          } else {
            throw new Error('Form not found in the response');
          }
        } else {
          throw new Error('Invalid response from server');
        }
        
      } catch (error) {
        console.error('Error fetching payment page:', error);
        setIsExpired(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPayment();
  }, [sessionId]);

  if (isLoading) {
    return <div>Redirigiendo al checkout...</div>;
  }

  if (isExpired) {
    return (
      <div className="expired-message">
        <h2>Sesión de Pago Expirada</h2>
        <p>La sesión de pago ha expirado. Por favor, inicie un nuevo proceso de pago.</p>
        <button 
          onClick={() => window.location.href = '/cart'}
          style={{
            backgroundColor: 'black',
            border: 'none',
            color: 'white',
            padding: '8px 10px',
            textAlign: 'center',
            textDecoration: 'none',
            display: 'inline-block',
            fontSize: '16px',
            margin: '4px 2px',
            cursor: 'pointer',
            borderRadius: '4px',
            fontWeight: 'bold'
          }}
        >
          Volver al carrito
        </button>
      </div>
    );
  }

  return null;
}

export default PaymentProxy;