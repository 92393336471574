import React, { useState, useEffect } from 'react';
import '../CSS/SignUp.css';
import Logo from '../../../Assets/wai-logo.png';
import ArrowBackIcon from '../../../Assets/arrow-left-solid.svg';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook for navigation
import { getFunctions, httpsCallable } from "firebase/functions";
import { signInWithEmailLink, isSignInWithEmailLink, signInWithEmailAndPassword } from 'firebase/auth';
import { collection, getDocs, getDoc, query, where, updateDoc, doc, arrayUnion, addDoc, deleteDoc, setDoc, arrayRemove } from 'firebase/firestore';
import { getAnalytics, logEvent } from "firebase/analytics";
import { ReactComponent as ShareIcon } from '../../../Assets/share-solid.svg';
import ConfettiExplosion from 'react-confetti-explosion';
import { db, auth } from '../../../firebase';

const SignUp = () => {
    const navigate = useNavigate();
    const [error, setError] = useState('');    
    const functions = getFunctions();
    const [emailResult, setEmailResult] = useState('');
    const [loading, setLoading] = useState(false);
    const [foundError, setFoundError] = useState(false);
    const [feedbackAsk, setFeedbackAsk] = useState(false);
    const [userCountry, setUserCountry] = useState('');
    const [userWaitlistPosition, setUserWaitlistPosition] = useState(0);
    const [isWaitlistPositionLoading, setIsWaitlistPositionLoading] = useState(true);
    const [code, setCode] = useState('');
    const [refCode, setRefCode] = useState('');
    const analytics = getAnalytics();
    const searchSignup = httpsCallable(functions, 'signup')
    const [fullName, setFullName] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [step, setStep] = useState(1); // New state to manage form step
    const [isSubmitted, setIsSubmitted] = useState(false);
    const handleRefShare = (email) => {
        const shareUrl = `https://wearlyapp.com?ref=${email}`
        navigator.clipboard.writeText(shareUrl).then(() => {
            
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const sharedCode = urlParams.get('invite');
        if (sharedCode) {
            setCode(sharedCode);
        }
        const refCode = urlParams.get('ref');
        if (refCode) {
            setRefCode(refCode);
        }
    }, []);

    const handleFeedback = (e) => {
        setFeedbackAsk(false);
        e.preventDefault();
        if (!email) {
            return;
        }
        const brand = e.target[0].value;
        const reason = e.target[1].value;
        const feedback = { brand: brand, reason: reason, email: email};
        addDoc(collection(db, 'feedback'), feedback)
        .then((result) => {
            console.log(result);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (step === 1) {
            setStep(2);
        } else if (step === 2) {
            setStep(3);
        } else {
            setLoading(true);
            setError('');
            setFoundError(false);
            setEmailResult('');
            if (email) {
                handleSignup();
                setIsSubmitted(true);
            } else {
                setLoading(false);
                setError('Please enter both email and password');
            }
        }
    }

    const handleSignup = () => {
        searchSignup({ email, code, country: userCountry, refCode , fullName, idNumber })
            .then((result) => {
                const data = result.data;
                handleSignupResult(data);
            })
            .catch((error) => {
                console.log('Error:', error.code, error.message, error.details);
                setLoading(false);
                setFoundError(true);
                setError(error.message);
            });
    }

    const handleSignupResult = (data) => {
        switch(data.code) {
            case 'login_link':
            case 'login_link_w_code':
                setEmailResult(data.code === 'login_link' ? 'Bienvenido devuelta! Te enviamos un link a tu email.' : 'Invitacion aceptada. Te enviamos un link a tu email!');
                window.localStorage.setItem('emailForSignIn', email);
                break;
            case 'waitlisted':
            case 'waitlisted_w_code':
                setEmailResult(data.code === 'waitlisted' ? 'Gracias! Estas en la lista de espera.' : 'El codigo ha sido usado anteriormente, ahora estas en la lista de espera.');
                setUserWaitlistPosition(data.waitlist_position);
                setIsWaitlistPositionLoading(false);
                setFeedbackAsk(true);
                break;
            default:
                setEmailResult('An unexpected error occurred');
        }
        logEvent(analytics, data.code);
        setLoading(false);
    }

    const goBack = () => {
        if (step === 1) {
            navigate(-1);
        } else {
            setStep(step - 1);
        }
    };

    return (
        <div className='login-container-video'>
            <video autoPlay loop muted playsInline style={{position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover', zIndex: -1}}>
                <source src={require("../../../Assets/background-video-1.mp4")} type="video/mp4" />
            </video>
            <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 0}}></div>
            <div className="login-container">
                <button className="back-button-login" onClick={goBack}>
                    <img src={ArrowBackIcon} alt="Back" />
                </button>
                <div className="login-card">
                    <div className="logo-container-login" style={{width: '100%'}}>
                        <img src={Logo} alt="Logo" className='logo' style={{objectFit: 'contain', width: '170px', height: '170px'}} />
                    </div>
                    {isSubmitted ? (
                        <div className="result-container">
                            <ConfettiExplosion />
                            <h2>{emailResult}</h2>
                        </div>
                    ) : (
                        <>
                        <h1 style={{textAlign: 'left', fontFamily: 'Raleway', fontWeight: 'bold'}}>Unite a Wearly</h1>
                        <p style={{textAlign: 'left', fontFamily: 'Raleway', fontWeight: 'bold', marginBottom: '10px', marginRight: '5%'}}>Crea en tu cuenta.</p>
                        <form onSubmit={handleSubmit}>
                            {step === 1 ? (
                                <div className="input-group" style={{marginBottom: '30px'}}>
                                    <input type="text" className='input-login' placeholder="Nombre Completo" required style={{fontFamily: 'Raleway', fontWeight: 'bold', padding: '5%'}} value={fullName} onChange={(e) => setFullName(e.target.value)} />
                                    <p className="terms-conditions">
                                        Al hacer clic en "Next", aceptas los 
                                        <a href="https://wearlyapp.com/terms" target="_blank" rel="noopener noreferrer"> Términos y Condiciones</a> y la 
                                        <a href="https://wearlyapp.com/privacy" target="_blank" rel="noopener noreferrer"> Política de Privacidad</a>.
                                    </p>
                                    <button type="submit" className="login-button" style={{fontFamily: 'Raleway', fontWeight: 'bold', textAlign: 'center', marginBottom: '10px', marginTop: '30%'}}>Next</button>
                                </div>
                            ) : step === 2 ? (
                                <div className="input-group" style={{marginBottom: '30px'}}>
                                    <input type="number" className='input-login' placeholder="DNI" required style={{fontFamily: 'Raleway', fontWeight: 'bold', padding: '5%'}} value={idNumber} onChange={(e) => setIdNumber(e.target.value)} />
                                    <p className="terms-conditions">
                                        Al hacer clic en "Next", aceptas los 
                                        <a href="https://wearlyapp.com/terms" target="_blank" rel="noopener noreferrer"> Términos y Condiciones</a> y la 
                                        <a href="https://wearlyapp.com/privacy" target="_blank" rel="noopener noreferrer"> Política de Privacidad</a>.
                                    </p>
                                    <button type="submit" className="login-button" style={{fontFamily: 'Raleway', fontWeight: 'bold', textAlign: 'center', marginBottom: '10px', marginTop: '30%'}}>Next</button>
                                </div>
                            ) : (
                                <div className="input-group" style={{marginBottom: '30px'}}>
                                    <input type="email" className='input-login' placeholder="Email" required style={{fontFamily: 'Raleway', fontWeight: 'bold', padding: '5%'}} value={email} onChange={(e) => setEmail(e.target.value)} />
                                    {/* <input type="password" className='input-login' placeholder="Password" required style={{fontFamily: 'Raleway', fontWeight: 'bold', padding: '5%', marginTop:'10px'}} value={password} onChange={(e) => setPassword(e.target.value)} /> */}
                                    <p className="terms-conditions">
                                        Al hacer clic en "Let's Go!", aceptas los 
                                        <a href="https://wearlyapp.com/terms" target="_blank" rel="noopener noreferrer"> Términos y Condiciones</a> y la 
                                        <a href="https://wearlyapp.com/privacy" target="_blank" rel="noopener noreferrer"> Política de Privacidad</a>.
                                    </p>
                                    <button type="submit" className="login-button" style={{fontFamily: 'Raleway', fontWeight: 'bold', textAlign: 'center', marginBottom: '10px', marginTop: '30%'}}>Let's Go!</button>
                                </div>
                            )}
                        </form>
                        </>
                    )}
                </div>
                {feedbackAsk && 
                    <div className='modal no-scroll'>
                        <div className='modal-content'>
                            <button className='modal-close-btn' onClick={() => setFeedbackAsk(false)}>X</button>
                            <div className='modal-confetti'>
                                <ConfettiExplosion />
                            </div>
                            <div className='modal-header'>
                                <h2>Gracias por unirte a nuestra lista de espera! 🥳</h2>
                            </div>
                            <div className='modal-body'>
                                <p style={{fontSize:'14px'}}>Te notificarmenos cuando salgas de la lista de espera.</p>
                                <h1 style={{textAlign:'center'}}>
                                    {isWaitlistPositionLoading ? (
                                        <div>Loading...</div> // Show loading message or spinner
                                    ) : (
                                        `#${userWaitlistPosition}` // Show waitlist position when not loading
                                    )}
                                </h1>
                                <p style={{fontSize:'14px'}}>Para avanzar en la lista de espera, puedes compartir Wearly con el siguiente enlace con cualquier persona que conozcas y que pueda estar interesada.</p>
                                <div style={{marginTop:'5%'}}>
                                    Compartir Wearly
                                    <button
                                        type="button"
                                        style={{
                                            cursor: 'pointer',
                                            backgroundColor: 'transparent',
                                            border: '0px',
                                            boxSizing: 'border-box',
                                            fill: 'black',
                                        }}
                                        onClick={() => handleRefShare(email)}
                                    >
                                        <ShareIcon style={{ width: '20px', height: '20px' }} />
                                    </button>
                                </div>
                                <br/>
                                <a className='share-url' href={`https://wearlyapp.com/?ref=${email}`} target="_blank" rel='noreferrer' style={{marginTop:'5%'}}><i>https://wearlyapp.com/?ref={email}</i></a>
                                <form onSubmit={handleFeedback} style={{marginTop:'7%'}}>
                                    <p style={{fontSize:'14px'}}><i>Nos encantaría conocerte mas!</i></p>
                                    <input type="text" placeholder="Marca Favorita" />
                                    <input type="text" placeholder="¿Por qué te interesa Wearly?" />
                                    <button type="submit">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                }
                {loading && 
                    <div className="loading-overlay">
                        <div className="spinner"></div>
                    </div>
                }
            </div>
        </div>
    );
};

export default SignUp;
